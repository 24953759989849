<!-- LiveSessionTile -->
<template>
    <div class="card px-0" :class="getClassTile(liveSession)">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-9 col-md-8 px-0">
                    <div class="row">
                        <div :class="['col-lg-4', 'col-md-6', 'col-6']">
                            <!-- Sport Info -->
                            <i class="bi bi-activity me-2"></i>
                            <span>{{ liveSession.sport.name }}</span>
                        </div>
                        <div :class="['col-lg-4', 'col-md-6', 'col-6']">
                            <!-- liveSession Time -->
                            <i class="bi bi-clock me-2"></i>
                            <span class="course-time" :data-utc-time="liveSession.start_time">
                                {{ convertToLocalTime(liveSession.start_time) }}
                            </span>
                        </div>
                        <div :class="['col-lg-4', 'col-md-6', 'col-6']">
                            <!-- Duration Info -->
                            <i class="bi bi-hourglass-split me-2"></i>
                            <span>{{ liveSession.duration }} min</span>
                        </div>
                        <div :class="['col-lg-4', 'col-md-6', 'col-6']">
                            <!-- coach Info -->
                            <template v-if="liveSession.coach.url_image">
                                <img class="rounded me-2" :src="liveSession.coach.url_image" alt="avatar"
                                    style="width: 20px; height: auto">
                            </template>
                            <template v-else>
                                <i class="bi bi-person me-2"></i>
                            </template>
                            <span>{{ liveSession.coach.pseudo }}</span>
                        </div>

                        <div :class="['col-lg-4', 'col-md-6', 'col-6']">
                            <!-- Price Info -->
                            <i class="bi bi-currency-dollar me-2"></i>
                            <template v-if="includedInUserPackages(liveSession.package_id)">
                                <i class="bi bi-infinity me-2 bi-ml"></i>
                            </template>
                            <template v-else>

                                <span v-if="liveSession.price === -1" data-bs-toggle="popover" data-bs-trigger="hover"
                                    data-bs-placement="bottom" data-bs-html="true"
                                    :data-bs-content="$t('liveSession.voluntaryContributionPopover')">
                                    {{ $t('liveSession.voluntaryContribution') }}
                                </span>
                                <span v-if="liveSession.price === 0" data-bs-toggle="popover" data-bs-trigger="hover"
                                    data-bs-placement="bottom" data-bs-html="true"
                                    :data-bs-content="$t('liveSession.freePopover')">
                                    {{ $t('liveSession.free') }}
                                </span>
                                <span v-if="liveSession.price > 0">
                                    {{ formatPrice(liveSession?.price) }}
                                </span>
                            </template>
                        </div>

                        <template v-if="liveSession.package_id">
                            <div v-if="!includedInUserPackages(liveSession.package_id)"
                                class="col-lg-4 col-md-6 col-6">
                                <!-- Forfait -->
                                <i class="bi bi-infinity me-2"></i>
                                <template v-if="isLoggedIn">
                                    <a href="#" class="link" @click="openPackagePaymentModal(liveSession)">
                                        {{ $t('liveSession.packageAvailable') }}
                                    </a>
                                </template>
                                <template v-else>
                                    <span class="link-disabled" data-bs-toggle="popover" data-bs-trigger="hover"
                                        data-bs-placement="bottom" data-bs-html="true"
                                        :data-bs-content="$t('general.shouldConnect')">
                                        {{ $t('liveSession.packageAvailable') }}
                                    </span>
                                </template>
                            </div>
                        </template>

                        <template v-if="!isForListBooking">
                            <div :class="['col-lg-3', 'col-md-6', 'col-6']"
                                v-if="!liveSession.hasOwnProperty('bookers_names') || liveSession.bookers_names.length === 0">
                                <!-- Booking Count Empty -->
                            </div>
                            <div :class="['col-lg-3', 'col-md-6', 'col-6']" v-else>
                                <!-- Booking Count Info -->
                                <i class="bi bi-people-fill me-2"></i>
                                <span data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom"
                                    :data-bs-content="liveSession.bookers_names">
                                    {{ liveSession.bookers_names.length }}
                                </span>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 d-flex align-items-center justify-content-center">
                    <div class="w-100">
                        <span data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom"
                            v-bind:data-bs-content="!isLoggedIn ? $t('general.shouldConnect') : ''">
                            <!-- Provient du Home.vue -->
                            <template v-if="!isForListBooking">
                                <!-- BT MoveLive -->
                                <template v-if="this.isCurrentlyOn(liveSession)">
                                    <button class="btn w-100 btn-moving-live" @click.prevent="moveLive(liveSession)"
                                        :disabled="!isLoggedIn || isLoading">
                                        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"
                                            role="status" aria-hidden="true"></span>
                                        <span v-if="!isLoading">
                                            <i class="bi bi-caret-right-square-fill mx-3"></i>
                                            {{ $t('liveSession.moveLive') }}
                                        </span>
                                        <span v-else>
                                            {{ $t('liveSession.processing') }}
                                        </span>
                                    </button>
                                </template>
                                <template v-else>
                                    <!-- BT BookLive -->
                                    <template v-if="!liveSession.user_booked">
                                        <button class="btn w-100 btn-booking-live"
                                            @click.prevent="bookLive(liveSession)" :disabled="!isLoggedIn || isLoading">
                                            <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!isLoading">
                                                <i class="bi bi-calendar-plus mx-3"></i>
                                                {{ $t('liveSession.bookLive') }}
                                            </span>
                                        </button>
                                    </template>
                                    <template v-else>
                                        <!-- BT Booked -->
                                        <button class="btn w-100 btn-booked-live" :disabled="!isLoggedIn">
                                            <span>
                                                <i class="fs-5 bi bi-bookmark-check-fill mx-3"></i>
                                                {{ $t('liveSession.booked') }}
                                            </span>
                                        </button>
                                    </template>
                                </template>
                            </template>
                            <!-- Proviens de Mes Reservations -->
                            <template v-else>
                                <button v-if="isCurrentlyOn(liveSession)" class="btn w-100 btn-moving-live"
                                    @click.prevent="this.moveLive(liveSession)" :disabled="!isLoggedIn">
                                    <i class="bi bi-caret-right-square-fill mx-3"></i>
                                    {{ $t('liveSession.moveLive') }}
                                </button>

                                <button v-else class="btn w-100 btn-outline-danger"
                                    @click.once="cancelBooking(liveSession)" :disabled="!isLoggedIn">
                                    <i class="bi bi-calendar-minus mx-3"></i>
                                    {{ $t('liveSession.cancel') }}
                                </button>
                            </template>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import liveSessionService from '@/api/liveSessionService';
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { popoverMixin } from '@/mixins/popoverMixin';
import { priceMixin } from '@/mixins/priceMixin';
import { timeMixin } from '@/mixins/timeMixin';
import { zoomMixin } from '@/mixins/zoomMixin';
import { mapGetters } from 'vuex';

export default {
    name: 'LiveSessionTile',
    mixins: [timeMixin, liveSessionMixin, popoverMixin, priceMixin, zoomMixin],
    props: {
        liveSession: {
            type: Object,
            required: true
        },
        isForSingleCoach: Boolean,
        isForListBooking: Boolean,
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            isLoggedIn: 'user/isLoggedIn',
            availablePackages: 'participantPackages/packages',
        }),
    },
    data() {
        return {
            defaultAvatar: 'https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png',
            isLoading: false,
        };
    },
    watch: {
        isLoggedIn(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.resetPopovers();
            }
        },
        liveSession: {
            handler() {
                // Forcer la mise à jour du composant
                this.$forceUpdate();
            },
            deep: true
        }
    },
    methods: {
        async moveLive(liveSession) {
            try {

                //check if user could have access to the course
                const link = await this.getZoomLink(liveSession);
                if (link === null) {
                    this.openPaymentModal(liveSession);
                    return;
                }
                // reset and allow to close modal
                this.$store.dispatch('payment/reset');
                this.openZoomLink(link);
                this.trackEvent('live_session_started', liveSession);
            } catch (error) {
                this.$showToast('error', this.$t('liveSession.getLinkError'));
            }
            try {
                await this.$store.dispatch('participantLiveSessions/recordParticipant', liveSession.id);
            } catch (error) {
                console.error("Error when recording participant", error);
            }
        },
        async getZoomLink(liveSession) {
            try {
                const payload = {
                    coach_id: liveSession.coach.id,
                    package_id: liveSession.package_id
                };
                return liveSessionService.getLink(liveSession.id, payload);
            } catch (error) {
                console.error(error);
                this.$showToast('error', this.$t('liveSession.getLinkError'));
            }
        },
        async bookLive(liveSession) {
            this.isLoading = true;
            try {
                await this.$store.dispatch('participantLiveSessions/recordReservation', {
                    liveSessionId: liveSession.id,
                    participant: this.currentUser
                });
                liveSession.user_booked = true;
                this.$showToast('success', this.$t('liveSession.bookLiveSuccess'));

                this.trackEvent('live_session_booked', liveSession);
            } catch (error) {
                console.error(error);
                this.$showToast('error', this.$t('liveSession.bookLiveError'));
            } finally {
                this.isLoading = false;
            }
        },
        openPaymentModal(liveSession) {
            this.$store.commit(
                'payment/openPaymentModal',
                {
                    selectedLiveSession: liveSession,
                    selectedPackage: null,
                    selectedReplay: null,
                    selectedCoach: liveSession.coach
                });
        },
        includedInUserPackages(packageId) {

            if (this.currentUser == null || this.currentUser.participant_valid_packages == null || this.currentUser.participant_valid_packages.length === 0 || packageId == null) {
                return false;
            }

            return this.currentUser.participant_valid_packages.find(p => p.id === packageId);
        },
        openPackagePaymentModal(liveSession) {
            const packageSelected = this.getAvailablePackageById(liveSession.package_id);
            if (packageSelected === undefined) {
                this.$showToast('error', this.$t('liveSession.packageNotAvailable'));
                return;
            }
            // check if the package is still in current user's packages
            const packageInUserPackages = this.currentUser.participant_valid_packages.find(p => p.id === packageSelected.id);
            if (packageInUserPackages) {
                this.$showToast('info', this.$t('package.alreadyPurchased'));
                return;
            }
            this.$store.commit(
                'payment/openPaymentModal',
                {
                    selectedLiveSession: null,
                    selectedPackage: packageSelected,
                    selectedReplay: null,
                    selectedCoach: liveSession.coach
                });

        },
        getAvailablePackageById(id) {
            return this.availablePackages.find(p => p.id === id);
        },
        async cancelBooking(liveSession) {
            await this.$store.dispatch('participantLiveSessions/cancelReservation', liveSession.id);
            this.trackEvent('live_session_canceled', liveSession);
        },
        trackEvent(eventType, liveSession) {
            try{
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: eventType,
                    liveSessionId: liveSession.id,
                    sport: liveSession.sport.name,
                    coach: liveSession.coach.pseudo,
                    price: liveSession.price,
                    duration: liveSession.duration,
                    startTime: liveSession.start_time,
                });
            } catch (error) {
                console.error("Error when tracking event", error);
            }
        },
    }
};
</script>
<style scoped>
@keyframes pulseAnimation {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0px #82B5AF80;
    }

    50% {
        transform: scale(1.05);
        box-shadow: 0 0 15px #82B5AFbf;
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0px #82B5AF80;
    }
}

.btn-moving-live {
    animation: pulseAnimation 2s infinite;
}

.course-live {
    background-color: #f0fff640
}

.course-live-coach {
    background-color: #fff5f5;
}

.btn-booked-live {
    color: var(--primary-color);
}

.btn-booking-live,
.btn-booking-live:disabled {
    border: 1px solid var(--primary-color);
    color: black;
}

.btn-booked-live:hover,
.btn-booked-live:active {
    border-color: #ffffff;
    cursor: default;
    color: var(--primary-color);
}

.bi-ml {
    color: var(--primary-color);
}
</style>
