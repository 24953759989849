/* src/index.js */
import store from '@/store';
import AdminDashboard from '@/views/AdminDashboard.vue';
import AdminManageCoaches from '@/views/AdminManageCoaches.vue';
import AdminManageParticipants from '@/views/AdminManageParticipants.vue';
import AdminManageSports from '@/views/AdminManageSports.vue';
import CoachDashboard from '@/views/CoachDashboard.vue';
import CoachProfile from '@/views/CoachProfile.vue';
import Confidentialite from '@/views/Confidentialite.vue';
import ConsultPayment from '@/views/ConsultPayment.vue';
import EnrollmentDonePage from '@/views/EnrollmentDonePage.vue';
import FAQ from '@/views/FAQ.vue';
import GettingStarted from '@/views/GettingStarted.vue';
import Home from '@/views/Home.vue';
import LiveSessionsBySport from '@/views/LiveSessionsBySport.vue';
import ManageBooking from '@/views/ManageBooking.vue';
import PaymentResult from '@/views/PaymentResult.vue';
import ProfileView from '@/views/Profile.vue';
import StripeOnboardNeedRefresh from '@/views/StripeOnboardNeedRefresh.vue';
import StripeOnboardSucces from '@/views/StripeOnboardSucces.vue';
import Terms from '@/views/Terms.vue';
import Wwa from '@/views/Wwa.vue';
import { createRouter, createWebHistory } from 'vue-router';
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'MovingLive' }
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ,
    meta: { title: 'FAQ - MovingLive' }
  },
  {
    path: '/termes-et-conditions',
    name: 'Terms',
    component: Terms,
    meta: { title: 'Termes et conditions - MovingLive' }
  },
  {
    path: '/confidentialite',
    name: 'Confidentialite',
    component: Confidentialite,
    meta: { title: 'Confidentialite - MovingLive' }
  },
  {
    path: '/qui-sommes-nous',
    name: 'Wwa',
    component: Wwa,
    meta: { title: 'Qui sommes nous - MovingLive' }
  },
  {
    path: '/stripe/onboard/success',
    name: 'StripeOnboardSucces',
    component: StripeOnboardSucces,
  },
  {
    path: '/stripe/onboard/need-refresh',
    name: 'StripeOnboardNeedRefresh',
    component: StripeOnboardNeedRefresh,
  },
  {
    path: '/coaches/:pseudo',
    name: 'coaches',
    component: CoachProfile,
    props: route => ({ pseudo: decodeURIComponent(route.params.pseudo) }),
  },
  {
    path: '/live-sessions/sport/:id',
    name: 'live-sessions-by-sport',
    component: LiveSessionsBySport,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/coach/dashboard',
    name: '/coachDashboard',
    component: CoachDashboard,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/coach-registration-form',
    name: 'coach-registration-form',
    component: ProfileView,
    props: { enrollementProcess: true },
    meta: { requiresAuth: true }
  },
  {
    path: '/enrollment-done-page',
    name: 'enrollment-done-page',
    component: EnrollmentDonePage,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/getting-started',
    name: 'getting-started',
    component: GettingStarted,
    meta: { title: 'Onboarding - MovingLive' }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: { requiresAuth: true }
  },
  {
    path: '/manage-booking',
    name: 'manage-booking',
    component: ManageBooking,
    meta: { requiresAuth: true }
  },
  {
    path: '/consult-payment',
    name: 'consult-payment',
    component: ConsultPayment,
    meta: { requiresAuth: true }
  },
  {
    path: '/payment-result',
    name: 'payment-result',
    component: PaymentResult,
    meta: { requiresAuth: true }
  },

  /* partie admin */
  {
    path: '/admin/dashboard',
    name: 'adminDashboard',
    component: AdminDashboard,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/coaches',
    name: 'AdminManageCoaches',
    component: AdminManageCoaches,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/sports',
    name: 'AdminManageSports',
    component: AdminManageSports,
    meta: { requiresAdmin: true }
  },
  {
    path: '/admin/participants',
    name: 'AdminManageParticipants',
    component: AdminManageParticipants,
    meta: { requiresAdmin: true }
  },

  {
    path: '/:pathMatch(.*)*', // This is a catch-all route
    name: '404',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/404.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Logique de vérification de l'authentification ou des droits d'admin
  const isLoggedIn = store.getters['user/isLoggedIn'];
  const currentUser = store.getters['user/currentUser'];

  if ((to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) ||
    (to.matched.some(record => record.meta.requiresAdmin) && !currentUser.is_admin)) {
    next('/'); // redirige vers la page d'accueil si non autorisé
  } else {
    // Mise à jour du titre de la page
    const pageTitle = to.meta.title || 'MovingLive'; // Remplacez 'MovingLive' par votre titre par défaut
    document.title = pageTitle;

    next(); // Continuez avec la navigation
  }
});

export default router