// src/api/authService.js

const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

export default {
    getToken() {
        return sessionStorage.getItem(TOKEN_KEY);
    },
    saveToken(token) {
        sessionStorage.setItem(TOKEN_KEY, token);
    },
    removeToken() {
        sessionStorage.removeItem(TOKEN_KEY);
    },
    getRefreshToken() {
        return sessionStorage.getItem(REFRESH_TOKEN_KEY);
    },
    saveRefreshToken(token) {
        sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
    },
    removeRefreshToken() {
        sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    }
};
