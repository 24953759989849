// src/api/SportService.js
import api from '@/services/api.js';

export default {
    async getSports() {
        const response = await api.get('/v1/sports');
        return response.data.sports;
    },
    async updateSport(sportId, sportData) {
        console.log(sportId);
        const response = await api.put(`/v1/sports/${sportId}`, sportData);
        return response.data.sport;
    },
    async getThemes() {
        const response = await api.get('/v1/themes');
        return response.data.themes;
    },
    async createSport(sportData) {
        const response = await api.post('/v1/sports', sportData);
        return response.data.sport;
    },
};
