import CoachService from "@/api/CoachService";
import PackageService from "@/api/PackageService";

const getDefaultState = () => {
    return {
        packages: [],
    };
};
export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        packages(state) {
            return state.packages;
        },
        hasPackages(state) {
            return state.packages && state.packages.length > 0;
        }
    },
    mutations: {
        SET_PACKAGES(state, packages) {
            state.packages = packages;
        },
        ADD_PACKAGE(state, newPackage) {
            state.packages.push(newPackage);
        },
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        REMOVE_PACKAGE(state, packageId) {
            state.packages = state.packages.filter(
                (pack) => pack.id !== packageId
            );
        }
    },
    actions: {
        async fetchPackages({ commit }, coachId) {
            const packages = await CoachService.getPackagesForCoach(coachId);
            commit("SET_PACKAGES", packages);
        },
        async createPackage({ commit, dispatch }, packageData) {
            const newPackage = await PackageService.createPackage(packageData);
            commit("ADD_PACKAGE", newPackage);
            dispatch('participantPackages/addPackage', newPackage, { root: true });
        },
        async deletePackage({ commit }, packageId) {
            await PackageService.deletePackage(packageId);
            commit("REMOVE_PACKAGE", packageId);
        },
        async resetState({ commit }) {
            commit('RESET_STATE');
        },
    }
};