<!-- CoachDashboard.vue -->
<template>
  <div class="container">
    <!-- Navigation Bootstrap pour les onglets -->
    <ul class="nav nav-pills nav-justified mb-3 mt-2 mt-md-0" id="myTab" role="tablist">
      <li class="nav-item me-2" role="presentation">
        <a class="nav-link" :class="{ active: currentTab === 'liveSessions' }" id="liveSessions-tab" href="#"
          @click.prevent="currentTab = 'liveSessions'" role="tab" aria-controls="liveSessions" aria-selected="true">{{
          $t('liveSession.sessions') }}</a>
      </li>
      <li class="nav-item me-2" role="presentation">
        <a class="nav-link" :class="{ active: currentTab === 'subscriptions' }" id="subscriptions-tab" href="#"
          @click.prevent="currentTab = 'subscriptions'" role="tab" aria-controls="subscriptions"
          aria-selected="false">{{ $t('package.packages') }}</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" :class="{ active: currentTab === 'replays' }" id="replays-tab" href="#"
          @click.prevent="currentTab = 'replays'" role="tab" aria-controls="replays" aria-selected="false">{{
          $t('replay.replays') }}</a>
      </li>
    </ul>

    <!-- Affichage conditionnel des composants basé sur l'onglet sélectionné -->
    <div class="tab-content">
      <div class="tab-pane fade" :class="{ show: currentTab === 'liveSessions', active: currentTab === 'liveSessions' }"
        id="liveSessions" role="tabpanel" aria-labelledby="liveSessions-tab">
        <CoachLiveSessionForm :sports="sports" />
        <CoachLiveSessionsTile />
        <CoachPastLiveSessionsTile />
      </div>
      <div class="tab-pane fade"
        :class="{ show: currentTab === 'subscriptions', active: currentTab === 'subscriptions' }" id="subscriptions"
        role="tabpanel" aria-labelledby="subscriptions-tab">
        <CoachPackageForm />
        <CoachPackagesTile />
      </div>
      <div class="tab-pane fade" :class="{ show: currentTab === 'replays', active: currentTab === 'replays' }"
        id="replays" role="tabpanel" aria-labelledby="replays-tab">
        <CoachReplayForm :sports="sports" />
        <CoachReplaysTile />
      </div>
    </div>
  </div>
</template>

<script>
import CoachLiveSessionForm from '@/components/liveSessions/CoachLiveSessionForm.vue';
import CoachLiveSessionsTile from '@/components/liveSessions/CoachLiveSessionsTile.vue';
import CoachPastLiveSessionsTile from '@/components/liveSessions/CoachPastLiveSessionsTile.vue';
import CoachPackageForm from '@/components/packages/CoachPackageForm.vue';
import CoachPackagesTile from '@/components/packages/CoachPackagesTile.vue';
import CoachReplayForm from '@/components/replays/CoachReplayForm.vue';
import CoachReplaysTile from '@/components/replays/CoachReplaysTile.vue';

export default {
  name: 'CoachDashboard',
  data() {
    return {
      currentTab: 'liveSessions',
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/currentUser'];
    },
    sports() {
      return this.$store.getters['sports/sports'];
    }
  },
  components: {
    CoachLiveSessionForm,
    CoachReplayForm,
    CoachReplaysTile,
    CoachLiveSessionsTile,
    CoachPastLiveSessionsTile,
    CoachPackageForm,
    CoachPackagesTile
  },
  async mounted() {
    this.fetchCoachLiveSessions();
    this.fetchCoachPastLiveSessions();
    this.fetchSports();
    this.fetchCoachPackages();
    this.fetchCoachReplays();
  },
  methods: {
    async fetchCoachLiveSessions() {
      await this.$store.dispatch('coachLiveSessions/fetchLiveSessions', this.currentUser.id);
    },
    async fetchCoachPastLiveSessions() {
      await this.$store.dispatch('coachLiveSessions/fetchPastLiveSessions', this.currentUser.id);
    },
    async fetchSports() {
      await this.$store.dispatch('sports/fetchSports');
    },
    async fetchCoachPackages() {
      await this.$store.dispatch('coachPackages/fetchPackages', this.currentUser.id);
    },
    async fetchCoachReplays() {
      await this.$store.dispatch('coachReplays/fetchReplays', this.currentUser.id);
    }
  },
};
</script>

<style scoped>
.nav-pills .nav-link {
  color: var(--primary-color);
  background-color: var(--white-color);
  border: 1px solid var(--primary-color);
}

.nav-pills .nav-link.active,
.nav-pills .nav-link:hover {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}
</style>
