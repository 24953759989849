<!-- Logo.vue -->
<template>
    <router-link class="navbar-brand" :class="className" :to="{ name: 'home' }" @click="handleHomeClick">
        <img :src="logoSrc" alt="Logo MovingLive" height="40">
    </router-link>
</template>

<script>
import logoML from '@/assets/logoML.png';

export default {
    name: 'LogoView',
    props: {
        className: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            logoSrc: logoML
        };
    },
    methods: {
        handleHomeClick(event) {
            if (this.$route.name === 'home') {
                event.preventDefault();
                window.location.reload();
            }
        }
    }
};
</script>
