// src/api/liveSessionService.js
import api from '@/services/api.js';

export default {
    async createLiveSession(liveSessionData) {
        const response = await api.post('/v1/live-sessions', liveSessionData);
        return response.data.liveSession;
    },
    async updateLiveSession(liveSessionId, liveSessionData) {
        const response = await api.put(`/v1/live-sessions/${liveSessionId}`, liveSessionData);
        return response.data.liveSession;
    },
    async cancelLiveSession(liveSessionId, sendNotification = true) {
        await api.delete(`/v1/live-sessions/${liveSessionId}`, { params: { sendNotification } });
    },
    async getLiveSessions() {
        const response = await api.get('/v1/live-sessions');
        return response.data.liveSessions;
    },
    async getLink(liveSessionId, payload) {
        const response = await api.get(`/v1/live-sessions/${liveSessionId}/link`, { params: payload });
        return response.data.link;
    },
    async addParticipant(liveSessionId) {
        await api.post(`/v1/live-sessions/${liveSessionId}/participants`);
    },
    async addReservation(liveSessionId) {
        await api.post(`/v1/live-sessions/${liveSessionId}/reservations`);
    },
    async cancelReservation(liveSessionId) {
        await api.delete(`/v1/live-sessions/${liveSessionId}/reservations`);
    },
};
