/* priceMixin.js */
export const priceMixin = {
    methods: {
        formatPrice(price) {
            if (price == null) {
                return this.$t('liveSession.free');
            }
            if (price === -1) {
                return this.$t('liveSession.voluntaryContribution');
            }
            if (price === 0) {
                return this.$t('liveSession.free');
            }
            if (typeof price === "number" && !isNaN(price)) {
                return price.toFixed(2);
            }
            return this.$t('liveSession.invalidPrice');
        }
    }
};