<template>
  <div class="container-lg flex-grow-1 ml-box mb-0 mb-md-4 bg-white">

    <h2 class="ml-title mt-4 mb-4" v-t="'stripeConnect.done.title'"></h2>
    <div class="jumbotron">
      <p class="lead spacing" v-t="'stripeConnect.done.allIsGood'"></p>
      <p class="lead spacing" v-t="'stripeConnect.done.letCreateSession'"></p>
      <p class="lead spacing" v-t="'stripeConnect.done.weAreHere'"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StripeOnboardSucces',
  data() {
    return {
    }
  },
  components: {
  },
  methods: {
  },
};
</script>
