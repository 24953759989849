<template>
    <!-- Tuile principale prenant tout l'espace -->
    <div class="row mt-3">
        <div class="col-12">
            <div class="card align-items-center d-flex flex-column text-center">
                <div class="card-body">
                    <h4 class="ml-title" v-t="'feedback.comeBack'"></h4>
                    <p v-t="'feedback.youCanBook'"></p>
                </div>
            </div>
        </div>
    </div>

    <!-- Deux tuiles côte à côte en dessous -->
    <div class="row">
        <div :class="['col-lg-12', 'col-md-12', 'col-sm-12', 'mt-4', 'mb-4']">
            <div class="card align-items-center text-center">
                <div class="card-body">
                    <h6 class="card-title fst-italic" v-t="'feedback.didYouKnowTitle'"></h6>
                    <p class="fst-italic mt-2">{{ randomFact }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FeedbackView',
    computed: {
        randomFact() {
            const facts = this.$tm('feedback.facts'); // Utilise $tm pour accéder au tableau directement
            if (!facts || !facts.length) {
                return '';
            }
            const randomIndex = Math.floor(Math.random() * facts.length); // Sélectionne un index aléatoire
            return facts[randomIndex]; // Retourne le fait aléatoire
        }
    }
};
</script>

<style scoped>
</style>
