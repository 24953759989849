<!-- PeopleTile.vue -->
<template>
    <!-- Spinner Overlay Conditionnel -->
    <div v-if="isLoading"
        class="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-overlay">
        <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden" v-t="'payment.loading'"></span>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-lg-8 col-md-7">
                <div class="row">
                    <!-- Name -->
                    <div :class="['col-lg-6', 'col-md-12', 'col-sm-6']">
                        <img v-if="user.url_image" class="rounded" :src="user.url_image" alt="avatar"
                            style="width: 35px; height: auto">
                        <template v-else>
                            <i class="bi bi bi-file-person me-2"></i>
                        </template>
                        <span class="mx-2">#{{ user.id }} - {{ user.pseudo }} - {{ user.name }} {{ user.surname
                            }}</span>
                    </div>

                    <!-- email -->
                    <div :class="['col-lg-6', 'col-md-12', 'col-sm-6']">
                        <i class="bi bi-at me-2"></i>
                        <span>{{ user.email }}</span>
                    </div>

                    <!-- tz -->
                    <div :class="['col-lg-6', 'col-md-12', 'col-sm-6']">
                        <i class="bi bi-pin-map me-2"></i>
                        <span>{{ user.timezone }}</span>
                    </div>

                    <!-- phone -->
                    <div :class="['col-lg-6', 'col-md-12', 'col-sm-6']">
                        <i class="bi bi-telephone me-2"></i>
                        <span>{{ user.phone_number }}</span>
                    </div>

                    <!-- Creation -->
                    <div :class="['col-lg-6', 'col-md-12', 'col-sm-6']">
                        <i class="bi bi-cake2 me-2"></i>
                        <span>{{ convertToLocalTimeWhitShortDay(user?.creation_date) }}</span>
                    </div>

                    <!-- for user.participant_valid_packages -->
                    <template v-for="pack in user.participant_valid_packages" :key="pack.id">
                        <div class="col-12 d-flex flex-wrap align-items-center">
                            <i class="bi bi-infinity"></i>
                            <span class="mx-2">#{{ pack.id }}</span>
                            <span>{{ formatDateShort(pack.start_date) }}</span>
                            <i class="bi bi-arrow-right mx-2"></i>
                            <span>{{ formatDateShort(pack.end_date) }}</span>
                            <span class="ms-2">- {{ pack.coach_pseudo }}</span>
                        </div>
                    </template>

                    <!-- Checkbox is_coach_validated -->
                    <div v-if="isForProf" :class="['col-lg-6', 'col-md-12', 'col-sm-6']">
                        <i class="bi bi-check2 me-2"></i>
                        <input type="checkbox" :checked="isProfValidated" disabled>
                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-5 d-flex justify-content-center">
                <div v-if="!isForProf" class="w-100">
                    <!-- Checkbox user.has_plan -->
                    <div :class="['col-12', 'col-md-12', 'col-sm-12']">
                        <i class="bi bi-fire me-2"></i>
                        <input type="checkbox" :checked="user.has_plan" @change="togglePlan(user)" class="me-2">
                        <span class="me-3 illimited">Illimité</span>
                    </div>

                    <!-- Checkbox user.is_beta_tester -->
                    <div :class="['col-lg-12', 'col-md-12', 'col-sm-12']">
                        <i class="bi bi-gift me-2"></i>
                        <input type="checkbox" :checked="user.has_free_offer" @change="toggleFreeOffer(user)"
                            class="me-2">
                        <span class="me-3 illimited">Cours gratuit</span>
                    </div>

                    <div :class="['col-lg-12', 'col-md-12', 'col-sm-12']">
                        <i class="bi bi-rocket-takeoff me-2"></i>
                        <input type="checkbox" :checked="!user.free_session_activation_date" disabled class="me-2">
                        <span class="me-3 illimited">Premiere séance gratuite</span>
                    </div>

                    <!-- Checkbox user.is_beta_tester -->
                    <div :class="['col-lg-12', 'col-md-12', 'col-sm-12']">
                        <i class="bi bi-eyedropper me-2"></i>
                        <input type="checkbox" :checked="user.is_beta_tester" @change="toggleBetaTester(user)"
                            class="me-2">
                        <span class="me-3 illimited">Beta Tester</span>
                    </div>
                </div>
                <div v-else>
                    <button v-if="!isProfValidated" type="button" class="btn btn-accept" @click="validateProf(user.id)">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"
                            aria-hidden="true"></span>
                        <span v-if="isLoading">En traitement</span>
                        <span v-else>Valider le coach</span>
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { timeMixin } from '@/mixins/timeMixin';
import api from '@/services/api.js';

export default {
    name: 'PeopleTile',
    mixins: [timeMixin],
    props: {
        user: {
            type: Object,
            required: true
        },
        isForProf: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isLoading: false,
            isProfValidated: this.user.is_coach_validated,
        }
    },
    methods: {
        togglePlan(user) {
            this.$emit('toggle-plan', user);
        },
        toggleBetaTester(user) {
            this.$emit('toggle-beta-tester', user);
        },
        toggleFreeOffer(user) {
            this.$emit('toggle-free-offer', user);
        },
        async validateProf(id) {

            try {
                this.isLoading = true;
                await api.put(`/v1/admin/coaches/${id}/validate`);
                this.isProfValidated = true;
                this.$showToast('success', 'Coach validé');
            } catch (error) {
                console.error(error);
                this.$showToast('error', error.message);
            } finally {
                this.isLoading = false;
            }
        }
    },
}
</script>

<style scoped>
.illimited,
.bi {
    color: var(--primary-color);
}
</style>
