// src/store/modules/coachReplays.js
import replayService from '@/api/replayService';

const getDefaultState = () => {
    return {
        replays: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        replays(state) {
            return state.replays;
        },
        hasReplays(state) {
            return state.replays && state.replays.length > 0;
        },
    },
    mutations: {
        SET_REPLAYS(state, replays) {
            state.replays = replays;
        },
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        REMOVE_REPLAY(state, replayId) {
            state.replays = state.replays.filter(replay => replay.id !== replayId);
        },
        ADD_REPLAY(state, replay) {
            state.replays.push(replay);
        }
    },
    actions: {
        async fetchReplays({ commit }, coachId) {
            try {
                const replays = await replayService.getReplaysByCoach(coachId);
                commit('SET_REPLAYS', replays);
            } catch (error) {
                commit('SET_REPLAYS', []);
                console.error('Error while fetching coach replays:', error);
            }
        },
        async deleteReplay({ commit }, replayId) {
            try {
                await replayService.deleteReplay(replayId);
                commit('REMOVE_REPLAY', replayId);
            } catch (error) {
                console.error('Error while deleting replay:', error);
            }
        },
        async createReplay({ commit, dispatch }, replayData) {
            const new_replay = await replayService.createReplay(replayData);
            commit('ADD_REPLAY', new_replay);
            dispatch('participantReplays/addReplay', new_replay, { root: true });
        },
        async resetState({ commit }) {
            commit('RESET_STATE');
        },
    },
};
