/* store/modules/user.js */
import authService from '@/api/authService';
import userService from '@/api/userService';

const getDefaultState = () => {
    return {
        currentUser: null,
        loggedInService: null, // 'google', 'facebook' or null
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        currentUser: state => state.currentUser,
        isLoggedIn: state => state.currentUser && state.currentUser.is_authenticated,
        isTermsAccepted: state => state.currentUser && state.currentUser.terms_accepted,
        loggedInService: state => state.loggedInService,
    },
    mutations: {
        SET_CURRENT_USER(state, user) {
            state.currentUser = { ...state.currentUser, ...user };
        },
        SET_LOGGED_IN_SERVICE(state, service) {
            state.loggedInService = service;
        },
        ADD_PACKAGE(state, pack) {
            if (!state.currentUser.participant_valid_packages) {
                state.currentUser.participant_valid_packages = [];
            }
            state.currentUser.participant_valid_packages.push(pack);
            localStorage.setItem('user', JSON.stringify(state.currentUser));
        },
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        }
    },
    actions: {
        async fetchCurrentUser({ commit, getters }) {
            if (!getters.isLoggedIn) {
                return;
            }
            try {
                const user = await userService.getUser();
                commit('SET_CURRENT_USER', user);
            } catch (error) {
                commit('RESET_STATE');
            }
        },
        async fetchUserProfile({ commit, getters }) {
            if (!getters.isLoggedIn) {
                return;
            }
            try {
                const user = await userService.getUserProfile();
                commit('SET_CURRENT_USER', user);
            } catch (error) {
                commit('RESET_STATE');
            }
        },
        async login({ commit }, payload) {
            try {
                let response = await userService.login(payload);
                if (response.data && response.data.user) {
                    commit('SET_CURRENT_USER', response.data.user);
                    commit('SET_LOGGED_IN_SERVICE', payload.provider);
                    authService.saveToken(response.data.access_token);
                    authService.saveRefreshToken(response.data.refresh_token);
                }
            } catch (error) {
                console.error(`Erreur de login avec ${payload.provider}`, error);
            }
        },
        async logout({ commit, dispatch }) {
            try {
                await userService.logout();
            } finally {
                commit('RESET_STATE');
                authService.removeToken();
                authService.removeRefreshToken();
                dispatch('resetStore', null, { root: true }); // Ajoutez cette ligne pour réinitialiser tous les modules
            }
        },
        async addPackage({ commit }, pack) {
            try {
                commit('ADD_PACKAGE', pack);
            } catch (error) {
                console.error('Error while adding package:', error);
            }
        },
        async updateUserProfile({ commit }, data) {
            try {
                const user = await userService.updateUserProfile(data);
                commit('SET_CURRENT_USER', user);
            } catch (error) {
                console.error('Error while updating user profile:', error);
            }
        },
        async refreshToken({ commit }) {
            try {
                const newAccessToken = await userService.refreshToken();
                authService.saveToken(newAccessToken);
            } catch (error) {
                commit('RESET_STATE');
                authService.removeToken();
                authService.removeRefreshToken();
                throw error;
            }
        },
        async checkToken() {
            try {
                const response = await userService.checkToken();
                return response.status === 200;
            } catch (error) {
                return false;
            }
        },
        async resetState({ commit }) {
            commit('RESET_STATE');
        },
        async updateTerms({ commit }, data) {
            console.log('updateTerms', data);
            const user = await userService.updateTerms(data);
            commit('SET_CURRENT_USER', user);
        },
    },
};
