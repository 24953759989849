/* global bootstrap */

export const popoverMixin = {
  async mounted() {
    await this.$nextTick();
    this.initializePopovers();
  },
  beforeUnmount() {
    this.destroyPopovers();
  },
  methods: {
    initializePopovers() {
      if (!this.$el || !(this.$el instanceof Element)) {
        return;
      }

      const popoverTriggerList = [].slice.call(this.$el.querySelectorAll('[data-bs-toggle="popover"]'));
      this.popovers = popoverTriggerList.map(el => {
        return new bootstrap.Popover(el);
      });
    },
    destroyPopovers() {
      if (this.popovers) {
        this.popovers.forEach(popover => {
          if (document.body.contains(popover._element)) {
            popover.dispose();
          }
        });
      }
    },
    resetPopovers() {
      // Détruit tous les popovers existants
      const popovers = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
      popovers.forEach(el => {
        const popoverInstance = bootstrap.Popover.getInstance(el);
        if (popoverInstance) {
          popoverInstance.dispose();
        }
      });

      // Réinitialise les popovers après un court délai pour s'assurer que les changements du DOM ont pris effet
      this.$nextTick(() => {
        this.initializePopovers();  // cette méthode provient du mixin popoverMixin
      });
    }
  }
};
