<!-- genere moi 4 rectable avec une logo et un slogan à l'interieur -->
<template>
    <div class="container-fluid bg-white mb-0 mb-md-4" id="concept">
        <div class="row text-center">
            <div class="col">
                <img src="/img/concept/gym.png" alt="logoLive" class="mt-4" style="width: 70px; height: auto">
                <p class="mt-2 link" v-t="'concept.sport'"></p>
            </div>
            <div class="col">
                <img src="/img/concept/visio.png" alt="logoLive" class="mt-4" style="width: 70px; height: auto">
                <p class="mt-2 link" v-t="'concept.onLine'"></p>
            </div>
            <div class="col">
                <img src="/img/concept/live.png" alt="logoLive" class="mt-4" style="width: 70px; height: auto">
                <p class="mt-2 link" v-t="'concept.live'"></p>
            </div>
            <div class="col">
                <img src="/img/concept/coaching.png" alt="logoLive" class="mt-4" style="width: 70px; height: auto">
                <p class="mt-2 link" v-t="'concept.interactive'"></p>
            </div>
            <div class="col">
                <router-link to="/getting-started" style="text-decoration: none;">
                    <img src="/img/concept/information.png" alt="logoLive" class="mt-4" style="width: 70px; height: auto">
                    <p class="mt-2 link"><span v-t="'concept.firstTime'"></span><i class="bi bi-link-45deg"></i></p>
                </router-link>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'ConceptVue',
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
    }
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
    #concept {
        border-bottom: 1px solid gray;
    }
}
</style>
