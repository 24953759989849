<!-- CoachLiveSessionsTile.vue -->
<template>
    <div class="container ml-box mb-4 bg-white">
        <div class="row g-0 mb-4">
            <div class="col-11 mt-4">
                <h2 class="ml-title">{{ $t('liveSession.myNextSessions') }}</h2>
            </div>
            <div class="col-1 mt-4">
                <i class="bi bi-chevron-down" style="cursor:pointer;" @click="toggleCollapse"></i>
            </div>
        </div>
        <!-- Utilisez v-if pour contrôler la visibilité du contenu -->
        <nav v-if="!isCollapsed">
            <div class="container-fluid">
                <div v-if="hasLiveSessions" class="row g-4">
                    <div class="col-12" v-for="liveSession in liveSessions" :key="liveSession.id">
                        <CoachLiveSessionTile :liveSession="liveSession" />
                    </div>
                    <span class="mb-4"></span>
                </div>
                <div v-else>
                    <p class="text-center">{{ $t('liveSession.myNextSessionsNothing') }}</p>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import CoachLiveSessionTile from './CoachLiveSessionTile.vue';
export default {
    name: 'CoachLiveSessionsTile',
    components: {
        CoachLiveSessionTile
    },
    data() {
        return {
            isCollapsed: false,
        };
    },
    mounted() {
    },
    computed: {
        liveSessions() {
            return this.$store.getters['coachLiveSessions/liveSessions'];
        },
        hasLiveSessions() {
            return this.$store.getters['coachLiveSessions/hasLiveSessions'];
        },
    },
    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed;
        },
    },
}
</script>


<style>
.btn-zoom {
    border-color: #5b8ef4;
    color: #5b8ef4;
}

.btn-zoom:hover {
    background-color: #5b8ef4;
    border-color: #5b8ef4;
}

.btn-update {
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.btn-update:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.btn-delete {
    border-color: var(--alert-color);
    color: var(--alert-color);
}

.btn-delete:hover,
#alertProfContainer {
    background-color: var(--alert-color);
    border-color: var(--alert-color);
}
</style>