<!-- SubscriptionListProf.vue -->
<template>
    <div class="container ml-box mb-4 bg-white">
        <div class="row g-0 mb-4">
            <div class="col-11 mt-4">
                <h2 class="ml-title" v-t="'package.myPackages'"></h2>
            </div>
        </div>
        <div class="container-fluid">
            <div v-if="hasPackages" class="row g-4 ">
                <div class="col-12" v-for="pack in packages" :key="pack.id">
                    <div class="card" :class="getClassCard(pack)">
                        <div class="card-body">
                            <div class="row">
                                <div :class="['col-xl-5', 'col-lg-12', 'col-md-12', 'col-sm-12']">
                                    <i class="bi bi-calendar-fill me-2"></i>
                                    <span>{{ convertToLocalTimeWhitShortDay(pack?.start_date) }}</span>
                                    <i class="bi bi-arrow-right me-2"></i>
                                    <span>{{ convertToLocalTimeWhitShortDay(pack?.end_date) }}</span>
                                </div>
                                <div :class="['col-xl-2', 'col-lg-4', 'col-md-3', 'col-sm-12']">
                                    <i class="bi bi-currency-dollar me-2"></i>
                                    <span>{{ formatPrice(pack?.price) }}</span>
                                </div>
                                <div :class="['col-xl-2', 'col-lg-3', 'col-md-3', 'col-sm-12']">
                                    <i class="bi bi-cart2 me-2"></i>
                                    <span>{{ pack?.payments?.length }}</span>
                                </div>
                                <div :class="['col-xl-2', 'col-lg-4', 'col-md-3', 'col-sm-12']">
                                    <i class="bi bi-piggy-bank me-2"></i>
                                    <span>{{ sumOfPaymentAmounts(pack) }}</span>
                                </div>
                                <div :class="['col-xl-1', 'col-lg-1', 'col-md-3', 'col-sm-12']">
                                    <template v-if="pack.payments?.length === 0 || !pack.payments">
                                        <button class="btn btn-delete btn-sm"
                                            @click="deletePackage(pack.id)">Supprimer</button>
                                    </template>
                                    <template v-else>
                                        <i class="bi bi-chevron-down" style="cursor:pointer;"
                                            @click="toggleSubscribers(pack?.id)"></i>
                                    </template>
                                </div>
                            </div>
                            <template v-if="visibleSubscribers[pack?.id]">
                                <div v-for="payment in pack.payments" :key="payment.id" class="row">
                                    <div :class="['col-xl-9', 'col-lg-7', 'col-md-6', 'col-sm-9']">
                                        <p class="card-text">* {{ payment?.participant.pseudo }}</p>
                                    </div>
                                    <div :class="['col-xl-3', 'col-lg-5', 'col-md-6', 'col-sm-3']">
                                        <i class="bi bi-currency-dollar me-2"></i>
                                        <span class="card-text">{{ payment?.net_to_coach }}</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <span class="mb-4"></span>
            </div>
            <div v-else>
                <p class="text-center" v-t="'package.noPackage'"></p>
            </div>
        </div>
    </div>
</template>


<script>
import { priceMixin } from '@/mixins/priceMixin';
import { timeMixin } from '@/mixins/timeMixin';
export default {
    name: 'CoachPackagesTile',
    mixins: [timeMixin, priceMixin],
    components: {
    },
    computed: {
        currentUser() {
            return this.$store.getters['user/currentUser'];
        },
        packages() {
            return this.$store.getters['coachPackages/packages'];
        },
        hasPackages() {
            return this.$store.getters['coachPackages/hasPackages'];
        },
    },
    data() {
        return {
            visibleSubscribers: {},
        }
    },
    mounted() {
    },
    methods: {
        getClassCard(pack) {
            let now = new Date().toISOString();
            if (pack.start_date <= now && now <= pack.end_date) {
                return 'package-active';
            }
            return '';
        },
        toggleSubscribers(packageId) {
            // Basculer l'état de visibilité en assignant une nouvelle valeur
            if (this.visibleSubscribers[packageId] !== undefined) {
                this.visibleSubscribers[packageId] = !this.visibleSubscribers[packageId];
            } else {
                // Si la clé n'existe pas, initialiser à true pour "montrer"
                this.visibleSubscribers = { ...this.visibleSubscribers, [packageId]: true };
            }
        },
        sumOfPaymentAmounts(pack) {
            // if package is null
            if (!pack || !pack.payments) {
                return 0;
            }
            return pack.payments.reduce((total, payment) => total + payment.net_to_coach, 0);
        },
        async deletePackage(packageId) {
            try {
                await this.$store.dispatch('coachPackages/deletePackage', packageId);
                this.$showToast('success', this.$t('package.deleteSuccess'));
            } catch (error) {
                this.$showToast('error', this.$t('package.deleteError'));
                console.error(error);
            }
        }
    }
}
</script>

<style scoped>
.bi {
    color: var(--primary-color);
}

.package-active {
    border: 2px solid var(--primary-color);
}
</style>