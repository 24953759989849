<!-- AdminManageCustomer.vue -->
<template>
  <div class="container flex-grow-1 ml-box mt-4 mb-4 bg-white">
    <div class="container-fluid mt-4 mb-4">
      <h2 class="ml-title mt-4">Nos membres</h2>
      <!-- Partie Filtre -->
      <div class="d-flex align-items-start ml-title mt-1">
        <div>
          <i class="bi bi-people me-2"></i>:<span class="ms-2 me-2">{{ users.length }}</span>
        </div>
        <div class="ms-2 me-2">-</div>
        <div :class="{ 'border border-info': activeFilter === 'has_plan' }" @click="setFilter('has_plan')"
          class="cursor-pointer">
          <i class="bi bi-fire ms-2 me-2"></i>:<span class="ms-2 me-2">{{ users.filter(user => user.has_plan).length
            }}</span>
        </div>
        <div class="ms-2 me-2">-</div>
        <div :class="{ 'border border-info': activeFilter === 'has_free_offer' }" @click="setFilter('has_free_offer')"
          class="cursor-pointer">
          <i class="bi bi-gift ms-2 me-2"></i>:<span class="ms-2 me-2">{{ users.filter(user =>
            user.has_free_offer).length }}</span>
        </div>
        <div :class="{ 'border border-info': activeFilter === 'free_session_activation_date' }"
          @click="setFilter('free_session_activation_date')" class="cursor-pointer">
          <i class="bi bi-rocket-takeoff ms-2 me-2"></i>:<span class="ms-2 me-2">{{ users.filter(user =>
            !user.free_session_activation_date).length }}</span>
        </div>
        <div class="ms-2 me-2">-</div>
        <div :class="{ 'border border-info': activeFilter === 'is_beta_tester' }" @click="setFilter('is_beta_tester')"
          class="cursor-pointer">
          <i class="bi bi-eyedropper ms-2 me-2"></i>:<span class="ms-2 me-2">{{ users.filter(user =>
            user.is_beta_tester).length }}</span>
        </div>
      </div>

      <!-- Zone de texte pour la recherche -->
      <div class="d-flex align-items-start ml-title mt-1 mb-2">
        <input type="text" v-model="searchQuery" placeholder="Rechercher un participant" class="form-control" />
      </div>

      <div class="row g-4">
        <div class="col-12" v-for="user in filteredUsers()" :key="user.id">
          <div class="card">
            <PeopleTile :user="user" @toggle-plan="togglePlan" @toggle-beta-tester="toggleBetaTester"
              @toggle-free-offer="toggleFreeOffer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from '@/api/AdminService';
import PeopleTile from '@/components/admin/PeopleTile.vue';
import { timeMixin } from '@/mixins/timeMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'AdminManageParticipants.vue',
  components: {
    PeopleTile
  },
  mixins: [timeMixin],
  data() {
    return {
      users: [],
      activeFilter: null,
      searchQuery: '',
    };
  },
  mounted() {
    this.fetchParticipants();
  },
  computed: {
    ...mapGetters({
      packages: 'participantPackages/packages',
    }),
  },
  methods: {
    async fetchParticipants() {
      try {
        this.users = await AdminService.getParticipants();
        this.populateCoachPseudos();
      } catch (error) {
        console.error(error);
      }
    },
    populateCoachPseudos() {
      this.users.forEach(user => {
        user.participant_valid_packages.forEach(pack => {
          const foundPackage = this.packages.find(p => p.id === pack.id);
          if (foundPackage) {
            pack.coach_pseudo = foundPackage.coach.pseudo;
          }
        });
      });
    },
    async togglePlan(user) {
      try {
        user.has_plan = !user.has_plan;
        const payload = { has_plan: user.has_plan };
        await AdminService.updateUser(user.id, payload);
      } catch (error) {
        console.error('Erreur lors de la mise à jour du plan :', error);
        this.$showToast('error', 'Erreur lors de la mise à jour du plan');
        user.has_plan = !user.has_plan;
      }
    },
    async toggleBetaTester(user) {
      try {
        user.is_beta_tester = !user.is_beta_tester;
        const payload = { is_beta_tester: user.is_beta_tester };
        await AdminService.updateUser(user.id, payload);
      } catch (error) {
        console.error('Erreur lors de la mise à jour en tant que beta tester :', error);
        this.$showToast('error', 'Erreur lors de la mise à jour en tant que beta tester');
        user.is_beta_tester = !user.is_beta_tester;
      }
    },
    async toggleFreeOffer(user) {
      try {
        user.has_free_offer = !user.has_free_offer;
        const payload = { has_free_offer: user.has_free_offer };
        await AdminService.updateUser(user.id, payload);
      } catch (error) {
        console.error('Erreur lors de la mise à jour du cours gratuit :', error);
        this.$showToast('error', 'Erreur lors de la mise à jour du cours gratuit');
        user.has_free_offer = !user.has_free_offer;
      }
    },
    setFilter(filterType) {
      this.activeFilter = this.activeFilter === filterType ? null : filterType;
    },
    filteredUsers() {
      let filtered = this.users;

      if (this.activeFilter) {
        filtered = filtered.filter(user => {
          if (this.activeFilter === 'has_plan') return user.has_plan;
          if (this.activeFilter === 'has_free_offer') return user.has_free_offer;
          if (this.activeFilter === 'is_beta_tester') return user.is_beta_tester;
          if (this.activeFilter === 'free_session_activation_date') return !user.free_session_activation_date;
          return true;
        });
      }

      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(user => {
          const email = user.email ? user.email.toLowerCase() : '';
          const pseudo = user.pseudo ? user.pseudo.toLowerCase() : '';
          const firstName = user.name ? user.name.toLowerCase() : '';
          const lastName = user.surname ? user.surname.toLowerCase() : '';
          return (
            email.includes(query) ||
            pseudo.includes(query) ||
            firstName.includes(query) ||
            lastName.includes(query)
          );
        });
      }

      return filtered;
    }

  },
};
</script>

<style scoped>
.illimited,
.bi {
  color: var(--primary-color);
}

.cursor-pointer {
  cursor: pointer;
}
</style>
