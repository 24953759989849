<template>
    <div class="container ml-box mb-4 bg-white">
        <div class="row g-0 mb-4">
            <div class="col-11 mt-4">
                <h2 class="ml-title">{{ $t('liveSession.myOldSessions') }}</h2>
            </div>
            <div class="col-1 mt-4">
                <i class="bi bi-chevron-down" style="cursor:pointer;" @click="toggleCollapse"></i>
            </div>
        </div>
        <nav v-if="!isCollapsed">
            <div class="container-fluid">
                <div v-if="hasLiveSessions" class="row g-4">
                    <div class="col-12" v-for="liveSession in liveSessions" :key="liveSession.id">
                        <CoachLiveSessionTile :liveSession="liveSession" :isPastLiveSession="true" />
                    </div>
                    <span class="mb-4"></span>
                </div>
                <div v-else>
                    <p class="text-center">{{ $t('liveSession.myOldSessionsNothing') }}</p>
                </div>
            </div>
        </nav>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
import CoachLiveSessionTile from './CoachLiveSessionTile.vue';

export default {
    name: 'CoachPastLiveSessionsTile',
    components: {
        CoachLiveSessionTile
    },
    data() {
        return {
            isCollapsed: false,
        };
    },
    mounted() {
    },
    computed: {
        ...mapGetters({
            liveSessions: 'coachLiveSessions/pastLiveSessions',
            hasLiveSessions: 'coachLiveSessions/hasPastLiveSessions',
        }),
    },
    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed;
        },
    },
}
</script>
<style></style>