<!-- Navbar.vue -->
<template>
  <div class="bg-dark sticky-top mb-lg-4 mb-0">
    <nav class="navbar navbar-expand-lg navbar-dark container bg-dark">
      <!-- Ecran mobile : Logo solo centré -->
      <Logo class="d-lg-none mx-auto" />

      <!-- Grand Ecran : Logo à gauche -->
      <Logo class="d-none d-lg-block" />

      <!-- Slogan centré sur les grands écrans -->
      <Slogan />

      <!-- Grand Ecran : Menu à droite -->
      <DesktopNavbar />
    </nav>
  </div>

  <!-- Barre de navigation mobile -->
  <MobileNavbar />
  <FeedbackModal />
</template>

<script>
import FeedbackModal from '@/components/FeedbackModal.vue';
import DesktopNavbar from '@/components/navbar/DesktopNavbar.vue';
import Logo from '@/components/navbar/Logo.vue';
import MobileNavbar from '@/components/navbar/MobileNavbar.vue';
import Slogan from '@/components/navbar/Slogan.vue';

import logoML from '@/assets/logoML.png';
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { popoverMixin } from '@/mixins/popoverMixin';
import { timeMixin } from '@/mixins/timeMixin';
import { webViewMixin } from '@/mixins/webViewMixin';
import api from '@/services/api.js';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'NavbarVue',
  mixins: [popoverMixin, webViewMixin, liveSessionMixin, timeMixin],
  components: {
    FeedbackModal,
    Logo,
    Slogan,
    DesktopNavbar,
    MobileNavbar
  },
  data() {
    return {
      logoSrc: logoML,
    };
  },
  computed: {
    isDevelopment() {
      return process.env.VUE_APP_ENV === 'development';
    },
    ...mapGetters({
      currentUser: 'user/currentUser',
      isLoggedIn: 'user/isLoggedIn',
      loggedInService: 'user/loggedInService',
      nextSevenDays: 'filters/nextSevenDays',
      selectedCoach: 'filters/selectedCoach',
      selectedDate: 'filters/selectedDate',
      selectedSport: 'filters/selectedSport',
      showAllReplays: 'filters/showAllReplays',
      uniqueCoaches: 'filters/uniqueCoaches',
      uniqueSports: 'filters/uniqueSports',
    }),
    localSelectedCoach: {
      get() {
        return this.selectedCoach;
      },
      set(value) {
        this.$store.dispatch('filters/setSelectedCoach', value);
      }
    },
    localSelectedSport: {
      get() {
        return this.selectedSport;
      },
      set(value) {
        this.$store.dispatch('filters/setSelectedSport', value);
      }
    },
    localSelectedDate: {
      get() {
        return this.selectedDate;
      },
      set(value) {
        this.$store.dispatch('filters/setSelectedDate', value);
      }
    }
  },
  watch: {
    isLoggedIn(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resetPopovers();
      }
    }
  },
  methods: {
    handleHomeClick(event) {
      if (this.$route.name === 'home') {
        event.preventDefault();
        window.location.reload();
      }
    },
    applyFilters() {
      this.$store.dispatch('filters/applyFilters');
    },
    statusChangeCallback(response) {
      if (response.status === 'connected') {
        this.handleFacebookLogin(response);
      } else {
        console.log('User is not logged in');
      }
    },
    getCourseLinkHref() {
      if (!this.currentUser) {
        return "#";
      } else if (this.currentUser.is_coach_validated === true) {
        return "/coach/dashboard";
      } else if (this.currentUser.is_coach_validated === false) {
        return "/enrollment-done-page";
      } else if (this.currentUser.is_coach_validated === null) {
        return "/coach-registration-form";
      } else {
        return "#";
      }
    },
    setLocale(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('locale', lang);
      if (this.isLoggedIn) {
        api.put('/v1/users/me/locale', {
          language: lang
        });
      }
    },
    showAllReplaysAction() {
      this.$store.dispatch('filters/toggleShowAllReplays');
    },
    async localAddSession() {
      await api.post('/v1/local/sessions');
      window.location.reload();
    },
    async localAddPastSession() {
      await api.post('/v1/local/sessions/past');
      window.location.reload();
    },
    async localClearSession() {
      await api.delete('/v1/local/sessions');
      window.location.reload();
    },
    async localClearPayment() {
      await api.delete('/v1/local/payments');
      window.location.reload();
    },
    formatDate(dateString) {
      return moment(dateString).locale(this.$i18n.locale).format('dddd');
    },
  },
};
</script>

<style>
.slogan-container {
  display: inline-block;
}

.slogan {
  color: var(--white-color);
  text-align: center;
  animation: pulse 2s infinite;
}

.navbar-slogan {
  flex-grow: 1;
  text-align: center;
}

.menu-mobile,
.link,
#profileDropdown {
  text-decoration: none;
  color: var(--primary-color);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  70% {
    transform: scale(1.05);
    opacity: 0.80;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-item {
  cursor: pointer;
  border: 1px solid var(--primary-color);
  padding: 0.75rem;
  margin: 0.25rem;
  border-radius: 0.25rem;
  text-align: center;
  color: var(--primary-color);
}

.cursor-pointer {
  cursor: pointer;
}

.custom-icon-space {
  margin-right: 0.5rem;
}

.custom-font-size {
  font-size: 1.2rem;
}

.dropdown-item:active {
  background-color: var(--primary-color);
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nav-item span {
  display: block;
  text-align: center;
}


.navbar-mobile .nav-item {
  flex: 1 0 auto;
}

.navbar-mobile .nav-link {
  font-size: 0.75rem;
  padding: 0;
  color: var(--primary-color);
}

.icon-large {
  font-size: 1.50rem;
}

.navbar-mobile .nav-link .bi {
  vertical-align: middle;
}

.facebook {
  color: #1877f2;
  display: flex;
  align-items: center;
}

.facebook i.bi-facebook {
  font-size: 1.5rem;
}

.facebook:hover {
  background-color: #e9ebee;
  color: #1877f2;
}

.facebook i.bi-facebook:hover {
  color: #1877f2;
}
</style>
