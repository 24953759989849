/* api/payments.js */
import api from '@/services/api.js';

export default {
    async getStripePaymentIntent(paymentIntentId) {
        return await api.get(`/v1/payments/stripe/${paymentIntentId}`);
    },
    async getLinkByPaymentIntentId(paymentIntentId) {
        const response = await api.get(`/v1/payments/stripe/payment-intents/${paymentIntentId}/link`);
        return response.data.link;
    },
    async cancelPaymentIntent(paymentIntentId) {
        await api.post(`/v1/payments/stripe/payment-intents/${paymentIntentId}/cancel`);
    },
    async createPaymentIntent(payload) {
        return await api.post(`/v1/payments/stripe/payment-intents`, payload);
    },
    async updatePaymentIntent(paymentIntentId, payload) {
        return await api.post(`/v1/payments/stripe/payment-intents/${paymentIntentId}`, payload);
    },
    async getPaymentsByUser(userId) {
        const response = await api.get(`/v1/payments/users/${userId}`);
        return response.data.payments;
    }
};