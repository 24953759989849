<!-- CoachPackageForm.vue -->
<template>
    <div class="container ml-box mb-4 bg-white">
        <div class="row g-0 mb-2">
            <h2 class="ml-title mt-4" v-t="'package.create'"></h2>
        </div>
        <form id="subscritpionForm" @submit.prevent="createPackage">
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-sm-12 mb-2">
                    <input type="hidden" id="datepickerPackage" name="date">
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-12">

                    <div class="input-group mb-4">
                        <span class="input-group-text">
                            <i class="bi bi-currency-dollar"></i>
                        </span>
                        <select class="form-select" v-model="selectedPrice">
                            <option value="-2" disabled v-t="'package.price'"></option>
                            <option value="-1" v-t="'payment.voluntaryContribution'"></option>
                            <option v-for="i in range(10, 100, 2)" :key="i" :value="i">
                                {{ formatPrice(i) }}
                            </option>
                        </select>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-create mb-4" :disabled="isLoading">
                            <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"
                                aria-hidden="true"></span>
                            <span v-if="!isLoading" v-t="'package.create'"></span>
                            <span v-else v-t="'package.isLoading'"></span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { priceMixin } from '@/mixins/priceMixin';
import { easepick } from '@easepick/bundle';
import { LockPlugin } from '@easepick/lock-plugin';
import { RangePlugin } from '@easepick/range-plugin';

export default {
    name: 'CoachPackageForm',
    mixins: [liveSessionMixin, priceMixin],
    props: {
        sports: Array
    },
    data() {
        return {
            picker: null,
            selectedPrice: '-2',
            isLoading: false,
        };
    },
    mounted() {
        this.$nextTick(() => {

            const today = new Date();
            const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

            this.picker = new easepick.create({
                element: "#datepickerPackage",
                css: ["https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css"],
                zIndex: 10,
                lang: "fr-FR",
                grid: 2,
                calendars: 2,
                inline: true,
                header: "",
                plugins: [RangePlugin, LockPlugin],
                RangePlugin: {
                    locale: {
                        one: 'jour',
                        other: 'jours',
                    },
                },
                LockPlugin: {
                    minDate: minDate,
                    minDays: 6,
                    inseparable: true,
                }
            });
        });
    },
    methods: {
        checkData() {
            var datesVal = document.getElementById("datepickerPackage").value;
            var dates = datesVal.split(" - ");
            var begin = dates[0];
            var end = dates[1];
            const conditions = [
                { condition: !begin, message: this.$t('package.error.selectStartDate') },
                { condition: !end, message: this.$t('package.error.selectEndDate') },
                { condition: this.generatedBeginDate() < new Date().setHours(0, 0, 0, 0), message: this.$t('subscription.error.futureDate') },
                { condition: this.generatedEndDate() < new Date().toISOString(), message: this.$t('subscription.error.futureDate') },
                { condition: this.selectedPrice === '-2', message: this.$t('package.error.price') },
            ];

            const failedCondition = conditions.find(({ condition }) => condition);

            if (failedCondition) {
                this.$showToast('error', failedCondition.message);
                return false;
            }

            return true;
        },
        generatedBeginDate() {
            var datesVal = document.getElementById("datepickerPackage").value;
            if (!datesVal) {
                return null;
            }
            var dates = datesVal.split(" - ");
            var begin = dates[0];
            const selectedDateString = `${begin}T00:00:00`;
            const selectedDateObject = new Date(selectedDateString);
            return selectedDateObject.toISOString();
        },
        generatedEndDate() {
            var datesVal = document.getElementById("datepickerPackage").value;
            if (!datesVal) {
                return null;
            }
            var dates = datesVal.split(" - ");
            var end = dates[1];
            const selectedDateString = `${end}T23:59:00`;
            const selectedDateObject = new Date(selectedDateString);
            return selectedDateObject.toISOString();
        },
        async createPackage() {
            if (!this.checkData()) {
                return;
            }
            this.isLoading = true;
            const pack = {
                start_date: this.generatedBeginDate(),
                end_date: this.generatedEndDate(),
                price: this.selectedPrice,
            }

            try {
                await this.$store.dispatch('coachPackages/createPackage', pack);
                this.$showToast('success', this.$t('package.createSuccess'));
            } catch (error) {
                this.error = error.message || 'An error occurred while creating package.';
                this.$showToast('error', this.$t('package.createError'));
            } finally {
                this.isLoading = false;
            }

        },
    },
    beforeUnmount() {
        if (this.picker) {
            this.picker.destroy();  // Détruit l'instance pour éviter les fuites de mémoire
        }
    }
};
</script>
<style scoped>
.btn-create:disabled {
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
}

#datepickerSubscription {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.calendar,
.form-select,
.form-control {
    font: 500 20px/34px var(--primary-font);
}

.calendar>.days-grid>.day.selected {
    background-color: var(--primary-color) !important;
    color: var(--white-color);
}
</style>