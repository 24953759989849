// src/api/CoachService.js
import api from '@/services/api.js';

export default {
    async getCoachByPseudo(pseudo) {
        const response = await api.get(`/v1/coaches/${pseudo}`);
        return response.data.coach;
    },
    async getLiveSessions(coachId) {
        const response = await api.get(`/v1/coaches/${coachId}/live-sessions`);
        return response.data.liveSessions;
    },
    async getPastLiveSession(coachId) {
        const response = await api.get(`/v1/coaches/${coachId}/past-live-sessions`);
        return response.data.liveSessions;
    },
    async getPackagesForCoach(coachId) {
        const response = await api.get(`/v1/coaches/${coachId}/my-packages`);
        return response.data.packages;
    },
    async getPackages(coachId){
        const response = await api.get(`/v1/coaches/${coachId}/packages`);
        return response.data.packages;
    },
    async getReplays(coachId) {
        const response = await api.get(`/v1/coaches/${coachId}/replays`);
        return response.data.replays;
    },
    async sendNewStripeLink(coachId) {
        await api.post(`/v1/coaches/${coachId}/refresh-stripe-account-link`);
    },
};
