<!-- PaymentModal.vue -->
<template>
  <!-- Spinner Overlay Conditionnel -->
  <div v-if="isLoading"
    class="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-overlay">
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden" v-t="'payment.loading'"></span>
    </div>
  </div>

  <!-- Contenu de la modale -->
  <div v-if="isPaymentModalOpen" class="modal" ref="paymentModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <header class="modal-header bg-dark">
          <img :src="logoSrc" alt="Logo MovingLive" height="40">
          <button type="button" class="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"
            @click="closeManuallyModal"></button>
        </header>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 text-center">
                <h2 class="modal-title" v-t="getModalTitle"></h2>
              </div>
            </div>
            <div class="row">
              <!-- présentation de l'offre -->
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12 offset-xl-1">
                <!-- Tuile info unitaire -->
                <article v-if="selectedLiveSession" class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-3 border-bottom border-dark">
                      <i class="bi bi-bag-check me-2"></i>
                      <h4 class="card-text mb-0" v-t="'payment.session.title'"></h4>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-calendar-check me-2"></i>
                      <span>{{ formatDayMonthHourMinute(selectedLiveSession?.start_time) }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-activity me-2"></i>
                      <span>
                        {{ selectedLiveSession?.sport.name }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-person me-2"></i>
                      <span>
                        {{
                          selectedLiveSession?.coach.pseudo
                        }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-sunglasses me-2"></i>
                      <span class="card-text mb-0" v-t="'payment.noCommitment'"></span>
                    </div>
                    <template v-if="selectedLiveSession?.price === -1">
                      <div class="d-flex align-items-center mb-1">
                        <i class="bi bi-bag-heart me-2"></i>
                        <p class="card-text mb-0" v-t="'payment.selectAmountOption'"></p>
                      </div>
                      <div class="input-group">
                        <span class="input-group-text"><i class="bi bi-currency-dollar"></i></span>
                        <select v-model="defaultLiveSessionPrice" class="form-select" id="defaultLiveSessionPrice">
                          <option v-for="price in unitPricesRange" :key="price" :value="price">
                            {{ formatPrice(price) }}
                          </option>
                        </select>
                      </div>
                    </template>
                    <template v-else>
                      <div class="d-flex align-items-center">
                        <i class="bi bi-currency-dollar me-2"></i>
                        <p class="card-text">
                          {{ formatPrice(selectedLiveSession?.price) }}
                        </p>
                      </div>
                    </template>
                  </div>
                </article>
                <!-- Tuile info forfait -->
                <article v-if="selectedPackage" class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-3 border-bottom border-dark">
                      <i class="bi bi-infinity me-2"></i>
                      <h4 class="card-text mb-0" v-t="'payment.package.title'"></h4>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-calendar-range me-2"></i>
                      <span>{{ formatDayMonth(packageBeginDate) }}</span>
                      <i class="bi bi-arrow-right mx-2"></i>
                      <span>{{ formatDayMonth(selectedPackage?.end_date) }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-bag me-2"></i>
                      <span>
                        {{ $t('payment.package.resume', { nbDays: packageDays, coach: selectedCoach?.pseudo })
                        }}
                      </span>
                    </div>
                    <!--
                      <div class="d-flex align-items-center">
                      <i class="bi bi-activity me-2"></i>
                      <span>{{ $t('payment.package.nbSessions', { nbSessions: nbSessionsForSelectedPackage, }) }}</span>
                    </div>
                    -->
                    <div class="d-flex align-items-center">
                      <i class="bi bi-sunglasses me-2"></i>
                      <span class="card-text mb-0" v-t="'payment.noCommitment'"></span>
                    </div>
                    <template v-if="selectedPackage?.price === -1">
                      <div class="d-flex align-items-center">
                        <i class="bi bi-bag-heart me-2"></i>
                        <p class="card-text mb-0" v-t="'payment.voluntaryContribution'"></p>
                      </div>
                      <div class="input-group mb-3">
                        <span class="input-group-text"><i class="bi bi-currency-dollar"></i></span>
                        <select v-model="defaultPackagePrice" class="form-select" id="defaultPackagePrice">
                          <option v-for="price in packagePricessRange" :key="price" :value="price">
                            {{ formatPrice(price) }}
                          </option>
                        </select>
                      </div>
                    </template>
                    <template v-else>
                      <div class="d-flex align-items-center">
                        <i class="bi bi-currency-dollar me-2"></i>
                        <p class="card-text mb-0">{{ formatPrice(selectedPackage?.price) }}</p>
                      </div>
                    </template>
                  </div>
                </article>
                <!-- Tuile info replay -->
                <article v-if="selectedReplay" class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center mb-3 border-bottom border-dark">
                      <i class="bi bi-bag-check me-2"></i>
                      <h4 class="card-text mb-0" v-t="'payment.replay.title'"></h4>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-activity me-2"></i>
                      <span>
                        {{ selectedReplay?.sport.name }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-person me-2"></i>
                      <span>
                        {{
                          selectedReplay?.coach.pseudo
                        }}
                      </span>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-sunglasses me-2"></i>
                      <span class="card-text mb-0" v-t="'payment.noCommitment'"></span>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-repeat me-2"></i>
                      <span class="card-text mb-0" v-t="'payment.replay.availability'"></span>
                    </div>
                    <template v-if="selectedReplay?.price === -1">
                      <div class="d-flex align-items-center">
                        <i class="bi bi-bag-heart me-2"></i>
                        <span class="card-text mb-0" v-t="'payment.selectAmountOption'"></span>
                      </div>
                      <div class="input-group">
                        <span class="input-group-text"><i class="bi bi-currency-dollar"></i></span>
                        <select v-model="defaultReplayPrice" class="form-select" id="defaultReplayPrice">
                          <option v-for="price in unitPricesRange" :key="price" :value="price">
                            {{ formatPrice(price) }}
                          </option>
                        </select>
                      </div>
                    </template>
                    <template v-else>
                      <div class="d-flex align-items-center">
                        <i class="bi bi-currency-dollar me-2"></i>
                        <p class="card-text mb-0">{{ formatPrice(selectedReplay?.price) }}</p>
                      </div>
                    </template>
                  </div>
                </article>

                <!-- info package disponible -->
                <div v-if="selectedLiveSession?.package_id" class="alert alert-light mt-2" role="alert">
                  <i class="bi bi-info-square"></i>
                  {{ $t('payment.packageAvailable', { coachName: selectedLiveSession?.coach.pseudo }) }}
                  <br>
                  <div class="d-flex justify-content-end">
                    <button class="btn btn-ml-secondary mt-3 col-12 col-md-6" type="button"
                      @click="switchToPackage(selectedLiveSession?.package_id)">
                      {{ $t('payment.switchToPackage') }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- moyen de paiement -->
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12 mt-2 mt-md-0">
                <article class="card">
                  <div class="card-body">
                    <div id="payment-element"></div>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                      <button id="submit" class="btn btn-moving-live mt-3 col-12 col-md-4" type="button"
                        @click="confirmStripePayment">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"
                          aria-hidden="true"></span>
                        <span v-t="isLoading ? 'payment.payInProcess' : 'payment.pay'"></span>
                      </button>
                    </div>
                  </div>
                </article>
                <div v-if="!currentUser?.country || !currentUser?.postal_code" class="alert alert-light mt-2"
                  role="alert">
                  <i class="bi bi-info-square"></i>
                  {{ $t('payment.fillProfileInfo') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PackageService from '@/api/PackageService';
import PaymentService from '@/api/PaymentService';
import logoML from '@/assets/logoML.png';
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { priceMixin } from '@/mixins/priceMixin';
import { timeMixin } from '@/mixins/timeMixin';
import { zoomMixin } from '@/mixins/zoomMixin';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PaymentModal',
  mixins: [liveSessionMixin, timeMixin, priceMixin, zoomMixin],
  data() {
    return {
      defaultLiveSessionPrice: '5',
      defaultPackagePrice: '45',
      defaultReplayPrice: 3,
      isLoading: false,
      logoSrc: logoML,
      nbSessionsForSelectedPackage: 0,
      packagePricessRange: Array.from({ length: 81 }, (_, i) => 20 + i), // 20 à 100
      paymentElement: null,
      paymentIntentId: null,
      stripe: null,
      unitPricesRange: Array.from({ length: 65 }, (_, i) => 3 + i * 0.5), // 3 à 35
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
      isLoggedIn: 'user/isLoggedIn',
      isPaymentModalOpen: 'payment/isPaymentModalOpen',
      selectedLiveSession: 'payment/selectedLiveSession',
      selectedCoach: 'payment/selectedCoach',
      selectedPackage: 'payment/selectedPackage',
      selectedReplay: 'payment/selectedReplay',
    }),
    packageDays() {
      if (!this.selectedPackage.start_date || !this.selectedPackage.end_date) {
        return 0;
      }

      const startDate = new Date(this.selectedPackage.start_date);
      const endDate = new Date(this.selectedPackage.end_date);
      const now = new Date();

      let differenceInTime;
      if (now > startDate) {
        differenceInTime = endDate.getTime() - now.getTime();
      } else {
        differenceInTime = endDate.getTime() - startDate.getTime();
      }
      return Math.round(differenceInTime / (1000 * 3600 * 24));
    },
    packageBeginDate() {
      const today = new Date();
      if (!this.selectedPackage.start_date || !this.selectedPackage.end_date) {
        return today;
      }
      return new Date(this.selectedPackage.start_date) < today ? today : this.selectedPackage.start_date;
    },
    getModalTitle() {
      if (this.selectedLiveSession) {
        return this.$t('payment.session.lastStep', { sportName: this.selectedLiveSession.sport.name });
      } else if (this.selectedPackage) {
        return this.$t('payment.package.lastStep');
      } else if (this.selectedReplay) {
        return this.$t('payment.replay.lastStep', { sportName: this.selectedReplay.sport.name });
      }
      return '';
    },
  },
  watch: {
    isPaymentModalOpen(newValue) {
      if (newValue) {
        this.fetchUserProfile();
        this.$nextTick(() => {
          this.initStripe();
        });
      }
    },
    defaultLiveSessionPrice(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updatePaymentIntent();
      }
    },
    defaultPackagePrice(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updatePaymentIntent();
      }
    },
    defaultReplayPrice(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updatePaymentIntent();
      }
    },
    selectedPackage(newValue) {
      if (newValue) {
        this.fetchNbSessionsForPackage();
      }
    },
  },
  methods: {
    ...mapActions({
      fetchUserProfile: 'user/fetchUserProfile',
      closeModal: 'payment/closePaymentModal',
      resetPaymentStore: 'payment/reset',

    }),
    /* global Stripe */
    async cancelPaymentIntent() {
      if (!this.paymentIntentId) {
        console.error("Payment Intent ID manquant");
        return;
      }
      try {
        await PaymentService.cancelPaymentIntent(this.paymentIntentId);
        this.paymentIntentId = null;
      } catch (error) {
        console.error("Erreur lors de l'annulation du Payment Intent:", error);
      }
    },
    closeManuallyModal() {
      this.cancelPaymentIntent();
      this.resetPaymentStore();
    },

    async fetchNbSessionsForPackage() {
      if (!this.selectedPackage) {
        this.nbSessionsForSelectedPackage = 0;
        return;
      }
      try {
        this.nbSessionsForSelectedPackage = await PackageService.nbLiveSessionsInPackage(this.selectedPackage.id);
      } catch (error) {
        console.error("Erreur lors de la récupération des sessions pour l'abonnement :", error);
        this.nbSessionsForSelectedPackage = 0;
      }
    },
    getAmountForPackage() {
      return this.selectedPackage.price === -1 ? this.defaultPackagePrice : this.selectedPackage.price;
    },
    getAmountForUnit() {
      return this.selectedLiveSession.price === -1 ? this.defaultLiveSessionPrice : this.selectedLiveSession.price;
    },
    getAmountForReplay() {
      return this.selectedReplay.price === -1 ? this.defaultReplayPrice : this.selectedReplay.price;
    },
    getAmount() {
      if (this.selectedLiveSession) {
        return this.getAmountForUnit();
      } else if (this.selectedPackage) {
        return this.getAmountForPackage();
      } else {
        return this.getAmountForReplay();
      }
    },
    async initStripe() {
      if (!this.selectedLiveSession && !this.selectedPackage && !this.selectedReplay) {
        this.$showToast('error', this.$t('payment.error.noItemSelected'));
        return;
      }
      try {
        await this.waitForStripe();

        let description;
        if (this.selectedLiveSession) {
          description = 'Séance de ' + this.selectedLiveSession.sport.name + ' le ' + this.formatDayMonthHourMinute(this.selectedLiveSession?.start_time) + ', acheté par ' + this.currentUser?.pseudo + ', offert par ' + this.selectedCoach?.pseudo;
        } else if (this.selectedPackage) {
          description = 'Forfait du ' + this.formatDayMonth(this.packageBeginDate) + ' au ' + this.formatDayMonth(this.selectedPackage?.end_date) + ', acheté par ' + this.currentUser?.pseudo + ', offert par ' + this.selectedCoach?.pseudo;
        } else {
          description = 'Replay de ' + this.selectedReplay.sport.name + ' acheté le ' + this.formatDayMonthHourMinute(new Date()) + ' avec ' + this.selectedReplay.coach.pseudo + ', acheté par ' + this.currentUser?.pseudo;
        }

        const payload = {
          amount: this.getAmount() * 100,
          currency: 'cad',
          coach_id: this.selectedCoach.id,
          live_session_id: this.selectedLiveSession?.id,
          package_id: this.selectedPackage?.id,
          replay_id: this.selectedReplay?.id,
          description: description,
        };
        const response = await PaymentService.createPaymentIntent(payload);
        this.paymentIntentId = response.data.payment_intent_id;

        if (response.data.client_secret) {

          this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
          const options = {
            clientSecret: response.data.client_secret,
          };

          this.elements = this.stripe.elements(options);
          this.paymentElement = this.elements.create('payment', {
            defaultValues: {
              billingDetails: {
                email: (this.currentUser?.email || ''),
                name: (this.currentUser?.surname || '') + ' ' + (this.currentUser?.name || ''),
                phone: (this.currentUser?.phone_number || ''),
                address: {
                  postal_code: (this.currentUser?.postal_code || ''),
                  country: (this.currentUser?.country || ''),
                },
              },
            },
          });
          this.paymentElement.mount('#payment-element');
        } else {
          this.$showToast('error', this.$t('payment.error.stripeSecret'));
          console.error("Erreur lors de la récupération du client secret pour Stripe.");
        }
      } catch (error) {
        this.$showToast('error', this.$t('payment.error.stripeLoading'));
        console.error("Erreur lors de l'initialisation de Stripe:", error);
      }
    },
    async updatePaymentIntent() {
      if (!this.stripe || !this.paymentIntentId) {
        console.error("Stripe ou PaymentIntent non initialisé.");
        return;
      }
      try {
        this.isLoading = true;
        const payload = {
          amount: this.getAmount() * 100,
        };
        const response = await PaymentService.updatePaymentIntent(this.paymentIntentId, payload);

        if (response.data.client_secret) {
          await this.elements.fetchUpdates();
        } else {
          this.$showToast('error', this.$t('payment.error.stripeSecret'));
        }
      } catch (error) {
        this.$showToast('error', this.$t('payment.error.updatePrice'));
        console.error("Erreur lors de la mise à jour du Payment Intent:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async performPayment() {
      const result = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: process.env.VUE_APP_BASE_URL + '/payment-result',
        },
        redirect: 'if_required',
      });
      return result;
    },

    async confirmStripePayment() {
      if (!this.stripe || !this.elements) {
        return;
      }
      this.isLoading = true;
      try {
        const result = await this.performPayment();
        if (result.error) {
          this.handlePaymentErrors(result.error);
          return
        }

        if (result.paymentIntent == null || result.paymentIntent.status !== 'succeeded') {
          this.handlePaymentErrors("Le paiement n'as pas pu etre effectué, veuillez réessayer.");
          return;
        }

        this.fetchZoomLink(result.paymentIntent.id);
        this.updatepackageProfileAndNotify(this.selectedPackage);
        this.trackPurchaseEvent(result.paymentIntent.id);
        this.resetPaymentStore();
      } catch (error) {
        this.$showToast('error', this.$t('payment.error.system', { message: error.message }));
        console.error("Erreur lors de la confirmation du paiement:", error);
      } finally {
        this.isLoading = false;
      }
    },

    handlePaymentErrors(error) {
      if (error.code === 'incomplete' && error.type === 'validation_error') {
        return;
      }

      if (error.code === 'incomplete_number' || error.code === 'incomplete_cvc' || error.code === 'incomplete_expiry') {
        return;
      }

      const errorMessageMapping = {
        'validation_error': 'payment.validationError',
        'api_connection_error': 'payment.networkError',
        'default': 'payment.generalError'
      };
      const errorKey = errorMessageMapping[error.type] || errorMessageMapping['default'];
      this.$showToast('error', this.$t(errorKey, { message: error.message }));

      if (error.type === 'api_connection_error') {
        this.retryPayment();
      }
    },

    async fetchZoomLink(paymentIntentId) {

      if (this.selectedPackage) {
        // si achat forfait, pas besoin de lien zoom
        return;
      }
      try {
        const link = await PaymentService.getLinkByPaymentIntentId(paymentIntentId);
        this.closeModalToZoom(link);
      } catch (error) {
        this.$showToast('error', this.$t('payment.error.link'));
        console.error("Erreur lors de la récupération du lien Zoom :", error);
      } finally {
        this.isLoading = false;
      }
    },
    closeModalToZoom(zoom_link) {
      this.closeModal();
      this.openZoomLink(zoom_link);
    },
    updatepackageProfileAndNotify(packageToAdd) {
      if (packageToAdd) {
        this.$store.dispatch('user/addPackage', packageToAdd);
        this.$showToast('success', this.$t('payment.package.success', { coach: this.selectedCoach.pseudo, nbDays: this.packageDays }));
      }
    },
    async retryPayment() {
      await new Promise(resolve => setTimeout(resolve, 5000));
      return this.confirmStripePayment();
    },
    waitForStripe() {
      return new Promise((resolve) => {
        if (window.stripeLoaded) {
          resolve();
        } else {
          const checkStripeLoaded = setInterval(() => {
            if (window.stripeLoaded) {
              clearInterval(checkStripeLoaded);
              resolve();
            }
          }, 100);
        }
      }).catch(err => console.error('Failed to load Stripe:', err));
    },
    trackPurchaseEvent(paymentIntentId) {
      try {
        let eventType = '';
        let eventValue = 0;
        let coach = '';

        if (this.selectedLiveSession) {
          eventType = 'live_session';
          eventValue = this.getAmountForUnit();
          coach = this.selectedLiveSession.coach.pseudo;
        } else if (this.selectedPackage) {
          eventType = 'package';
          eventValue = this.getAmountForPackage();
          coach = this.selectedCoach.pseudo;
        } else if (this.selectedReplay) {
          eventType = 'replay';
          eventValue = this.getAmountForReplay();
          coach = this.selectedReplay.coach.pseudo;
        }

        if (eventType && eventValue) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'purchase',
            eventType: eventType,
            eventValue: eventValue,
            coach: coach,
            paymentIntentId: paymentIntentId,
          });
        }
      } catch (error) {
        console.error("Erreur lors du tracking de l'achat :", error);
      }

    },
    switchToPackage(packageId) {
      // récuperer l'objet package du store payment.js en fonction de son ID
      const packageToSwitch = this.$store.getters['participantPackages/getPackageById'](packageId);
      if (packageToSwitch) {
        this.cancelPaymentIntent();
        // Appeler openPaymentModal du store payment.js avec le package en paramètre
        this.$store.dispatch('payment/reset');
        this.$store.commit('payment/openPaymentModal', { selectedPackage: packageToSwitch, selectedCoach: packageToSwitch.coach });
        this.initStripe();
      } else {
        console.error('Package not found with ID:', packageId);
      }
    }
  },
}
</script>

<style scoped>
.bg-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.modal-body {
  max-height: calc(100vh - 120px);
  /* 100vh est la hauteur totale de la vue, 120px est ajustable selon votre header/footer */
  overflow-y: auto;
  /* Affiche une barre de défilement seulement si le contenu déborde */
}


.modal-title {
  color: var(--primary-color);
}

.bi {
  color: var(--primary-color);
}

.modal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  /*   z-index: 1; */
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}
</style>
