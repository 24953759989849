// src/api/replayService.js
import api from '@/services/api.js';

export default {
    async createReplay(replayData) {
        const response = await api.post('/v1/replays', replayData);
        return response.data.replay;
    },
    async getReplays() {
        const response = await api.get('/v1/replays');
        return response.data.replays;
    },
    async getReplaysByCoach(coachId) {
        const response = await api.get(`/v1/coaches/${coachId}/replays`);
        return response.data.replays;
    },
    async deleteReplay(id) {
        await api.delete(`/v1/replays/${id}`);
    },
    async getReplayLink(replayId) {
        const response = await api.get(`/v1/replays/${replayId}/link`);
        console.log('Replay link:', response);
        return response.data.link;
    },
    async recordParticipant(replayId) {
        await api.post(`/v1/replays/${replayId}/participants`);
    }
};