<template>
    <div class="container-fluid bg-danger-subtle mb-0 mb-md-4 text-center" id="concept">
        <div>
            Le navigateur web intégré de Facebook ne permet pas la connection rapide avec Facebook ou Google
        </div>
        <a href="https://movinglive.ca" @click="openInBrowser" class="btn mt-2 mb-3">Ouvrir MovingLive avec mon
            navigateur par défaut</a>
    </div>
</template>

<script>

export default {
    name: 'WebViewAlert',
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
        openInBrowser() {

            // For Android devices
            var intentUrl = 'intent://' + window.location.host + window.location.pathname + '#Intent;scheme=https;package=com.android.chrome;end';
            window.location = intentUrl;

            // For iOS devices
            setTimeout(function () {
                window.location = 'https://' + window.location.host + window.location.pathname;
            }, 25);
        }
    }
};
</script>
<style scoped>
.btn {
    background-color: var(--primary-color);
    color: var(--black-color);
}
</style>