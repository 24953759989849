
export const zoomMixin = {
    methods: {
        openZoomLink(link) {
            if (!link) {
                return;
            }

            if (this.isOnSafariIOS()) {
                window.location.href = link;
            } else {
                window.open(link, '_blank');
            }
        },
        isOnSafariIOS() {
            var ua = window.navigator.userAgent;
            var iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
            var webkit = ua.match(/WebKit/i);
            var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
            return iOSSafari;
        },
    }
}