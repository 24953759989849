import SportService from "@/api/SportService";

export default {
    namespaced: true,
    state() {
        return {
            sports: [],
        };
    },
    getters: {
        sports(state) {
            return state.sports;
        },
        hasSports(state) {
            return state.sports && state.sports.length > 0;
        },
    },
    mutations: {
        SET_SPORTS(state, sports) {
            state.sports = sports;
        },
        ADD_SPORT(state, sport) {
            state.sports.push(sport);
        },
        UPDATE_SPORT(state, sport) {
            const index = state.sports.findIndex(s => s.id === sport.id);
            state.sports[index] = sport;
        }
    },
    actions: {
        async fetchSports({ commit }) {
            try {
                const sports = await SportService.getSports();
                commit('SET_SPORTS', sports);
            } catch (error) {
                console.error('Error while fetching sports:', error);
            }
        },
        async createSport({ commit }, sportData) {
            try {
                const newSport = await SportService.createSport(sportData);
                commit('ADD_SPORT', newSport);
            } catch (error) {
                console.error('Error while creating sport:', error);
            }
        },
        async updateSport({ commit }, sportData) {
            try {
                const updatedSport = await SportService.updateSport(sportData);
                commit('UPDATE_SPORT', updatedSport);
            } catch (error) {
                console.error('Error while updating sport:', error);
            }
        }
    },
};