// src/api/AdminService.js
import api from '@/services/api.js';

export default {
    async getCoaches() {
        const response = await api.get('v1/admin/coaches/');
        return response.data.coaches;
    },
    async getParticipants() {
        const response = await api.get('v1/admin/participants/');
        return response.data.users;
    },
    async updateUser(userId, payload) {
        await api.put(`v1/admin/user/${userId}/`, payload);
    },
    async getPayments() {
        const response = await api.get('v1/admin/payments/');
        return response.data.payments;
    }
};
