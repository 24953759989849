<!-- CoachLiveSessionTile.vue -->
<template>
    <div class="card mb-3">
        <div class="card-body py-1 py-md-auto">
            <div class="row">
                <!-- Course Date for list by coach or sport -->
                <div class="col-xl-2 col-lg-3 col-md-4 col-6">
                    <i class="bi bi-calendar-fill me-2"></i>
                    <span class="course-time" :data-utc-time="localLiveSession.start_time">
                        {{ convertToLocalTimeWhitShortDay(localLiveSession.start_time) }}
                    </span>
                </div>

                <!-- Sport Info -->
                <div class="col-xl-2 col-lg-3 col-md-4 col-6">
                    <i v-if="!isEditing" class="bi bi-activity me-2"></i>
                    <select v-if="isEditing" class="form-select" v-model="localLiveSession.sport_id">
                        <option v-for="sport in sports" :key="sport.id" :value="sport.id">
                            {{ sport.name }}
                        </option>
                    </select>
                    <span v-else>{{ localLiveSession.sport.name }}</span>
                </div>

                <!-- Course Time -->
                <div class="col-xl-2 col-lg-3 col-md-4 col-6 ">
                    <i class="bi bi-clock-fill me-2"></i>
                    <span class="course-time" :data-utc-time="localLiveSession.start_time">
                        {{ convertToLocalTime(localLiveSession.start_time) }}
                    </span>
                </div>

                <!-- Duration Info -->
                <div class="col-xl-2 col-lg-3 col-md-4 col-6">
                    <i class="bi bi-stopwatch-fill me-2"></i>
                    {{ localLiveSession.duration }} min
                </div>

                <!-- Price Info -->
                <div class="col-xl-2 col-lg-3 col-md-4 col-6">
                    <i v-if="!isEditing" class="bi bi-currency-dollar me-2"></i>
                    <select v-if="isEditing" class="form-select" v-model="localLiveSession.price">
                        <option value="-1">{{ $t('liveSession.voluntaryContribution') }}</option>
                        <option value="0">{{ $t('liveSession.free') }}</option>
                        <option v-for="i in range(10, 30, 1)" :key="i" :value="i">
                            {{ i }}
                        </option>
                    </select>
                    <span v-else>{{ formatPrice(localLiveSession?.price) }}</span>
                </div>

                <!-- Booking Count Info -->
                <div class="col-xl-2 col-lg-3 col-md-4 col-6">
                    <i class="bi bi-bookmark me-2"></i>
                    <span data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom"
                        :data-bs-content="localLiveSession.bookers_names">
                        {{ localLiveSession.bookers_names?.length }}
                    </span>
                </div>

                <!-- Actions -->
                <template v-if="!isPastLiveSession">
                    <div class="col-12 d-flex justify-content-end">
                        <button v-if="isEditing" @click="cancelEdit" class="btn btn-back me-2">
                            <i class="bi bi-arrow-left"></i>
                        </button>
                        <a v-if="!isEditing" :href="localLiveSession.zoom_join_url" class="btn btn-zoom me-2"
                            target="_blank">
                            {{ $t('liveSession.zoom') }}
                        </a>
                        <button v-if="!isEditing" @click="isEditing = true" class="btn btn-update me-2">
                            {{ $t('liveSession.update') }}
                        </button>
                        <button v-else @click="updateLiveSession" class="btn btn-update me-2">
                            {{ $t('liveSession.save') }}
                        </button>
                        <button :disabled="isEditing" data-bs-toggle="modal"
                            :data-bs-target="'#confirmCancelModal-' + localLiveSession.id"
                            class="btn btn-delete">
                            {{ $t('liveSession.cancel.cancel') }}
                        </button>
                    </div>
                </template>
                <template v-else>
                    <!-- Nb participants -->
                    <div :class="['col-xl-9', 'col-lg-4', 'col-md-6', 'col-12']">
                        <i class="bi bi-person-fill-check me-2"></i>
                        <span data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom"
                            :data-bs-content="localLiveSession.participants_names">
                            {{ localLiveSession.participants_names?.length }}
                        </span>
                    </div>
                    <!-- total -->
                    <div :class="['col-xl-2', 'col-lg-3', 'col-md-5', 'col-12']">
                        <i class="bi bi-piggy-bank me-2"></i>
                        <span>{{ sumOfPaymentAmounts(localLiveSession) }}$</span>
                    </div>
                    <div :class="['col-xl-1', 'col-lg-1', 'col-md-3', 'col-12']">
                        <i class="bi bi-chevron-down" style="cursor:pointer;"
                            @click="togglePayers(localLiveSession)"></i>
                    </div>
                </template>
            </div>

            <!-- Payment Details -->
            <template v-if="visiblePayers[localLiveSession?.id]">
                <div v-for="payment in localLiveSession.payments" :key="payment.id" class="row align-items-center mt-2">
                    <div class="col-xl-9 col-lg-7 col-md-6 col-9">
                        <p class="card-text">* {{ payment?.participant.pseudo }}</p>
                    </div>
                    <div class="col-xl-3 col-lg-5 col-md-6 col-3">
                        <i class="bi bi-currency-dollar me-2"></i>
                        <span class="card-text">{{ payment?.total_amount }}</span>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" :id="'confirmCancelModal-' + localLiveSession.id" tabindex="-1"
        aria-labelledby="confirmCancelModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="confirmCancelModalLabel">{{ $t('liveSession.cancel.title') }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    {{ localLiveSession?.sport?.name }} - Le {{ convertToLocalTimeWhitShortDay(localLiveSession.start_time) }} à {{ convertToLocalTime(localLiveSession.start_time) }}
                    <br>
                    <br>
                    {{ $t('liveSession.cancel.notifyParticipants') }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-delete" data-bs-dismiss="modal" @click="cancelLiveSession(true)">{{ $t('liveSession.cancel.cancelWithNotification') }}</button>
                    <button type="button" class="btn btn-delete" data-bs-dismiss="modal" @click="cancelLiveSession(false)">{{ $t('liveSession.cancel.cancelWithoutNotification') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { popoverMixin } from '@/mixins/popoverMixin';
import { priceMixin } from '@/mixins/priceMixin';
import { timeMixin } from '@/mixins/timeMixin';

export default {
    name: 'CoachLiveSessionTile',
    mixins: [timeMixin, liveSessionMixin, popoverMixin, priceMixin],
    props: {
        liveSession: {
            type: Object,
            required: true
        },
        isPastLiveSession: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isEditing: false,
            visiblePayers: {},
            localLiveSession: { ...this.liveSession },
            showCancelModal: false
        };
    },
    watch: {
        liveSession: {
            handler(newValue) {
                this.localLiveSession = { ...newValue };
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        sports() {
            return this.$store.getters['sports/sports'];
        },
    },
    methods: {
        updateLiveSession() {
            try {
                this.$store.dispatch('coachLiveSessions/updateLiveSession', this.localLiveSession);
                this.isEditing = false;
                this.$showToast('success', this.$t('liveSession.updateSuccess'));
            } catch (error) {
                this.$showToast('error', this.$t('liveSession.updateError'));
            }
        },
        cancelEdit() {
            this.isEditing = false;
            this.localLiveSession = { ...this.liveSession };
        },
        async cancelLiveSession(sendNotification = true) {
            try {
                await this.$store.dispatch('coachLiveSessions/cancelLiveSession', { liveSessionId: this.localLiveSession.id, sendNotification });
                this.$showToast('success', this.$t('liveSession.cancelSuccess'));
            } catch (error) {
                console.log('error while canceling live session', error);
                this.$showToast('error', this.$t('liveSession.cancelError'));
            }
        },
        getClassTile() {
            return this.isCurrentlyOn(this.localLiveSession) ? 'course-live-coach' : '';
        },
        togglePayers(liveSession) {
            this.visiblePayers[liveSession.id] = !this.visiblePayers[liveSession.id];
        },
        sumOfPaymentAmounts(liveSession) {
            if (!liveSession.payments) {
                return 0;
            }
            return liveSession.payments.reduce((total, payment) => total + payment.total_amount, 0);
        }
    }
}
</script>

<style scoped>
.btn-zoom {
    border-color: #5b8ef4;
    color: #5b8ef4;
}

.btn-zoom:hover {
    background-color: #5b8ef4;
    border-color: #5b8ef4;
    color: #ffffff;
}

.btn-update {
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.btn-update:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: #ffffff;
}

.btn-delete {
    border-color: var(--alert-color);
    color: var(--alert-color);
}

.btn-delete:hover {
    background-color: var(--alert-color);
    border-color: var(--alert-color);
    color: #ffffff;
}

.btn-cancel {
    border-color: var(--secondary-color);
    color: var(--secondary-color);
}

.btn-cancel:hover {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #ffffff;
}

.btn-back {
    border-color: #ff5733;
    color: #ff5733;
}

.btn-back:hover {
    background-color: #ff5733;
    border-color: #ff5733;
    color: #ffffff;
}

.modal-title {
    color: var(--primary-color);
}
</style>
