<template>
    <div class="container flex-grow-1 ml-box mt-4 mb-4 bg-white">
        <div class="container-fluid mt-4 mb-4">
            <h2 class="ml-title mt-4">Les coachs</h2>
        </div>

        <div class="row g-4">
            <div class="col-12" v-for="user in coaches" :key="user.id">
                <div class="card">
                    <PeopleTile :user="user" :isForProf="true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminService from '@/api/AdminService';
import PeopleTile from '@/components/admin/PeopleTile.vue';

export default {
    name: "AdminManageCoaches",
    components: {
        PeopleTile
    },
    data() {
        return {
            coaches: [],
        };
    },
    mounted() {
        this.fetchProfs();
    },
    methods: {
        async fetchProfs() {
            try {
                this.coaches = await AdminService.getCoaches();
            } catch (error) {
                this.$showToast('error', 'Erreur lors de la récupération des coachs');
                console.error(error);
            }
        },
    }
}
</script>