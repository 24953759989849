// store/modules/payment.js
const getDefaultState = () => {
  return {
    isPaymentModalOpen: false,
    selectedLiveSession: null,
    selectedPackage: null,
    selectedReplay: null,
    selectedCoach: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    isPaymentModalOpen: state => state.isPaymentModalOpen,
    selectedLiveSession: state => state.selectedLiveSession,
    selectedPackage: state => state.selectedPackage,
    selectedReplay: state => state.selectedReplay,
    selectedCoach: state => state.selectedCoach,
  },
  mutations: {
    openPaymentModal(state, { selectedLiveSession = null, selectedPackage = null, selectedReplay = null, selectedCoach = null }) {
      state.isPaymentModalOpen = true;
      state.selectedLiveSession = selectedLiveSession;
      state.selectedPackage = selectedPackage;
      state.selectedReplay = selectedReplay;
      state.selectedCoach = selectedCoach;
    },
    RESET_STORE(state) {
      Object.assign(state, getDefaultState());
    },
    CLOSE_MODAL(state) {
      state.isPaymentModalOpen = false;
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET_STORE');
    },
    closePaymentModal({ commit }) {
      commit('CLOSE_MODAL');
    },
  }
};

