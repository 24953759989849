<!-- PackagesTile.vue -->
<template>
    <div class="d-flex align-items-center">
        <i class="bi bi-infinity mb-1 me-2"></i>
        <h3>{{ $t('package.packages') }}</h3>
    </div>

    <div v-if="isLoggedIn" class="row">
        <template v-if="hasPackages">
            <PackageTile v-for="pack in packages" :key="pack.id" :pack="pack" />
        </template>
        <div v-else class="col-12">
            <p>{{ $t('package.noPackage') }}</p>
        </div>
    </div>
    <div v-else class="row">
        <div class="col-12 mt-2">
            <p>{{ $t('package.shouldBeConnected') }}</p>
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import PackageTile from './PackageTile.vue';
export default {
    name: 'PackagesTile',
    components: {
        PackageTile
    },
    computed: {
        ...mapGetters({
            hasPackages: 'participantPackages/hasFilteredPackages',
            isLoggedIn: 'user/isLoggedIn',
            packages: 'participantPackages/filteredPackages',
        }),
    },
}
</script>

<style scoped>
/* Styles pour ReplaysTile */
</style>
