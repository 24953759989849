export const packageMixin = {
    methods: {
        isPackageInList(packageId) {
            return this.currentUser.participant_valid_packages.some(pack => pack.id === packageId);
        },
        openPackagePaymentModal(package_id, coach) {
            if (this.isPackageInList(package_id)) {
                this.$showToast('info', this.$t('package.alreadyPurchased'));
                return;
            }
            console.log("coach", coach);
            //const selectedPackage =
            //this.$store.commit('payment/openPaymentModal', { selectedPackage: pack, selectedCoach: coach });
        },
    }
}