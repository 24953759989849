<template>
  <div class="container ml-box flex-grow-1 mb-4 mt-4 bg-white">
    <div class="jumbotron text-center">
      <h1 class="display-4 spacing ml-title" v-t="'enroll.title'"></h1>
      <p class="lead spacing" v-t="'enroll.content'"></p>
      <p class="spacing" v-t="'enroll.content2'"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EnrollmentDonePage',
  components: {
  },
  computed: {
  },
};
</script>
<style scoped>
.highlight {
  color: var(--primary-color);
  font-weight: bold;
}

.spacing {
  margin-bottom: 20px;
}
</style>