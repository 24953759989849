/* store/modules/filters.js */
import moment from 'moment';

function generateNextSevenDays() {
    const daysOfWeek = [];
    const startDate = moment().startOf('week'); // Start from the beginning of the week

    for (let i = 0; i < 7; i++) {
        const day = startDate.clone().add(i, 'days');
        daysOfWeek.push({
            dayName: day.format('dddd'),
            date: day.format('YYYY-MM-DD')
        });
    }

    return daysOfWeek;
}

const getDefaultState = () => {
    return {
        selectedCoach: '',
        selectedSport: '',
        selectedDate: '',
        showAllReplays: false,
        showAllPackages: false,
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        selectedCoach: (state) => state.selectedCoach,
        selectedSport: (state) => state.selectedSport,
        selectedDate: (state) => state.selectedDate,
        nextSevenDays: () => generateNextSevenDays(),
        uniqueCoaches: (state, getters, rootState) => {
            const liveSessionsCoaches = rootState.participantLiveSessions.liveSessions.map(
                (liveSession) => liveSession.coach.pseudo
            );
            const replayCoaches = rootState.participantReplays.replays.map(
                (replay) => replay.coach.pseudo
            );
            const allCoaches = new Set([...liveSessionsCoaches, ...replayCoaches]);
            return Array.from(allCoaches);
        },
        uniqueSports: (state, getters, rootState) => {
            const liveSessionsSports = rootState.participantLiveSessions.liveSessions.map(
                (liveSession) => liveSession.sport.name
            );
            const replaySports = rootState.participantReplays.replays.map(
                (replay) => replay.sport.name
            );
            const allSports = new Set([...liveSessionsSports, ...replaySports]);
            return Array.from(allSports).sort();
        },
        showAllReplays: (state) => state.showAllReplays,
        showAllPackages: (state) => state.showAllPackages,
    },
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        SET_SELECTED_COACH(state, coach) {
            state.selectedCoach = coach;
        },
        SET_SELECTED_SPORT(state, sport) {
            state.selectedSport = sport;
        },
        SET_SELECTED_DATE(state, date) {
            state.selectedDate = date;
        },
        SET_SHOW_ALL_REPLAYS(state, showAllReplays) {
            state.showAllReplays = showAllReplays;
        },
        SET_SHOW_ALL_PACKAGES(state, showAllPackages) {
            state.showAllPackages = showAllPackages;
        },
    },
    actions: {
        resetFilters({ commit, dispatch }) {
            commit('SET_SELECTED_COACH', '');
            commit('SET_SELECTED_SPORT', '');
            commit('SET_SELECTED_DATE', '');
            dispatch('applyFilters');
        },
        setSelectedCoach({ commit, dispatch }, coach) {
            commit('SET_SELECTED_COACH', coach);
            dispatch('applyFilters');
        },
        setSelectedSport({ commit, dispatch }, sport) {
            commit('SET_SELECTED_SPORT', sport);
            dispatch('applyFilters');
        },
        setSelectedDate({ commit, dispatch }, date) {
            commit('SET_SELECTED_DATE', date);
            dispatch('applyFilters');
        },
        applyFilters({ state, rootState, commit }) {
            const { selectedCoach, selectedSport, selectedDate, showAllReplays, showAllPackages } = state;
            const liveSessions = rootState.participantLiveSessions.liveSessions;
            const replays = rootState.participantReplays.replays;
            const packages = rootState.participantPackages.packages;

            // Utility function to check matching criteria
            const isMatch = (item, type) => {
                const matchesDate =
                    selectedDate && type !== 'package'
                        ? type === 'replay'
                            ? moment(item.recorded_date).format('dddd') === selectedDate
                            : moment(item.start_time).format('dddd') === selectedDate
                        : true;
                const matchesCoach =
                    selectedCoach && item.coach
                        ? item.coach.pseudo === selectedCoach
                        : true;
                const matchesSport =
                    selectedSport && item.sport
                        ? item.sport.name === selectedSport
                        : true;

                if (type === 'session') {
                    return matchesDate && matchesCoach && matchesSport;
                }
                if (type === 'replay') {
                    return matchesDate && matchesCoach && matchesSport;
                }
                if (type === 'package') {
                    return matchesCoach;
                }
                return true;
            };

            // Conditionally filter only the necessary data
            if (!showAllReplays && !showAllPackages) {
                const filteredLiveSessions = liveSessions.filter((session) =>
                    isMatch(session, 'session')
                );
                commit(
                    'participantLiveSessions/SET_FILTERED_LIVE_SESSIONS',
                    JSON.parse(JSON.stringify(filteredLiveSessions)),
                    { root: true }
                );
            }

            if (showAllReplays) {
                const filteredReplays = replays.filter((replay) =>
                    isMatch(replay, 'replay')
                );
                commit(
                    'participantReplays/SET_FILTERED_REPLAYS',
                    JSON.parse(JSON.stringify(filteredReplays)),
                    { root: true }
                );
            }

            if (showAllPackages) {
                const filteredPackages = packages.filter((pack) =>
                    isMatch(pack, 'package')
                );
                commit(
                    'participantPackages/SET_FILTERED_PACKAGES',
                    JSON.parse(JSON.stringify(filteredPackages)),
                    { root: true }
                );
            }
        },
        toggleShowAllReplays({ commit, dispatch, state }) {
            commit('SET_SHOW_ALL_REPLAYS', !state.showAllReplays);
            /* on s'assure que les autres menus sont désélectionnés */
            commit('SET_SHOW_ALL_PACKAGES', false);
            dispatch('resetFilters');
        },
        toggleShowAllPackages({ commit, dispatch, state }) {
            commit('SET_SHOW_ALL_PACKAGES', !state.showAllPackages);
            /* on s'assure que les autres menus sont désélectionnés */
            commit('SET_SHOW_ALL_REPLAYS', false);
            dispatch('resetFilters');
        },
        setPackageId({ rootState, commit }) {
            const liveSessions = rootState.participantLiveSessions.liveSessions;
            const packages = rootState.participantPackages.packages;
            const packageUpdates = liveSessions.map((session) => {
                const packageId = packages.find(
                    (pack) =>
                        pack.coach_id === session.coach_id &&
                        moment(session.start_time).isBetween(
                            pack.start_date,
                            pack.end_date
                        )
                )?.id;
                return { liveSessionId: session.id, packageId: packageId };
            });
            commit(
                'participantLiveSessions/SET_PACKAGE_IDS',
                packageUpdates,
                { root: true }
            );
        },
        async resetState({ commit }) {
            commit('RESET_STATE');
        },
    },
};
