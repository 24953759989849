<template>
    <div class="container ml-box flex-grow-1 mb-4 bg-white">
        <div class="accordion mt-4" id="faqAccordion">
            <div class="accordion-item mb-3" v-for="(item, index) in faqItems" :key="index">
                <h2 class="accordion-header" :id="`header${index}`">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapse${index}`" aria-expanded="false" :aria-controls="`collapse${index}`">
                        <strong>{{ item.question }}</strong>
                    </button>
                </h2>
                <div :id="`collapse${index}`" class="accordion-collapse collapse" :aria-labelledby="`header${index}`"
                    data-bs-parent="#faqAccordion">
                    <div class="accordion-body">
                        {{ item.answer }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FAQ',
    computed: {
        faqItems() {
            return this.$tm('faq.items');
        }
    },
};
</script>
