<template>
  <div class="container-lg flex-grow-1 ml-box mb-4 bg-white">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-6 text-center">
        <img :src="logo404" class="img-fluid mb-1" alt="logo-404">
        <p v-t="'error.maintenance.message'"></p>
        <p v-t="'error.maintenance.commingSoon'"></p>
      </div>
      <DidYouKnow />
    </div>
  </div>
</template>

<script>
import logo404 from '@/assets/movinglive-404.png';
import DidYouKnow from '@/components/DidYouKnow.vue';

export default {
  name: 'MaintenanceView',
  data() {
    return {
      logo404: logo404
    }
  },
  components: {
    DidYouKnow,
  },
  methods: {
  },
};
</script>
