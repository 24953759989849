<template>
    <div class="container ml-box mb-4 bg-white">
        <div class="row g-0 mb-2">
            <h2 class="ml-title  mt-4" v-t="'replay.form.create'"></h2>
        </div>
        <form id="subscritpionForm" @submit.prevent="createReplay">
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-sm-12 mb-2">
                    <input type="hidden" id="datepickerReplay" name="date">
                </div>
                <div class="col-xl-4 col-lg-4 col-sm-12">
                    <div class="col-lg-12 mb-3">
                        <div class="input-group">
                            <span class="input-group-text">
                                <i class="bi bi-activity"></i>
                            </span>
                            <select class="form-select" v-model="selectedSport">
                                <option value="" disabled>{{ $t('replay.form.sport') }}</option>
                                <option v-for="sport in sports" :key="sport.id" :value="sport.id">
                                    {{ sport.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-12 mb-3">
                        <div class="input-group">
                            <span class="input-group-text">
                                <i class="bi bi-stopwatch-fill"></i>
                            </span>
                            <select class="form-select" v-model="selectedDuration">
                                <option value="" disabled>{{ $t('replay.form.duration') }}</option>
                                <option v-for="i in range(15, 75, 5)" :key="i" :value="i">
                                    {{ formatMinutes(i) }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-12 mb-3">
                        <div class="input-group">
                            <span class="input-group-text">
                                <i class="bi bi-currency-dollar"></i>
                            </span>
                            <select class="form-select" v-model="selectedPrice">
                                <option value="-2" disabled>{{ $t('replay.form.price') }}</option>
                                <option value="-1">{{ $t('liveSession.voluntaryContribution') }}</option>
                                <option value="0">{{ $t('liveSession.free') }}</option>
                                <option v-for="i in range(10, 30, 1)" :key="i" :value="i">
                                    {{ formatPrice(i) }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="input-group">
                            <span class="input-group-text">
                                <i class="bi bi-link-45deg"></i>
                            </span>
                            <input type="text" class="form-control" id="title" v-model="link">
                        </div>
                    </div>

                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-create mb-4" :disabled="isLoading">
                            <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"
                                aria-hidden="true"></span>
                            <span v-if="!isLoading" v-t="'replay.create'"></span>
                            <span v-else v-t="'replay.isLoading'"></span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { priceMixin } from '@/mixins/priceMixin';
import { timeMixin } from '@/mixins/timeMixin';
import { easepick } from '@easepick/bundle';

export default {
    name: 'AddReplayForm',
    mixins: [liveSessionMixin, priceMixin, timeMixin],
    props: {
        sports: Array,
    },
    data() {
        return {
            isLoading: false,
            selectedSport: '',
            selectedDuration: '',
            selectedPrice: '-1',
            link: '',
            picker: null,
        };
    },
    mounted() {
        this.$nextTick(() => {
            const datepickerElement = document.getElementById("datepickerReplay");
            if (datepickerElement) {
                this.picker = new easepick.create({
                    element: datepickerElement,
                    css: ["https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css"],
                    zIndex: 10,
                    lang: "fr-FR",
                    grid: 2,
                    calendars: 2,
                    inline: true,
                    header: "",
                    plugins: [],
                });
            } else {
                console.error("Element #datepickerReplay not found");
            }
        });
    },
    beforeUnmount() {
        if (this.picker) {
            this.picker.destroy();  // Détruit l'instance pour éviter les fuites de mémoire
        }
    },
    methods: {
        checkData() {
            var dateVal = document.getElementById("datepickerReplay").value;
            const conditions = [
                { condition: !dateVal, message: this.$t('error.form.date') },
                { condition: this.selectedSport === '', message: this.$t('error.form.sport') },
                { condition: this.selectedDuration === '', message: this.$t('error.form.duration') },
                { condition: this.selectedPrice === '-2', message: this.$t('error.form.price') },
                { condition: this.link === '', message: this.$t('error.form.link') },
            ];

            const failedCondition = conditions.find(({ condition }) => condition);

            if (failedCondition) {
                this.$showToast('error', failedCondition.message);
                return false;
            }

            return true;
        },
        clearForm() {
            this.link = '';
            this.selectedSport = '';
            this.selectedDuration = '';
            this.selectedPrice = '-1';
        },
        generatedDate() {
            var dateVal = document.getElementById("datepickerReplay").value;

            // Assurez-vous que dateVal est une chaîne de date valide au format 'YYYY-MM-DD'
            if (!dateVal || !/^\d{4}-\d{2}-\d{2}$/.test(dateVal)) {
                console.error("Invalid date format");
                return null;
            }

            // Récupère l'heure actuelle et formate les heures et minutes sur deux chiffres
            let date = new Date();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            // Construit la chaîne de date complète avec l'heure
            const selectedDateString = `${dateVal}T${hours}:${minutes}:00`;

            // Crée un objet Date à partir de la chaîne et vérifie sa validité
            const isoDate = new Date(selectedDateString);
            if (isNaN(isoDate)) {
                console.error("Invalid date value");
                return null;
            }

            // Retourne la date au format ISO
            return isoDate.toISOString();
        },
        async createReplay() {
            if (!this.checkData()) {
                return;
            }
            this.isLoading = true;

            const replay = {
                sport_id: this.selectedSport,
                duration: this.selectedDuration,
                price: this.selectedPrice,
                link: this.link,
                recorded_date: this.generatedDate(),
            };
            try {
                await this.$store.dispatch('coachReplays/createReplay', replay);
                this.clearForm();
                this.$showToast('success', this.$t('replay.createSuccess'));
            } catch (error) {
                this.$showToast('error', this.$t('replay.createError'));
                console.error(error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped></style>
