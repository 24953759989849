<!-- DesktopNavbar.vue -->
<template>
    <ul class="navbar-nav mb-lg-0 d-none d-lg-flex">
        <li class="nav-item" v-if="isLoggedIn">
            <router-link :to="getCourseLinkHref()" class="nav-link" @click="resetFilter">
                <div class="d-flex align-items-center">
                    <i class="bi bi-plus"></i>
                    <span v-if="currentUser.is_coach_validated">
                        {{ $t('menu.workspace') }}
                    </span>
                    <span v-else>
                        {{ $t('menu.becomeCoach') }}
                    </span>
                </div>
            </router-link>
        </li>
        <DesktopProfileDropdown v-if="isLoggedIn" />
        <template v-else>
            <li class="nav-item">
                <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#feedbackModal">
                    <i class="bi bi-envelope-fill d-none d-xl-inline"></i>
                    {{ $t('menu.contact') }}
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link" href="#" @click.prevent role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <i class="bi bi-translate d-none d-xl-inline"></i>
                    {{ $i18n.locale.toUpperCase() }}
                    <i class="bi bi-caret-down-fill ms-1"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="localeDropdown">
                    <li>
                        <a class="dropdown-item" href="#" @click="setLocale($i18n.locale === 'fr' ? 'en' : 'fr')">
                            <span v-if="$i18n.locale === 'fr'">EN</span>
                            <span v-else>FR</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    {{ $t('menu.login') }}
                </a>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profileDropdown">
                    <li>
                        <GoogleLogin :callback="handleGoogleResponse" />
                    </li>
                    <li>
                        <a class="dropdown-item facebook d-flex align-items-center" @click="loginWithFacebook" href="#">
                            <i class="bi bi-facebook me-2"></i>
                            {{ $t('menu.facebook') }}
                        </a>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
</template>

<script>
import { timeMixin } from '@/mixins/timeMixin';
import { mapGetters } from 'vuex';
import DesktopProfileDropdown from './DesktopProfileDropdown.vue';

export default {
    name: 'DesktopNavbar',
    mixins: [timeMixin],
    components: {
        DesktopProfileDropdown
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            isLoggedIn: 'user/isLoggedIn',
        }),

    },
    methods: {
        /* global FB */
        getCourseLinkHref() {
            if (!this.currentUser) {
                return "#";
            } else if (this.currentUser.is_coach_validated === true) {
                return "/coach/dashboard";
            } else if (this.currentUser.is_coach_validated === false) {
                return "/enrollment-done-page";
            } else if (this.currentUser.is_coach_validated === null) {
                return "/coach-registration-form";
            } else {
                return "#";
            }
        },
        setLocale(lang) {
            this.$i18n.locale = lang;
            localStorage.setItem('locale', lang);
        },
        resetFilter() {
            this.$store.dispatch('filters/resetState');
        },
        async handleGoogleResponse(response) {
            const payload = {
                id_token: response.credential,
                timezone: this.getTimezone(),
                provider: 'google'
            };
            await this.$store.dispatch('user/login', payload);
            this.trackLoginEvent('google');
        },
        loginWithFacebook() {
            FB.login((response) => {
                if (response.status === 'connected') {
                    this.handleFacebookLogin(response);
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            }, { scope: 'public_profile,email' });
        },
        handleFacebookLogin(response) {
            if (response?.status === 'connected') {
                const payload = {
                    id_token: response.authResponse.accessToken,
                    timezone: this.getTimezone(),
                    provider: 'facebook'
                };
                this.$store.dispatch('user/login', payload);
                this.trackLoginEvent('facebook');
            } else {
                console.log('Facebook login failed : ' + response?.status);
                this.$showToast('error', this.$t('error.facebookConnect'));
            }
        },
        async trackLoginEvent(provider) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'login',
                loginProvider: provider,
            });
        },
    }
};
</script>
