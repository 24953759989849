<!-- CoachLiveSessionForm.vue -->
<template>
    <div class="container ml-box mb-4 bg-white">
        <div class="row g-0 mb-2">
            <h2 class="ml-title mt-4">{{ $t('liveSession.create.new') }}</h2>
        </div>
        <form id="courseForm" @submit.prevent="createLiveSession">
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-sm-12 mb-2">
                    <input type="hidden" id="datepicker" name="date">
                </div>

                <div class="col-xl-4 col-lg-4 col-sm-12">
                    <div class="row">

                        <div class="col-lg-12 mb-3 ">
                            <div class="input-group">
                                <span class="input-group-text">
                                    <i class="bi bi-clock-fill"></i>
                                </span>
                                <select class="form-select" v-model="selectedHour">
                                    <option value="" disabled>{{ $t('liveSession.create.hour') }}</option>
                                    <option v-for="i in 24" :key="i" :value="formatNumber(i)">
                                        {{ formatNumber(i) }}
                                    </option>
                                </select>
                                <select class="form-select" v-model="selectedMinute">
                                    <option value="" disabled>{{ $t('liveSession.create.minute') }}</option>
                                    <option v-for="i in range(0, 60, 5)" :key="i" :value="formatNumber(i)">
                                        {{ formatNumber(i) }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-12 mb-3">
                            <div class="input-group">
                                <span class="input-group-text">
                                    <i class="bi bi-activity"></i>
                                </span>
                                <select class="form-select" v-model="selectedSport">
                                    <option value="" disabled>{{ $t('liveSession.create.sessions') }}</option>
                                    <option v-for="sport in sports" :key="sport.id" :value="sport.id">
                                        {{ sport.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-12 mb-3">
                            <div class="input-group">
                                <span class="input-group-text">
                                    <i class="bi bi-stopwatch-fill"></i>
                                </span>
                                <select class="form-select" v-model="selectedDuration">
                                    <option value="" disabled>{{ $t('liveSession.create.duration') }}</option>
                                    <option v-for="i in range(15, 75, 5)" :key="i" :value="i">
                                        {{ formatMinutes(i) }}
                                    </option>
                                    <!-- ... -->
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-12 mb-3">
                            <div class="input-group">
                                <span class="input-group-text">
                                    <i class="bi bi-currency-dollar"></i>
                                </span>
                                <select class="form-select" v-model="selectedPrice">
                                    <option value="-2" disabled>{{ $t('liveSession.price') }}</option>
                                    <option value="-1">{{ $t('liveSession.voluntaryContribution') }}</option>
                                    <option value="0">{{ $t('liveSession.free') }}</option>
                                    <option v-for="i in range(10, 30, 1)" :key="i" :value="i">
                                        {{ formatPrice(i) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-12 mb-3">
                            <div class="input-group">
                                <span class="input-group-text">
                                    <i class="bi bi-repeat"></i>
                                </span>
                                <select class="form-select" v-model="selectedRecurrence">
                                    <option value="1" selected>1 fois</option>
                                    <option v-for="i in range(2, 10, 1)" :key="i" :value="i">
                                        {{ formatSemaine(i) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button type="submit" class="btn btn-create mb-4" :disabled="isLoading">
                                <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"
                                    aria-hidden="true"></span>
                                <span v-if="!isLoading">{{ $t('liveSession.create.create') }}</span>
                                <span v-else>{{ $t('liveSession.create.onCreate') }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { priceMixin } from '@/mixins/priceMixin';
import { timeMixin } from '@/mixins/timeMixin';
import { easepick } from '@easepick/bundle';
import { LockPlugin } from '@easepick/lock-plugin';

export default {
    name: 'AddCourseForm',
    mixins: [liveSessionMixin, priceMixin, timeMixin],
    props: {
        sports: Array
    },
    data() {
        return {
            selectedSport: '',
            selectedHour: '09',
            selectedMinute: '30',
            selectedDuration: '',
            selectedPrice: '-1',
            selectedRecurrence: '1',
            picker: null,
            start_time: null,
            isLoading: false,
        };
    },
    methods: {
        checkData() {
            var dateVal = document.getElementById("datepicker").value;
            const conditions = [
                { condition: !dateVal, message: this.$t('error.form.date') },
                { condition: this.generatedDate() < new Date().toISOString(), message: this.$t('error.form.datePast') },
                { condition: this.selectedHour === '', message: this.$t('error.form.hour') },
                { condition: this.selectedMinute === '', message: this.$t('error.form.minute') },
                { condition: this.selectedSport === '', message: this.$t('error.form.sport') },
                { condition: this.selectedDuration === '', message: this.$t('error.form.duration') },
                { condition: this.selectedPrice === '-2', message: this.$t('error.form.price') },
                { condition: this.selectedRecurrence === '', message: this.$t('error.form.recurrence') },
            ];

            const failedCondition = conditions.find(({ condition }) => condition);

            if (failedCondition) {
                this.$showToast('error', failedCondition.message);
                return false;
            }

            return true;
        },
        generatedDate(weeksToAdd = 0) {
            var dateVal = document.getElementById("datepicker").value;
            if (!dateVal) {
                return null;
            }
            const selectedDateString = `${dateVal}T${this.selectedHour}:${this.selectedMinute}:00`;
            const selectedDateObject = new Date(selectedDateString);
            selectedDateObject.setDate(selectedDateObject.getDate() + (weeksToAdd * 7));
            return selectedDateObject.toISOString();
        },
        async createLiveSession() {
            if (!this.checkData()) {
                return;
            }
            this.isLoading = true;

            const recurrenceCount = parseInt(this.selectedRecurrence, 10);
            for (let i = 0; i < recurrenceCount; i++) {
                const liveSession = {
                    sport_id: this.selectedSport,
                    start_time: this.generatedDate(i),
                    duration: this.selectedDuration,
                    price: this.selectedPrice,
                };

                try {
                    await this.$store.dispatch('coachLiveSessions/createLiveSession', liveSession);
                    this.$showToast('success', this.$t('liveSession.createSuccess'));
                } catch (error) {
                    // plage horaire deja prise
                    this.$showToast('error', this.$t('liveSession.createError'));
                }
            }

            this.isLoading = false;
        },
    },
    mounted() {
        this.$nextTick(() => {
            const today = new Date();
            const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

            const datepickerElement = document.getElementById("datepicker");
            if (datepickerElement) {
                this.picker = new easepick.create({
                    element: datepickerElement,
                    css: ["https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css"],
                    zIndex: 10,
                    lang: "fr-FR",
                    grid: 2,
                    calendars: 2,
                    inline: true,
                    header: "",
                    LockPlugin: {
                        minDate: minDate.toISOString().split('T')[0],
                    },
                    plugins: [LockPlugin],
                });
            } else {
                console.error("Element #datepicker not found");
            }
        });
    },
    beforeUnmount() {
        if (this.picker) {
            this.picker.destroy();  // Détruit l'instance pour éviter les fuites de mémoire
        }
    }
};
</script>

<style scoped>
.btn-create:disabled {
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
}

#datepicker {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.calendar,
.form-select,
.form-control {
    font: 500 20px/34px var(--primary-font);
}

.calendar>.days-grid>.day.selected {
    background-color: var(--primary-color) !important;
    color: var(--white-color);
}
</style>