<!-- PaymentResult.vue -->
<template>
  <div class="container-lg flex-grow-1 ml-box mb-4 bg-white">
    <h2 :class="['ml-title mt-4', isPaymentSuccess ? '' : 'text-danger']"
      v-t="isPaymentSuccess ? 'paymentResult.title.success' : 'paymentResult.title.error'"></h2>

    <div class="container-fluid">
      <template v-if="!isPaymentSuccess">
        <div class="d-flex">
          {{ errorReason ? $t('paymentResult.errorOccurred', { errorReason: errorReason }) :
      $t('paymentResult.errorOccurredGlobal') }}
        </div>
      </template>

      <template v-if="liveSession">
        <div class="d-flex mb-3" v-t="'paymentResult.pleasantSession'"></div>
        <article class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-3 border-bottom border-dark">
              <i class="bi bi-bag-check me-2"></i>
              <h4 class="card-text mb-0" v-t="'payment.session.title'"></h4>
            </div>
            <div class="d-flex align-items-center mb-1">
              <i class="bi bi-calendar-check me-2"></i>
              <div>{{ formatDayMonthHourMinute(liveSession?.start_time) }}</div>
            </div>
            <div class="d-flex align-items-center mb-1">
              <i class="bi bi-activity me-2"></i>
              <div>{{ $t('payment.session.resume', {
      sportName: liveSession?.sport.name, coach:
        liveSession?.coach.pseudo
    }) }}</div>
            </div>
            <div class="d-flex align-items-center mb-1">
              <i class="bi bi-sunglasses me-2"></i>
              <p class="card-text mb-0" v-t="'payment.noCommitment'"></p>
            </div>
            <div class="d-flex align-items-center mb-3">
              <i class="bi bi-currency-dollar me-2"></i>
              <p class="card-text mb-0">{{ formatPrice(amount) }}</p>
            </div>
          </div>
        </article>
      </template>

      <template v-if="pack">
        <div>{{ $t('paymentResult.pleasantpackage', { name: pack?.coach.pseudo }) }}</div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="d-flex align-items-center mb-3 border-bottom border-dark">
              <i class="bi bi-infinity me-2"></i>
              <h4 class="card-text mb-0" v-t="'payment.package'"></h4>
            </div>
            <div class="d-flex align-items-center mb-1">
              <i class="bi bi-calendar-range me-2"></i>
              <div>{{ formatDayMonth(getpackageBeginDate(pack)) }}</div>
              <i class="bi bi-arrow-right mx-2"></i>
              <div>{{ formatDayMonth(pack?.end_date) }}</div>
            </div>
            <div class="d-flex align-items-center mb-1">
              <i class="bi bi-activity me-2"></i>
              <div>{{ $t('payment.package.resume', {
      nbDays: getpackageDays(pack), coach:
        pack?.coach.pseudo
    }) }}</div>
            </div>
            <div class="d-flex align-items-center mb-1">
              <i class="bi bi-sunglasses me-2"></i>
              <p class="card-text mb-0" v-t="'payment.noCommitment'"></p>
            </div>
            <div class="d-flex align-items-center mb-3">
              <i class="bi bi-currency-dollar me-2"></i>
              <p class="card-text mb-0">{{ formatPrice(amount) }}</p>
            </div>
          </div>
        </div>
      </template>

      <template v-if="replay">
        <div class="d-flex mb-3" v-t="'paymentResult.pleasantSession'"></div>
        <article class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-3 border-bottom border-dark">
              <i class="bi bi-bag-check me-2"></i>
              <h4 class="card-text mb-0" v-t="'payment.replay.title'"></h4>
            </div>
            <div class="d-flex align-items-center mb-1">
              <i class="bi bi-activity me-2"></i>
              <div>
                {{ $t('payment.replay.resume', {
      sportName: replay?.sport.name,
      coach: replay?.coach.pseudo
    })
                }}</div>
            </div>
            <div class="d-flex align-items-center mb-1">
              <i class="bi bi-sunglasses me-2"></i>
              <p class="card-text mb-0" v-t="'payment.noCommitment'"></p>
            </div>
            <div class="d-flex align-items-center mb-1">
              <i class="bi bi-repeat me-2"></i>
              <p class="card-text mb-0" v-t="'payment.replay.availability'"></p>
            </div>
            <div class="d-flex align-items-center mb-3">
              <i class="bi bi-currency-dollar me-2"></i>
              <p class="card-text mb-0">{{ formatPrice(amount) }}</p>
            </div>
          </div>
        </article>
      </template>

      <div class="container-fluid mt-4">
        <div class="row d-flex flex-column flex-md-row justify-content-md-end">
          <div class="col-md-4 col-lg-3 mb-2 mb-md-0">
            <router-link to="/consult-payment" class="btn btn-booking-live w-100"
              v-t="'paymentResult.findYourHistory'"></router-link>
          </div>
          <div class="col-md-3 col-lg-2">
            <router-link :to="{ name: 'home' }" class="btn btn-booking-live w-100"
              v-t="'general.homePageLink'"></router-link>
          </div>
          <div v-if="replay" class="col-md-4 col-lg-3 mb-2 mb-md-0">
            <button class="btn btn-accept w-100 " @click="openZoomLink(replay.link)">
              <i class="bi bi-camera-video mx-2"></i> {{
              $t('replay.replay') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import PaymentService from '@/api/PaymentService';
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { priceMixin } from '@/mixins/priceMixin';
import { timeMixin } from '@/mixins/timeMixin';
import { zoomMixin } from '@/mixins/zoomMixin';

export default {
  name: 'PaymentResult',
  mixins: [liveSessionMixin, timeMixin, priceMixin, zoomMixin],
  computed: {
    currentUser() {
      return this.$store.getters['user/currentUser'];
    }
  },
  data() {
    return {
      isPaymentSuccess: true,
      pack: null,
      liveSession: null,
      replay: null,
      amount: 0,
      errorReason: '',
    }
  },
  created() {
    // LIKE onCreated
    this.processPaymentResult();
  },
  mounted() {
    // LIKE onload
    // Ici, vous pouvez par exemple faire un appel à une API pour récupérer des données
    // Toute logique qui était dans 'onLoad.html' ou 'home.js'
    this.resetPaymentStore();
  },
  methods: {
    resetPaymentStore() {
      this.$store.dispatch('payment/reset');
    },
    async processPaymentResult() {
      try {
        // Ici, vous pouvez déclarer des fonctions qui seront utilisées dans votre template
        const urlParams = new URLSearchParams(window.location.search);
        const payment_intent = urlParams.get('payment_intent');
        if (!payment_intent) {
          this.errorReason = this.$t('payment.error.url');
          return;
        }
        const response = await PaymentService.getStripePaymentIntent(payment_intent);
        this.isPaymentSuccess = true;
        const payment = response.data.payment;
        const link = response.data.link;
        this.openZoomLink(link);
        this.liveSession = payment.live_session;
        this.pack = payment.package;
        this.replay = payment.replay;

        if (this.pack) {
          //On met à jour le user pour changer son logo et lui donner le forfait
          this.$store.dispatch('user/addPackage', this.pack);
        }
        this.amount = payment.total_amount;
      } catch (error) {
        console.error(error);
        this.isPaymentSuccess = false;

        if (error.response) {
          switch (error.response.status) {
            case 400:
              this.errorReason = this.$t('payment.error.refused');
              break;
            case 404:
              this.errorReason = this.$t('payment.error.notFound');
              break;
            case 502:
              this.errorReason = this.$t('payment.error.stripeConnection');
              break;
            case 500:
            default:
              this.errorReason = this.$t('payment.error.stripe');
              break;
          }
        } else {
          this.errorReason = this.$t('payment.error.stripe');
        }
      }
    },
    getpackageBeginDate(pack) {
      const today = new Date();
      if (!pack.start_date || !pack.end_date) {
        return today;
      }
      return new Date(pack.start_date) < today ? today : pack.start_date;
    },
    getpackageDays(pack) {
      // Vérifier si les dates de début et de fin d'abonnement sont définies
      if (!pack.start_date || !pack.end_date) {
        return 0;
      }

      const startDate = new Date(this.getpackageBeginDate(pack));
      const endDate = new Date(pack.end_date);

      let differenceInTime = endDate.getTime() - startDate.getTime();
      return Math.round(differenceInTime / (1000 * 3600 * 24));
    },
  }
}
</script>
<style></style>