<!-- OffersTile.vue -->
<template>
    <div class="container-fluid mt-2 mb-4">
        <ReplaysTile v-if="showAllReplays" />
        <PackagesTile v-if="showAllPackages" />
        <template v-if="!showAllReplays && !showAllPackages">
            <div v-if="currentLiveSessions.length">
                <div class="d-flex align-items-center">
                    <i class="bi bi-caret-right-square mb-1 me-2"></i>
                    <h3 v-if="currentLiveSessions.length === 1">{{ $t('liveSession.currentSession') }}</h3>
                    <h3 v-else>{{ $t('liveSession.currentSessions') }}</h3>
                </div>
                <div class="row g-4">
                    <LiveSessionTile v-for="liveSession in currentLiveSessions" :key="liveSession.id"
                        :liveSession="liveSession" :isForSingleCoach="isForSingleCoach"
                        :isForSingleSport="isForSingleSport" :isForListBooking="isForListBooking" />
                </div>
            </div>
            <div v-if="futureLiveSessions.length">
                <div class="d-flex align-items-center mt-4">
                    <i class="bi bi-calendar-plus mb-1 me-2"></i>
                    <h3 v-if="futureLiveSessions.length === 1" class="font-italic">{{ $t('liveSession.nextSession') }}
                    </h3>
                    <h3 v-else class="font-italic">{{ $t('liveSession.nextSessions') }}</h3>
                </div>
                <div class="row g-4">

                    <div class="accordion px-0 " id="liveSessionsAccordion">
                        <div class="accordion-item" v-for="(sessions, index) in groupedSessions" :key="index">
                            <h2 class="accordion-header">
                                <button class="accordion-button d-flex align-items-center" type="button"
                                    data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="true"
                                    :aria-controls="'collapse' + index">
                                    <i class="bi bi-calendar me-2"></i>
                                    <!-- Ajout de l'icône à l'intérieur du bouton -->
                                    {{ sessions.day }}
                                </button>
                            </h2>
                            <div :id="'collapse' + index" class="accordion-collapse collapse show">
                                <div class="accordion-body">
                                    <div class="row g-4">
                                        <LiveSessionTile v-for="liveSession in sessions.sessions" :key="liveSession.id"
                                            :liveSession="liveSession" :isForSingleCoach="isForSingleCoach"
                                            :isForSingleSport="isForSingleSport" :isForListBooking="isForListBooking" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LiveSessionTile from '@/components/liveSessions/LiveSessionTile.vue';
import PackagesTile from '@/components/packages/PackagesTile.vue';
import ReplaysTile from '@/components/replays/ReplaysTile.vue';
import { accordionMixin } from '@/mixins/accordionMixin';
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { timeMixin } from '@/mixins/timeMixin';
import { mapGetters } from 'vuex';

export default {
    name: 'OffersTile',
    mixins: [timeMixin, liveSessionMixin, accordionMixin],
    components: {
        LiveSessionTile,
        ReplaysTile,
        PackagesTile,
    },
    props: {
        isForSingleCoach: Boolean,
        isForListBooking: Boolean,
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            liveSessions: 'participantLiveSessions/filteredLiveSessions',
            packages: 'participantPackages/packages',
            replays: 'participantReplays/filteredReplays',
            showAllReplays: 'filters/showAllReplays',
            showAllPackages: 'filters/showAllPackages',
        }),
        currentLiveSessions() {
            return this.liveSessions.filter((liveSession) => this.isCurrentlyOn(liveSession));
        },
        futureLiveSessions() {
            return this.liveSessions.filter((liveSession) => !this.isCurrentlyOn(liveSession));
        },
        groupedSessions() {
            let grouped = {};

            this.futureLiveSessions.forEach(session => {
                let day = this.formatDateFull(session.start_time);
                if (!grouped[day]) {
                    grouped[day] = [];
                }
                grouped[day].push(session);
            });

            let today = this.formatDateFull(new Date());
            let sortedGrouped = Object.keys(grouped).sort((a, b) => new Date(a) - new Date(b));
            let sortedGroupedWithTodayFirst = sortedGrouped.filter(day => day !== today);
            sortedGroupedWithTodayFirst.unshift(today);

            // Filtre les jours sans sessions avant de les retourner
            return sortedGroupedWithTodayFirst
                .filter(day => grouped[day] && grouped[day].length > 0)
                .map(day => ({ day, sessions: grouped[day] }));
        }
    },
    methods: {
    },
    mounted() {
        this.$store.dispatch('payment/reset');
    },
    watch: {
        liveSessions() {
            this.$nextTick(() => {
                this.initializeAccordions();
            });
        }
    }
};
</script>


<style scoped>
.accordion-button,
.accordion-button:hover,
.accordion-button:focus,
.accordion-button:not(.collapsed),
.accordion-body {
    background-color: var(--white-color);
    color: var(--primary-color);
}

.accordion-button:focus {
    border: 0px;
}

.accordion-button {
    font-size: 1.4rem;
    /* font-weight: bold; */
}
</style>