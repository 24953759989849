/* store/index.js */
import api from '@/services/api.js';
import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import coachLiveSessions from './modules/coachLiveSessions';
import coachPackages from './modules/coachPackages';
import coachReplays from './modules/coachReplays';
import filters from './modules/filters';
import participantLiveSessions from './modules/participantLiveSessions';
import participantPackages from './modules/participantPackages';
import participantReplays from './modules/participantReplays';
import payment from './modules/payment';
import sports from './modules/sports';
import user from './modules/user';

export default createStore({
  modules: {
    coachLiveSessions,
    coachPackages,
    coachReplays,
    participantLiveSessions,
    participantPackages,
    participantReplays,
    payment,
    sports,
    user,
    filters,
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
  })],
  state: {
    toast: {
      show: false,
      type: null,
      message: ''
    },
    isMaintenanceMode: false,
  },
  getters: {
    isMaintenanceMode: state => state.isMaintenanceMode,
  },
  mutations: {
    SET_MAINTENANCE_MODE(state, value) {
      state.isMaintenanceMode = value;
    },
    SET_TOAST(state, payload) {
      state.toast.show = true;
      state.toast.type = payload.type;
      state.toast.message = payload.message;
    },
    HIDE_TOAST(state) {
      state.toast.show = false;
      state.toast.type = null;
      state.toast.message = '';
    },
  },
  actions: {
    async checkBackendStatus({ commit }) {
      try {
        await api.get('/v1/health');
        commit('SET_MAINTENANCE_MODE', false);
      } catch (error) {
        commit('SET_MAINTENANCE_MODE', true);
      }
    },
    showToast({ commit }, payload) {
      commit('SET_TOAST', payload);
      setTimeout(() => {
        commit('HIDE_TOAST');
      }, 5000);
    },
    resetStore({ dispatch }) {
      dispatch('coachLiveSessions/resetState', null, { root: true });
      dispatch('coachPackages/resetState', null, { root: true });
      dispatch('coachReplays/resetState', null, { root: true });
      dispatch('filters/resetState', null, { root: true });
      dispatch('payment/reset', null, { root: true });
      dispatch('user/resetState', null, { root: true });
    }
  },
});
