<!-- PackageTile.vue -->
<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- Coach -->
                <div class="col-lg-2 col-md-3 col-6">
                    <template v-if="pack.coach.url_image">
                        <img class="rounded" :src="pack.coach.url_image" alt="avatar" style="width: 20px; height: auto">
                    </template>
                    <template v-else>
                        <i class="bi bi-person"></i>
                    </template>
                    {{ pack.coach.pseudo }}
                </div>
                <!-- periode -->
                <div class="col-lg-4 col-md-4 col-sm-7 col-12">
                    <i class="bi bi-calendar me-2"></i>
                    <span class="h6">{{ convertToLocalTimeWhitShortDay(pack.start_date) }}</span>
                    <i class="bi bi-arrow-right mx-2"></i>
                    <span class="h6">{{ convertToLocalTimeWhitShortDay(pack.end_date) }}</span>
                </div>
                <!-- Tarif -->
                <div class="col-lg-2 col-md-2 col-sm-5 col-12">
                    <i class="bi bi-currency-dollar me-2"></i>
                    <span class="h6" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom"
                        v-bind:data-bs-content="pack.price === -1 ? $t('liveSession.voluntaryContributionPopover') : ''">
                        {{ formatPrice(pack.price) }}
                    </span>
                </div>

                <!-- Bouton -->
                <div class="col-lg-4 col-md-3 col-sm-12 col-12">
                    <span data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom"
                        v-bind:data-bs-content="!isLoggedIn ? $t('general.shouldConnect') : ''">
                        <button class="btn w-100 btn-moving-live btn-sm"
                            @click.prevent="this.openPackagePaymentModal(pack, pack.coach)" :disabled="!isLoggedIn">
                            <span class="h6">
                                <i class="bi bi-infinity me-3 "></i> {{ $t('package.unlimited') }}
                            </span>
                        </button>
                    </span>
                </div>

            </div>
        </div>
    </div>
</template>


<script>
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { popoverMixin } from '@/mixins/popoverMixin';
import { priceMixin } from '@/mixins/priceMixin';
import { timeMixin } from '@/mixins/timeMixin';
import { mapGetters } from 'vuex';
export default {
    name: 'PackageTile',
    mixins: [timeMixin, liveSessionMixin, popoverMixin, priceMixin],
    data() {
        return {};
    },
    props: {
        pack: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            filteredPackages: 'participantPackages/filteredPackages',
            currentUser: 'user/currentUser',
            isLoggedIn: 'user/isLoggedIn',
            availablePackages: 'participantPackages/packages',
        }),
    },
    methods: {
        openPackagePaymentModal(pack) {
            const packageSelected = this.getAvailablePackageById(pack.id);
            if (packageSelected === undefined) {
                this.$showToast('error', this.$t('liveSession.packageNotAvailable'));
                return;
            }
            // check if the package is still in current user's packages
            const packageInUserPackages = this.currentUser.participant_valid_packages.find(p => p.id === packageSelected.id);
            if (packageInUserPackages) {
                this.$showToast('info', this.$t('package.alreadyPurchased'));
                return;
            }
            this.$store.commit(
                'payment/openPaymentModal',
                {
                    selectedLiveSession: null,
                    selectedPackage: pack,
                    selectedReplay: null,
                    selectedCoach: pack.coach
                });

        },
        getAvailablePackageById(id) {
            return this.availablePackages.find(p => p.id === id);
        },
    },
};
</script>

<style scoped>
.btn-replay {
    color: var(--primary-color);
    border-color: var(--primary-color);
}


.btn-replay:hover,
.btn-replay:active {
    color: #ffffff;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}
</style>
