import moment from 'moment';

const browserLocale = navigator.language || navigator.userLanguage;
moment.locale(browserLocale);

export const timeMixin = {
    methods: {
        getTimezone() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        },
        convertToLocalTime(utcTime) {
            return moment(utcTime).format('HH:mm');
        },
        formatDateFull(utcTime) {
            return moment(utcTime).format('dddd D MMMM');
        },
        formatDayMonth(utcTime) {
            return moment(utcTime).format('D MMMM');
        },
        formatDayMonthHourMinute(utcTime) {
            return moment(utcTime).format('D MMMM HH:mm');
        },
        formatDateShorty(utcTime) {
            return moment(utcTime).format('DD/MM');
        },
        formatDateShort(utcTime) {
            return moment(utcTime).format('DD/MM/YY');
        },
        convertToLocalTimeWhitShortDay(utcTime) {
            return moment(utcTime).format('ddd Do MMM');
        },
        formatNumber(num) {
            return String(num).padStart(2, '0');
        },
        formatSemaine(nbSemaine) {
            return nbSemaine + ' ' + this.$t('liveSession.weeks');
        },
        formatMinutes(minutes) {
            return minutes === 0 ? '00' : minutes + ' min';
        },
    }
};
