<template>
    <div class="container-lg flex-grow-1 ml-box mb-0 mb-md-4 bg-white">
        <h2 class="ml-title mt-4 mb-2">{{ pageTitle }}
            <img class="rounded" :src="currentUser?.url_image" alt="Current profile picture"
                style="width: 55px; height: auto">
        </h2>
        <form v-if="currentUser" id="profileForm" @submit.prevent="updateProfile">
            <div class="row">
                <!-- Partie Participant -->
                <div class="col-xl-6 col-lg-6 col-sm-12 mb-5">
                    <div class="row mb-3">
                        <label for="surname" class="col-sm-3 col-form-label" v-t="'profile.surname'"><span
                                class="text-danger">*</span>:</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="surname" v-model="currentUser.surname"
                                autocomplete="family-name">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="name" class="col-sm-3 col-form-label" v-t="'profile.name'"><span
                                class="text-danger">*</span>:</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="name" v-model="currentUser.name"
                                autocomplete="given-name">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="pseudo" class="col-sm-3 col-form-label" v-t="'profile.pseudo'"><span
                                class="text-danger">*</span>:</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="pseudo" v-model="currentUser.pseudo"
                                autocomplete="nickname">
                            <small id="pseudoHelp" class="form-text text-muted" v-t="'profile.visibleData'"></small>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="email" class="col-sm-3 col-form-label" v-t="'profile.email'"><span
                                class="text-danger">*</span>:</label>
                        <div class="col-sm-9">
                            <input type="email" class="form-control" id="email" v-model="currentUser.email"
                                autocomplete="email">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="phone_number" class="col-sm-3 col-form-label" v-t="'profile.cellphone'"><span
                                class="text-danger">*</span>:</label>
                        <div class="col-sm-9">
                            <vue-tel-input v-model="phoneNumber" :required="true" :enabled-country-code="true"
                                :default-country="'CA'" :inputOptions="{ placeholder: '' }">
                                <template v-slot:default="{ props, inputEvents }">
                                    <input v-bind="props" v-on="inputEvents" :required="true" autocomplete="tel" />
                                </template>
                            </vue-tel-input>
                        </div>
                    </div>
                </div>

                <!-- Partie Coach -->
                <div class="col-xl-6 col-lg-6 col-sm-12">
                    <!-- Champ pour l'adresse de rue -->
                    <div class="row mb-3">
                        <label for="street_address" class="col-sm-3 col-form-label" v-t="'profile.address'"><span
                                class="text-danger">*</span>:</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="street_address"
                                v-model="currentUser.street_address" autocomplete="street-address">
                        </div>
                    </div>

                    <!-- Champ pour la ville -->
                    <div class="row mb-3">
                        <label for="city" class="col-sm-3 col-form-label" v-t="'profile.city'"><span
                                class="text-danger">*</span>:</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="city" v-model="currentUser.city"
                                autocomplete="address-level2">
                        </div>
                    </div>

                    <!-- Champ pour la province -->
                    <div class="row mb-3">
                        <label for="province" class="col-sm-3 col-form-label" v-t="'profile.province'"><span
                                class="text-danger">*</span>:</label>
                        <div class="col-sm-9">
                            <select class="form-select" id="province" v-model="currentUser.province"
                                autocomplete="address-level1">
                                <option v-for="province in provinces" :value="province.code" :key="province.code">
                                    {{ province.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- Champ pour le code postal -->
                    <div class="row mb-3">
                        <label for="postal_code" class="col-sm-3 col-form-label" v-t="'profile.postalCode'"><span
                                class="text-danger">*</span>:</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="postal_code" v-model="currentUser.postal_code"
                                placeholder="A1A 1A1" autocomplete="postal-code">
                        </div>
                    </div>

                    <!-- Champ pour le pays -->
                    <div class="row mb-3">
                        <label for="country" class="col-sm-3 col-form-label" v-t="'profile.country'"><span
                                class="text-danger">*</span>:</label>
                        <div class="col-sm-9">
                            <select class="form-select" id="country" v-model="currentUser.country"
                                autocomplete="country">
                                <option v-for="country in countries" :value="country.code" :key="country.code">
                                    {{ country.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <template v-if="showProfInfoForm">
                        <div class="row mb-3">
                            <label for="profile_facebook" class="col-sm-3 col-form-label"
                                v-t="'profile.facebook'"></label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="profile_facebook"
                                    v-model="currentUser.profile_facebook" autocomplete="url">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="profile_tiktok" class="col-sm-3 col-form-label" v-t="'profile.tiktok'"></label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="profile_tiktok"
                                    v-model="currentUser.profile_tiktok" autocomplete="url">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="profile_instagram" class="col-sm-3 col-form-label"
                                v-t="'profile.instagram'"></label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="profile_instagram"
                                    v-model="currentUser.profile_instagram" autocomplete="url">
                            </div>
                        </div>
                        <template v-if="currentUser.is_coach_validated">
                            <div class="row mb-3">
                                <label for="notifications" class="col-sm-3 col-form-label"
                                    v-t="'profile.notifications'"></label>
                                <div class="col-sm-9 mt-1">
                                    <input type="checkbox" class="form-check-input" id="notifications"
                                        v-model="currentUser.notifications">
                                    <label class="form-check-label mx-2" for="notifications"
                                        v-t="'profile.notificationsInfo'"></label>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <!-- Zone à cocher pour la certification -->
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="certificationCheck"
                                    v-model="hasCertification">
                                <label class="form-check-label" for="certificationCheck"
                                    v-t="'profile.certifications'"></label>
                            </div>
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="insuranceCheck"
                                    v-model="hasInsurance">
                                <label class="form-check-label" for="insuranceCheck" v-t="'profile.insurances'"></label>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-create mb-4">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"
                            aria-hidden="true"></span>
                        <span>{{ buttonText }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import { mapGetters } from 'vuex';
export default {
    name: "ProfileView",
    props: {
        enrollementProcess: {
            type: Boolean,
            default: false
        }
    },
    components: {
        VueTelInput,
    },
    data() {
        return {
            isLoading: false,
            postalCodePattern: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
            provinces: [
                { code: 'AB', name: 'Alberta' },
                { code: 'BC', name: 'Colombie-Britannique' },
                { code: 'MB', name: 'Manitoba' },
                { code: 'NB', name: 'Nouveau-Brunswick' },
                { code: 'NL', name: 'Terre-Neuve-et-Labrador' },
                { code: 'NS', name: 'Nouvelle-Écosse' },
                { code: 'NT', name: 'Territoires du Nord-Ouest' },
                { code: 'NU', name: 'Nunavut' },
                { code: 'ON', name: 'Ontario' },
                { code: 'PE', name: 'Île-du-Prince-Édouard' },
                { code: 'QC', name: 'Québec' },
                { code: 'SK', name: 'Saskatchewan' },
                { code: 'YT', name: 'Yukon' }
            ],
            countries: [{ code: "CA", name: "Canada" }],
            hasCertification: false,
            hasInsurance: false,
        };
    },
    mounted() {
        this.fetchUserProfile();
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
        }),
        phoneNumber: {
            get() {
                return this.currentUser.phone_number || '';
            },
            set(value) {
                this.currentUser.phone_number = value;
            }
        },
        showProfInfoForm() {
            return this.currentUser.is_coach_validated || this.enrollementProcess;
        },
        pageTitle() {
            return this.enrollementProcess
                ? this.$t('profile.titleEnrollment')
                : this.$t('profile.title');
        },
        buttonText() {
            return this.isLoading ? this.$t('general.inProcess') : (this.enrollementProcess ? this.$t('profile.apply') : this.$t('profile.save'));
        }
    },
    methods: {
        async fetchUserProfile() {
            await this.$store.dispatch('user/fetchUserProfile');
        },
        async updateProfile() {
            if (!this.validateForm()) return;
            this.isLoading = true;

            const payload = { userProfile: this.currentUser, enrollementProcess: this.enrollementProcess };
            try {
                await this.$store.dispatch('user/updateUserProfile', payload);
                if (this.enrollementProcess) {
                    this.$router.push('/enrollment-done-page');
                } else {
                    this.$showToast('success', this.$t('profile.updateSuccess'));
                }
            } catch (error) {
                console.error(error);
                this.$showToast('error', this.$t('profile.updateError'));
            } finally {
                this.isLoading = false;
            }
        },
        validateForm() {
            return this.checkGenericData() && (!this.showProfInfoForm || this.checkProfData());
        },
        checkGenericData() {
            const requiredFields = ['name', 'surname', 'email', 'pseudo', 'phone_number', 'street_address', 'city', 'province', 'postal_code', 'country'];
            for (const field of requiredFields) {
                if (!this.currentUser[field]) {
                    this.$showToast('error', this.$t(`error.form.${field}`));
                    return false;
                }
            }
            return this.validatePostalCode();
        },
        validatePostalCode() {
            return this.currentUser.country !== 'CA' || this.postalCodePattern.test(this.currentUser.postal_code);
        },
        checkProfData() {
            if (!this.hasCertification && !this.currentUser.is_coach_validated) {
                this.$showToast('error', this.$t('error.form.certifications'));
                return false;
            }
            if (!this.hasInsurance && !this.currentUser.is_coach_validated) {
                this.$showToast('error', this.$t('error.form.insurances'));
                return false;
            }
            return true;
        },
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>
