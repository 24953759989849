// src/api/PackageService.js
import api from '@/services/api.js';

export default {
    async getPackages() {
        const response = await api.get('/v1/packages');
        return response.data.packages;
    },
    async createPackage(packageData) {
        const response = await api.post('/v1/packages', packageData);
        return response.data.package;
    },
    async deletePackage(packageid) {
        await api.delete(`/v1/packages/${packageid}`);
    },
    async nbLiveSessionsInPackage(packageid) {
        const response = await api.get(`/v1/packages/${packageid}/live-sessions/count`);
        return response.data.nbLiveSessions;
    }
};
