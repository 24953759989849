// src/api/UserService.js
import api from '@/services/api.js';

export default {
    async getUser() {
        const response = await api.get('v1/users/me/');
        return response.data.user;
    },
    async getUserForTerms() {
        const response = await api.get('v1/users/me/terms/');
        return response.data.user;
    },
    async getUserProfile() {
        const response = await api.get('v1/users/me/profile/');
        return response.data.user;
    },
    async updateUserProfile(data) {
        const response = await api.put('v1/users/me/profile/', data);
        return response.data.user;
    },
    async login(data) {
        const response = await api.post('v1/users/login/', data);
        return response;
    },
    async refreshToken() {
        const response = await api.post('v1/users/refresh-token/');
        return response.data.access_token;
    },
    async checkToken() {
        return await api.get('v1/users/check-token/');
    },
    async logout() {
        await api.post('v1/users/logout/');
    },
    async updateTerms(data) {
        const response = await api.put('v1/users/me/terms/', data);
        return response.data.user;
    },
    async updateLocale(data) {
        await api.put('v1/users/me/locale/', data);
    },
    async sendFeedback(data) {
        await api.post('v1/users/contact/', data);
    },
};





