<template>
  <div class="container flex-grow-1 ml-box mt-4 mb-4 bg-white">
    <div class="row g-0 mb-4">
      <h2 class="ml-title mb-4 mt-4">Modification des thèmes et des sports</h2>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-8 col-lg-6">
        <!-- Select à deux dimensions pour thèmes et sports associés -->
        <div class="form-group">
          <label for="themeSelect">Sélectionnez un thème ou un sport</label>
          <select id="themeSelect" class="form-select" v-model="selectedItem" @change="handleSelectionChange">
            <optgroup v-for="theme in themes" :key="theme.id" :label="theme.name">
              <!-- Option pour le thème lui-même -->
              <option :value="'theme-' + theme.id">{{ theme.name }} (Thème)</option>
              <!-- Options pour les sports associés au thème -->
              <option v-for="sport in theme.sports" :key="sport.id" :value="sport.id">
                {{ sport.name }}
              </option>
            </optgroup>
          </select>
        </div>

        <!-- Zone de mise à jour du sport -->
        <div v-if="selectedSport" class="mt-4">
          <div class="form-group">
            <label for="updatedSportName">Nom du sport à mettre à jour</label>
            <input type="text" id="updatedSportName" v-model="updatedSportName" class="form-control" />
          </div>
          <button class="btn btn-create mt-3" @click="updateSportName">
            Modifier le sport
          </button>
        </div>

        <!-- Zone d'ajout d'un sport à un thème sélectionné -->
        <div v-if="selectedTheme" class="mt-4 mb-4">
          <div class="form-group">
            <label for="newSportName">Nom du sport à ajouter</label>
            <input type="text" id="newSportName" v-model="newSportName" class="form-control" />
          </div>
          <button class="btn btn-create mt-3" @click="addSport">
            Ajouter le sport
          </button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import SportService from '@/api/SportService';
export default {
  data() {
    return {
      themes: [],
      selectedSport: null,
      updatedSportName: "",
      selectedTheme: null,
      newSportName: ""
    };
  },
  mounted() {
    this.loadThemes();
  },
  methods: {
    async loadThemes() {
      try {
        this.themes = await SportService.getThemes();
      } catch (error) {
        console.log(error);
        this.$showToast('error', 'Erreur lors de la récupération des thèmes');
      }
    },
    handleSportChange() {
      for (let theme of this.themes) {
        for (let sport of theme.sports) {
          if (sport.id === this.selectedSport) {
            this.updatedSportName = sport.name;
            this.selectedTheme = theme.id;
            return;
          }
        }
      }
      this.selectedTheme = null;
    },
    handleSelectionChange() {
      if (String(this.selectedItem).startsWith('theme-')) {
        this.selectedTheme = this.selectedItem.split('-')[1];
        this.selectedSport = null;
        this.updatedSportName = "";  // Reset le nom du sport
      } else {
        for (let theme of this.themes) {
          for (let sport of theme.sports) {
            if (sport.id === this.selectedItem) {
              this.updatedSportName = sport.name;
              this.selectedTheme = null;  // Reset le thème sélectionné
              this.selectedSport = sport.id;
              return;
            }
          }
        }
      }
    },
    async updateSportName() {

      // verify that the sport name is not empty
      if (this.updatedSportName === '') {
        this.$showToast('error', this.$t('error.form.sportNameEmpty'));
        return;
      }

      try {
        const payload = {
          name: this.updatedSportName
        };
        await SportService.updateSport(this.selectedSport, payload);

        // update sport in themes
        for (let theme of this.themes) {
          for (let sport of theme.sports) {
            if (sport.id === this.selectedSport) {
              sport.name = this.updatedSportName;
              break;
            }
          }
        }
      } catch (error) {
        console.log(error);
        this.$showToast('error', 'Erreur lors de la mise à jour du sport');
      }
    },

    async addSport() {

      // Vérifiez que le nom du sport n'est pas vide
      if (this.newSportName === '') {
        this.$showToast('error', this.$t('error.form.sportNameEmpty'));
        return;
      }

      try {
        const payload = {
          name: this.newSportName,
          theme_id: this.selectedTheme
        };
        const new_sport = await SportService.createSport(payload);

        this.newSportName = '';
        // add sport in themes
        for (let theme of this.themes) {
          if (theme.id === this.selectedTheme) {
            theme.sports.push(new_sport);
            break;
          }
        }

        this.$showToast('success', 'Sport ajouté avec succès');
      } catch (error) {
        console.log(error);
        this.$showToast('error', 'Erreur lors de l\'ajout du sport');
      }
    }
  }
};
</script>
