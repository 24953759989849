<!-- CoursesBycoach.vue -->
<template>
  <div class="container-lg flex-grow-1 ml-box mb-4 bg-white">
    <div class="row mt-4">
      <div :class="['col-xl-12', 'col-lg-12', 'col-md-12', 'col-sm-12']">
        <h2 class="ml-title">{{ $t('liveSession.nextSessionsOf', { name: coach?.pseudo }) }}
          <template v-if="coach?.url_image">
            <img class="rounded" :src="coach.url_image" alt="avatar" style="width: 35px; height: auto">
          </template>
          <template v-else>
            <i class="bi bi-person-fill text-dark me-2"></i>
          </template>
        </h2>
      </div>

      <div v-if="subscriptions && subscriptions.length"
        :class="['col-xl-12', 'col-lg-12', 'col-md-12', 'col-sm-12', 'mt-2']">
        <div class="d-flex align-items-center">
          <i class="bi bi-infinity mb-1 me-2"></i>
          <h3 v-if="subscriptions.length === 1" class="font-italic" v-t="'session.subscriptionAvailable'"></h3>
          <h3 v-else class="font-italic" v-t="'session.subscriptionsAvailable'"></h3>
        </div>

        <div class="card">
          <div class="card-body">
            <template v-for="subscription in subscriptions" :key="subscription.id">
              <div class="row mb-1">
                <div :class="['col-lg-4', 'col-md-12', 'col-sm-12']">
                  <i class="bi bi-infinity me-2"></i>
                  <span>{{ convertToLocalTimeWhitShortDay(subscription.start_date) }}</span>
                  <i class="bi bi-arrow-right me-2"></i>
                  <span>{{ convertToLocalTimeWhitShortDay(subscription.end_date) }}</span>
                </div>
                <!-- nb sessions -->
                <div :class="['col-lg-2', 'col-md-4', 'col-sm-6']">
                  <i class="bi bi-bag me-2"></i>
                  <span>{{ $t('payment.subscription.nbSessions', {
        nbSessions: subscription.nbSessions,
      }) }}</span>
                </div>

                <!-- prix -->
                <div :class="['col-lg-3', 'col-md-4', 'col-sm-6']">
                  <i class="bi bi-currency-dollar me-2"></i>
                  <span>{{ formatPrice(subscription.amount) }}</span>
                </div>

                <div :class="['col-lg-3', 'col-md-4', 'col-sm-12']">
                  <span data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom"
                    v-bind:data-bs-content="!isLoggedIn ? $t('general.shouldConnect') : ''">
                    <button :class="['btn', 'w-100', 'btn-moving-live']"
                      @click.prevent="this.openPackagePaymentModal(subscription, coach)" :disabled="!isLoggedIn">
                      <span>
                        <i class="bi bi-infinity me-2"></i> {{ $t('subscription.unlimited') }}
                      </span>
                    </button>
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <template v-if="courses && courses.length">
      <OffersTile :courses="courses" :isForSinglecoach="true" />
      <PaymentModal />
    </template>

    <NoLiveSessionTile v-if="!courses || !courses.length" />
  </div>
</template>

<script>
import PaymentModal from '@/components/PaymentModal.vue';
import NoLiveSessionTile from '@/components/liveSessions/NoLiveSessionTile.vue';
import OffersTile from '@/components/offers/OffersTile.vue';
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { packageMixin } from '@/mixins/packageMixin';
import { timeMixin } from '@/mixins/timeMixin';
import api from '@/services/api.js';
import { priceMixin } from '@/mixins/priceMixin';

export default {
  name: 'CoachProfile',
  mixins: [timeMixin, liveSessionMixin, packageMixin, priceMixin],
  props: {
    pseudo: String
  },
  components: {
    OffersTile, NoLiveSessionTile, PaymentModal
  },
  data() {
    return {
      coach: null,
      courses: [],
      subscriptions: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/currentUser'];
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
  },
  methods: {
    wantSubscription() {
      this.$router.push({ name: 'Subscription', params: { id: this.id } });
    },
    calculateNbSessions(subscription) {
      const startDate = new Date(subscription.start_date);
      const endDate = new Date(subscription.end_date);
      return this.courses.filter(course => {
        const courseDate = new Date(course.start_time);
        return courseDate >= startDate && courseDate <= endDate;
      }).length;
    },
    async fetchSubscriptions() {
      try {
        const response = await api.get(`/v1/coaches/${this.coach.id}/subscriptions`);
        this.subscriptions = response.data.subscriptions.map(subscription => ({
          ...subscription,
          nbSessions: this.calculateNbSessions(subscription)
        }));
      } catch (error) {
        console.error(error);
      }
    }
  },
  async created() {
    const encodedPseudo = encodeURIComponent(this.pseudo);
    try {
      const response = await api.get(`/v1/coaches/${encodedPseudo}`);
      this.coach = response.data.coach;
      this.courses = response.data.courses;
    } catch (error) {
      console.error(error);
    }

    await this.fetchSubscriptions();
  },
};
</script>
