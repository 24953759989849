// src/store/modules/coachLiveSessions.js
import coachService from '@/api/CoachService';
import liveSessionService from '@/api/liveSessionService';

const getDefaultState = () => {
    return {
        liveSessions: [],
        pastLiveSessions: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        liveSessions(state) {
            return state.liveSessions;
        },
        pastLiveSessions(state) {
            return state.pastLiveSessions;
        },
        hasLiveSessions(state) {
            return state.liveSessions && state.liveSessions.length > 0;
        },
        hasPastLiveSessions(state) {
            return state.pastLiveSessions && state.pastLiveSessions.length > 0;
        },
    },
    mutations: {
        SET_LIVE_SESSIONS(state, liveSessions) {
            state.liveSessions = liveSessions;
        },
        SET_PAST_LIVE_SESSIONS(state, pastLiveSessions) {
            state.pastLiveSessions = pastLiveSessions;
        },
        ADD_LIVE_SESSION(state, liveSession) {
            state.liveSessions.push(liveSession);
        },
        REMOVE_LIVE_SESSION(state, liveSessionId) {
            state.liveSessions = state.liveSessions.filter(liveSession => liveSession.id !== liveSessionId);
        },
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        UPDATE_LIVE_SESSION(state, liveSession) {
            const index = state.liveSessions.findIndex(s => s.id === liveSession.id);
            state.liveSessions[index] = liveSession;
        }
    },
    actions: {
        async fetchLiveSessions({ commit }, coachId) {
            const liveSessions = await coachService.getLiveSessions(coachId);
            commit('SET_LIVE_SESSIONS', liveSessions);
        },
        async fetchPastLiveSessions({ commit }, coachId) {
            const pastLiveSessions = await coachService.getPastLiveSession(coachId);
            commit('SET_PAST_LIVE_SESSIONS', pastLiveSessions);
        },
        async createLiveSession({ commit, dispatch }, liveSessionData) {
            const newLiveSession = await liveSessionService.createLiveSession(liveSessionData);
            commit('ADD_LIVE_SESSION', newLiveSession);
            dispatch('participantLiveSessions/addLiveSession', newLiveSession, { root: true });
        },
        async cancelLiveSession({ commit }, { liveSessionId, sendNotification = true}) {
            await liveSessionService.cancelLiveSession(liveSessionId, sendNotification);
            commit('REMOVE_LIVE_SESSION', liveSessionId);
        },
        async updateLiveSession({ commit }, liveSessionData ) {
            const updated_live_session = await liveSessionService.updateLiveSession( liveSessionData.id, liveSessionData);
            commit('UPDATE_LIVE_SESSION', updated_live_session);
        },
        async reset({ commit }) {
            commit('RESET');
        },
        async resetState({ commit }) {
            commit('RESET_STATE');
        }
    },
};
