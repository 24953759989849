/* global bootstrap */

export const accordionMixin = {
    async mounted() {
        this.$nextTick(() => {
            this.initializeAccordions();
        });
    },
    beforeUnmount() {
        this.destroyAccordions();
    },
    methods: {
        initializeAccordions() {
            if (!this.$el || !(this.$el instanceof Element)) {
                return;
            }

            const accordionElements = [].slice.call(this.$el.querySelectorAll('.accordion-collapse'));
            this.accordions = accordionElements.map(el => {

                const accordionInstance = new bootstrap.Collapse(el, {
                    toggle: false
                });

                // Attach events if not already attached
                if (!el.dataset.eventsAttached) {
                    el.addEventListener('show.bs.collapse', (event) => {
                        // Prevent show event if element is still collapsing
                        if (el.classList.contains('collapsing')) {
                            event.preventDefault();
                        }
                    });

                    el.dataset.eventsAttached = true;
                }

                return accordionInstance;
            });
        },
        destroyAccordions() {
            if (this.accordions) {
                this.accordions.forEach(accordion => {
                    if (document.body.contains(accordion._element)) {
                        accordion.dispose();
                    }
                });
            }
        },
        resetAccordions() {
            // Destroy existing accordions
            const accordions = [].slice.call(document.querySelectorAll('.accordion-collapse'));
            accordions.forEach(el => {
                const accordionInstance = bootstrap.Collapse.getInstance(el);
                if (accordionInstance) {
                    accordionInstance.dispose();
                }
            });

            // Reinitialize accordions after a short delay to ensure DOM changes have taken effect
            this.$nextTick(() => {
                this.initializeAccordions();
            });
        }
    }
};
