<template>
  <div class="container mb-4 ml-box bg-white">
    <div class="row mt-4">
      <div class="col-md-3">
        <nav id="sidebar">
          <ul class="list-unstyled components" id="sidebar-list">
          </ul>
        </nav>
      </div>

      <div class="col-md-9">
        <section id="section">
          <div v-t="'confidentiality.legalNotice'"></div>
          <h1 class="ml-title mb-4" v-t="'confidentiality.title'"></h1>
          <div class="mb-4" v-t="'confidentiality.intro'"></div>

          <section id="section1">
            <h4 v-t="'confidentiality.section1Title'"></h4>

            <section id="section1-1">
              <p v-t="'confidentiality.section1Content'"></p>
            </section>

          </section>

          <section id="section2">
            <h4 v-t="'confidentiality.section2Title'"></h4>

            <section id="section2-1">
              <div v-t="'confidentiality.section2Content'"></div>
              <p v-t="'confidentiality.section2Content1'"></p>
              <ul>
                <li v-for="(item, index) in $tm('confidentiality.section2Content1Items')" :key="index">
                  {{ item }}
                </li>
              </ul>
            </section>

            <section id="section2-2">
              <div v-t="'confidentiality.section22Title'"></div>
              <p v-t="'confidentiality.section22Content'"></p>
              <ul>
                <li v-for="(item, index) in $tm('confidentiality.section22ContentItems')" :key="index">
                  {{ item }}
                </li>
              </ul>
            </section>


            <section id="section2-3">
              <div v-t="'confidentiality.section23Title'"></div>
              <p v-t="'confidentiality.section23Content'"></p>
              <ul>
                <li v-for="(item, index) in $tm('confidentiality.section23ContentItems')" :key="index">
                  {{ item }}
                </li>
              </ul>
            </section>

          </section>

          <section id="section3">
            <h4 v-t="'confidentiality.section3Title'"></h4>

            <section id="section3-1">
              <div v-t="'confidentiality.section31Title'"></div>
              <p v-t="'confidentiality.section31Content'"></p>
              <ul>
                <li v-for="(item, index) in $tm('confidentiality.section31ContentItems')" :key="index">
                  {{ item }}
                </li>
              </ul>
            </section>

            <section id="section3-2">
              <div v-t="'confidentiality.section32Title'"></div>
              <p v-t="'confidentiality.section32Content'"></p>
            </section>

            <section id="section3-3">
              <div v-t="'confidentiality.section33Title'"></div>
              <p v-t="'confidentiality.section32Content'"></p>
            </section>

            <section id="section3-4">
              <div v-t="'confidentiality.section34Title'"></div>
              <p v-t="'confidentiality.section34Content'"></p>
              <ul>
                <li v-for="(item, index) in $tm('confidentiality.section34ContentItems')" :key="index">
                  {{ item }}
                </li>
              </ul>
            </section>

            <section id="section3-5">
              <div v-t="'confidentiality.section35Title'"></div>
              <p v-t="'confidentiality.section35Content'"></p>
            </section>

          </section>

          <section id="section4">
            <h4 v-t="'confidentiality.section4Title'"></h4>

            <section id="section4-1">
              <div v-t="'confidentiality.section41Title'"></div>
              <p v-t="'confidentiality.section41Content'"></p>
              <ul>
                <li v-for="(item, index) in $tm('confidentiality.section41ContentItems')" :key="index">
                  {{ item }}
                </li>
              </ul>
            </section>
          </section>

          <section id="section5">
            <h4 v-t="'confidentiality.section5Title'"></h4>

            <section id="section5-1">
              <div v-t="'confidentiality.section51Title'"></div>
              <p v-t="'confidentiality.section51Content'"></p>
              <p v-t="'confidentiality.section51Content1'"></p>
            </section>

            <section id="section5-2">
              <div v-t="'confidentiality.section52Title'"></div>
              <p v-t="'confidentiality.section52Content'"></p>
              <p v-t="'confidentiality.section52Content1'"></p>
            </section>


          </section>

          <section id="section6">
            <h4 v-t="'confidentiality.section6Title'"></h4>

            <section id="section6-1">
              <div v-t="'confidentiality.section61Title'"></div>
              <p v-t="'confidentiality.section61Content'"></p>
            </section>

            <section id="section6-2">
              <div v-t="'confidentiality.section62Title'"></div>
              <p v-t="'confidentiality.section62Content'"></p>
            </section>

            <section id="section6-3">
              <div v-t="'confidentiality.section63Title'"></div>
              <p v-t="'confidentiality.section63Content'"></p>
              <ul>
                <li v-for="(item, index) in $tm('confidentiality.section63ContentItems')" :key="index">
                  {{ item }}
                </li>
              </ul>
            </section>
          </section>

          <section id="section7">
            <h4 v-t="'confidentiality.section7Title'"></h4>
            <p v-t="'confidentiality.section7Content'"></p>

          </section>

          <section id="section8">
            <h4 v-t="'confidentiality.section8Title'"></h4>
            <p v-t="'confidentiality.section8Content'"></p>
          </section>

          <section id="section9">
            <h4 v-t="'confidentiality.section9Title'"></h4>
            <p v-t="'confidentiality.section9Content'"></p>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfidentialiteVue',
  metaInfo: {
    title: 'Confidentialité',
    meta: [
      {
        vmid: 'confidentialite',
        name: 'description',
        content: 'Politique de confidentialité de MovingLive.'
      }
    ]
  },
  components: {
  },
  computed: {
  },
};
</script>

<style scoped>
section {
  font: 500 20px/34px var(--primary-font);
  margin-bottom: 2rem;
}
</style>