<template>
  <div class="container-lg flex-grow-1 ml-box mb-4 bg-white">
    <h2 class="ml-title mt-4 mb-4" v-t="'gettingStarted.title'"></h2>

    <div class="news-section mt-4 pt-2 pb-2 ml-box px-md-4 px-3 row" v-for="section in sections" :key="section.id">
      <div class="col-sm-6 text-section">
        <h3 class="ml-title mt-4">{{ section.title }}</h3>
        <p>{{ section.content }}</p>
        <ul>
          <li v-for="(item, idx) in section.list" :key="idx">{{ item }}</li>
        </ul>
      </div>
      <div class="col-sm-6 image-section d-flex justify-content-center align-items-center">
        <img v-if="section.imageUrl" :src="`/img/gettingStarted/${section.id}/${section.imageUrl}`" :alt="`/img/gettingStarted/${section.id}`" class="img-fluid limited-height print-screen">
        <div v-if="section.imagesName" id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
              aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
              aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
              aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner print-screen">
            <div v-for="(imageName, index) in section.imagesName" :key="index" class="carousel-item"
              :class="{ active: index === 0 }">
              <img :src="`/img/gettingStarted/${section.id}/${imageName}`" class="d-block w-100 limited-height" alt="...">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'GettingStarted',
  components: {
  },
  computed: {
    formattedContent() {
      return this.content.replace(/\n/g, '<br>');
    }
  },
  data() {
    return {
      sections: this.$tm('gettingStarted.sections', { returnObjects: true }) // Cette méthode permet de récupérer les objets traduits
    }
  },
  methods: {

  }
};
</script>

<style scoped>
.limited-height {
  max-height: 250px;
  /* Ajustez cette valeur selon vos besoins */
}

/* Media query pour les écrans de moins de 768px (point de rupture Bootstrap pour les mobiles) */
@media (max-width: 767.98px) {
  .limited-height {
    max-height: 150px;
    /* Réduit la taille maximale de l'image sur les écrans mobiles */
  }
}

.print-screen {
/*   border-radius: 24px; */
  border-radius: .25rem;
}
</style>
