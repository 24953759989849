<template>
  <div class="container-lg flex-grow-1 ml-box mb-4 bg-white">
    <h2 class="ml-title mt-4" v-t="'liveSession.management.title'"></h2>
    <template v-if="bookedLiveSessions && bookedLiveSessions.length">
      <div v-for="liveSession in bookedLiveSessions" :key="liveSession.id" class="mb-3">
        <LiveSessionTile :liveSession="liveSession" :isForListBooking="true" />
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col">
          <p v-t="'liveSession.management.noItems'"></p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LiveSessionTile from '@/components/liveSessions/LiveSessionTile.vue';
export default {
  name: 'ManageBooking',
  components: {
    LiveSessionTile
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/currentUser'];
    },
    liveSessions() {
      return this.$store.getters['participantLiveSessions/liveSessions'];
    },
    bookedLiveSessions() {
      return this.liveSessions.filter(session => session.user_booked);
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style>
.btn-booking-live {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.btn-moving-live,
.btn-moving-live:hover,
.btn-create:hover,
.btn-accept:hover,
.date-item:hover,
.btn-booking-live:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}
</style>
