<template>
    <div class="modal fade" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="feedbackModalLabel">{{ $t('feedbackModal.title') }}</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="sendFeedback">
                        <!-- Catégorie de feedback -->
                        <div class="mb-3">
                            <select class="form-select" v-model="feedbackCategory">
                                <option value="" disabled>{{ $t('feedbackModal.category.iWant') }}</option>
                                <option>{{ $t('feedbackModal.category.question') }}</option>
                                <option>{{ $t('feedbackModal.category.idea') }}</option>
                                <option>{{ $t('feedbackModal.category.bug') }}</option>
                                <option>{{ $t('feedbackModal.category.session') }}</option>
                            </select>
                        </div>

                        <!-- Champ texte -->
                        <div class="mb-3">
                            <label for="feedbackText" class="form-label">{{ $t('feedbackModal.details') }}<span
                                    class="text-danger">*</span></label>
                            <textarea class="form-control" v-model="feedbackText" rows="3"></textarea>
                        </div>

                        <!-- Champ email -->
                        <div class="mb-3">
                            <label for="email" class="form-label">{{ $t('feedbackModal.email') }}<span
                                    class="text-danger">*</span></label>
                            <input type="email" class="form-control" v-model="userEmail">
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{
                        $t('feedbackModal.close') }}</button>
                            <button type="submit" class="btn btn-accept">{{ $t('feedbackModal.submit') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/api/userService';
import { Modal } from 'bootstrap';
import { mapGetters } from 'vuex';
export default {
    name: 'FeedbackModal',
    data() {
        return {
            feedbackText: '',
            feedbackCategory: '',
            userEmail: this.currentUser?.email || ''
        };
    },
    mounted() {
        // Ajoute un listener pour être averti lors de la fermeture de la popup
        const modalEl = document.getElementById('feedbackModal');
        modalEl.addEventListener('hidden.bs.modal', this.removeBackdrop);
    },
    beforeUnmount() {
        // Permet de supprimer le fond noir
        const modalEl = document.getElementById('feedbackModal');
        modalEl.removeEventListener('hidden.bs.modal', this.removeBackdrop);
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
        }),
    },
    methods: {
        checkDatas() {
            if (this.feedbackText === '' || this.feedbackCategory === '' || this.userEmail === '') {
                this.$showToast('error', this.$t('error.form.notFilled'));
                return false;
            }

            // Regex email
            const regex = /\S+@\S+\.\S+/;
            if (!regex.test(this.userEmail)) {
                this.$showToast('error', this.$t('error.form.email'));
                return false;
            }

            return true;
        },
        async sendFeedback() {
            if (!this.checkDatas()) {
                return;
            }
            try {
                const payLoad = {
                    feedbackCategory: this.feedbackCategory,
                    feedbackText: this.feedbackText,
                    email: this.userEmail
                };
                await userService.sendFeedback(payLoad)
                // Fermer la modal
                const modalEl = document.getElementById('feedbackModal');
                Modal.getInstance(modalEl)?.hide();
                this.$showToast('success', this.$t('feedbackModal.sendSuccess'));
            } catch (error) {
                console.log(error);
                this.$showToast('error', this.$t('feedbackModal.sendError'));
            }
        },
        removeBackdrop() {
            const backdrops = document.querySelectorAll('.modal-backdrop');
            backdrops.forEach(backdrop => {
                backdrop.remove();
            });
        }
    }
}
</script>

<style scoped>
.modal-title {
    color: var(--primary-color);
}
</style>
