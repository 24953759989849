/* store/modules/participantLiveSessions.js */
import liveSessionService from '@/api/liveSessionService';

const getDefaultState = () => {
    return {
        liveSessions: [],
        filteredLiveSessions: [],
    };
};

const mergeSessions = (newSessions, oldSessions) => {
    const mergedSessions = newSessions.map(session => {
        const oldSession = oldSessions.find(s => s.id === session.id);
        return oldSession ? { ...session, package_id: oldSession.package_id } : session;
    });
    return mergedSessions;
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        liveSessions(state) {
            return state.liveSessions;
        },
        filteredLiveSessions(state) {
            return state.filteredLiveSessions;
        },
        hasLiveSessions(state) {
            return state.liveSessions && state.liveSessions.length > 0;
        },
        hasFilteredLiveSessions(state) {
            return state.filteredLiveSessions && state.filteredLiveSessions.length > 0;
        }
    },
    mutations: {
        SET_LIVE_SESSIONS(state, liveSessions) {
            state.liveSessions = mergeSessions(liveSessions, state.liveSessions);
        },
        SET_FILTERED_LIVE_SESSIONS(state, filteredLiveSessions) {
            state.filteredLiveSessions = filteredLiveSessions;
        },
        SET_PACKAGE_IDS(state, packageUpdates) {

            const updatePackageIds = (sessions) => {
                packageUpdates.forEach(({ liveSessionId, packageId }) => {
                    const sessionIndex = sessions.findIndex(liveSession => liveSession.id === liveSessionId);
                    if (sessionIndex !== -1) {
                        sessions[sessionIndex] = {
                            ...sessions[sessionIndex],
                            package_id: packageId
                        };
                    }
                });
            };

            updatePackageIds(state.liveSessions);
            updatePackageIds(state.filteredLiveSessions);
        },
        ADD_BOOKER_EMAIL(state, { liveSessionId, email }) {
            const session = state.liveSessions.find(liveSession => liveSession.id === liveSessionId);
            if (session) {
                if (!session.bookers_emails) {
                    session.bookers_emails = [];
                }
                session.bookers_emails.push(email);
            }
        },
        ADD_BOOKER_NAME(state, { liveSessionId, name }) {
            const session = state.liveSessions.find(liveSession => liveSession.id === liveSessionId);
            if (session) {
                if (!session.bookers_names) {
                    session.bookers_names = [];
                }
                session.bookers_names.push(name);
            }
        },
        SET_USER_BOOKED(state, { liveSessionId, userBooked }) {
            const session = state.liveSessions.find(liveSession => liveSession.id === liveSessionId);
            if (session) {
                session.user_booked = userBooked;
            }
            const filteredSession = state.filteredLiveSessions.find(liveSession => liveSession.id === liveSessionId);
            if (filteredSession) {
                filteredSession.user_booked = userBooked;
            }
        },
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        ADD_LIVE_SESSION(state, liveSession) {
            state.liveSessions.push(liveSession);
        }
    },
    actions: {
        async fetchLiveSessions({ commit }) {
            try {
                const liveSessions = await liveSessionService.getLiveSessions();
                commit('SET_LIVE_SESSIONS', liveSessions);
            } catch (error) {
                console.error('Error while fetching participant live sessions:', error);
            }
        },
        setPackageIds({ commit }, payloads) {
            commit('SET_PACKAGE_IDS', payloads);
        },
        recordParticipant(context, liveSessionId) {
            liveSessionService.addParticipant(liveSessionId);
        },
        recordReservation({ commit }, { liveSessionId, participant }) {
            liveSessionService.addReservation(liveSessionId);
            commit('ADD_BOOKER_EMAIL', { liveSessionId, email: participant.email });
            commit('ADD_BOOKER_NAME', { liveSessionId, name: participant.pseudo });
            commit('SET_USER_BOOKED', { liveSessionId, userBooked: true });
        },
        cancelReservation({ commit }, liveSessionId) {
            liveSessionService.cancelReservation(liveSessionId);
            commit('SET_USER_BOOKED', { liveSessionId, userBooked: false });
        },
        async init({ commit }) {
            const liveSessions = await liveSessionService.getLiveSessions();
            commit('SET_LIVE_SESSIONS', liveSessions);
            commit('SET_FILTERED_LIVE_SESSIONS', JSON.parse(JSON.stringify(liveSessions))); // Deep copy to avoid mutation side effects
        },
        async resetState({ commit }) {
            commit('RESET_STATE');
        },
        async addLiveSession({ commit }, liveSessionData) {
            console.log('Adding live session', liveSessionData);
            commit('ADD_LIVE_SESSION', liveSessionData);
        },
    },
};
