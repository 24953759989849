<template>
    <div class="toast">
      <!-- contenu du toast -->
    </div>
  </template>

<script>
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import { nextTick } from 'vue';
import { mapState } from 'vuex';

// Function to escape HTML
function escapeHtml(unsafe) {
    return typeof unsafe === 'string' ? unsafe.replace(/[&<"']/g, function (m) {
        switch (m) {
            case '&': return '&amp;';
            case '<': return '&lt;';
            case '"': return '&quot;';
            case "'": return '&#039;';
            default: return m;
        }
    }) : '';
}

export default {
    name: 'ToastVue',
    data() {
        return {
            toastMessage: '',
            toastBg: '',
            toastBorder: '',
            toastTextColor: 'black', // Default text color
            defaultMessage: this.$t('error.global'), // Default message
        };
    },
    computed: {
        ...mapState(['toast'])
    },
    watch: {
        'toast.show'(newVal) {
            if (newVal) {
                this.prepareToastData();
                nextTick().then(() => {
                    Toastify({
                        text: this.toastMessage,
                        duration: 5000,
                        close: true,
                        gravity: "top", // `top` or `bottom`
                        position: "center", // `left`, `center` or `right`
                        stopOnFocus: true, // Prevents dismissing of toast on hover
                        style: {
                            background: this.toastBg,
                            color: this.toastTextColor, // Text color
                            textAlign: "center" // Center the text
                        },
                        offset: {
                            y: '100px' // Offset the toast by 100px from the top
                        },
                        escapeMarkup: false // Allows HTML markup in the text
                    }).showToast();
                }).catch(error => {
                    console.error('An error occurred:', error);
                });
            }
        }
    },
    methods: {
        prepareToastData() {
            if (!this.toast || typeof this.toast.message !== 'string') {
                console.error('Toast data is invalid or message is not a string.');
                this.toastMessage = this.defaultMessage; // Use default message if invalid
            } else {
                // Escape the message and replace newlines with <br> tags
                this.toastMessage = escapeHtml(this.toast.message).replace(/\n/g, '<br>');
            }

            switch (this.toast.type) {
                case 'error':
                    this.toastBg = '#fff5f5';
                    this.toastBorder = 'var(--alert-color)';
                    break;
                case 'success':
                    this.toastBg = '#ffffff';
                    this.toastBorder = 'var(--primary-color)';
                    break;
                default:
                    this.toastBg = '#ffffff';
            }
        }
    },
}
</script>

<style scoped>
/* No additional styles needed as Toastify handles styling */
</style>
