<template>
    <!-- Ce composant n'a pas besoin de rendu visible -->
    <div></div>
</template>

<script>
/* global google */
import { timeMixin } from '@/mixins/timeMixin';
export default {
    name: 'GoogleOneTap',
    mixins: [timeMixin],
    mounted() {
        // Initialisation de Google OneTap si l'utilisateur n'est pas connecté
        if (!this.isLoggedIn) {
            this.initGoogleOneTap();
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters['user/isLoggedIn'];
        },
        googleAppId() {
            return process.env.VUE_APP_GOOGLE_CLIENT_ID;
        }
    },
    watch: {
        // Surveiller les changements d'état de connexion
        isLoggedIn(newValue) {
            if (!newValue) {
                // Si l'utilisateur se déconnecte, réinitialiser Google OneTap
                this.initGoogleOneTap();
            }
        }
    },
    methods: {
        initGoogleOneTap() {
            // S'assurer que Google OneTap n'est pas initialisé si l'utilisateur est connecté
            if (!this.isLoggedIn && typeof google !== "undefined" && google.accounts && google.accounts.id) {
                google.accounts.id.initialize({
                    client_id: this.googleAppId,
                    callback: this.handleCredentialResponse,
                    use_fedcm_for_prompt: true,
                });

                google.accounts.id.prompt();
            }
        },
        async handleCredentialResponse(response) {
            // Traiter la réponse de Google OneTap
            const payload = {
                id_token: response.credential,
                timezone: this.getTimezone(),
                provider: 'google'
            };
            await this.$store.dispatch('user/login', payload);
            this.trackLoginEvent();
        },
        async trackLoginEvent() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'login',
                loginProvider: 'googleTap',
            });
        }
    }
}
</script>
