<template>
  <div class="container-lg flex-grow-1 ml-box mb-4 bg-white">

    <h2 class="ml-title mt-4" v-t="'consultPayment.title'"></h2>

    <div class="container-fluid">
      <div v-if="payments && payments.length" class="row g-4 ">
        <div class="col-12" v-for="payment in payments" :key="payment.id">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div :class="['col-lg-3', 'col-md-4', 'col-sm-6']">
                  <i class="bi bi-calendar me-2"></i>
                  <span>{{ convertToLocalTimeWhitShortDay(payment.create_time) }}</span>
                </div>
                <div :class="['col-lg-2', 'col-md-4', 'col-sm-6']">
                  <i :class="getLogo(payment)"></i>
                  <span>{{ getTypePayment(payment) }}</span>
                </div>
                <template v-if="isPackage(payment)">

                  <div :class="['col-lg-3', 'col-md-4', 'col-sm-6']">
                    <i class="bi bi-person me-2"></i>
                    <span>{{ payment.package?.coach.pseudo }}</span>
                  </div>

                  <div :class="['d-flex', 'col-lg-3', 'col-md-4', 'col-sm-6']">
                    <i class="bi bi-calendar-range me-2"></i>
                    <div>{{ formatDateShort(payment.package?.start_date) }}</div>
                    <i class="bi bi-arrow-right mx-2"></i>
                    <div>{{ formatDateShort(payment.package?.end_date) }}</div>
                  </div>
                  <div :class="['col-lg-1', 'col-md-6', 'col-sm-6']">
                    <i class="bi bi-currency-dollar"></i>
                    <span>{{ formatPrice(payment.total_amount) }}</span>
                  </div>

                </template>
                <template v-if="isLiveSession(payment)">
                  <div :class="['col-lg-3', 'col-md-4', 'col-sm-6']">
                    <i class="bi bi-person me-2"></i>
                    <span>{{ payment.live_session?.coach.pseudo }}</span>
                  </div>

                  <div :class="['col-lg-3', 'col-md-4', 'col-sm-6']">
                    <i class="bi bi-activity me-2"></i>
                    <span>{{ payment.live_session?.sport.name }}</span>
                  </div>
                  <div :class="['col-lg-1', 'col-md-6', 'col-sm-6']">
                    <i class="bi bi-currency-dollar"></i>
                    <span>{{ formatPrice(payment.total_amount) }}</span>
                  </div>

                </template>
                <template v-if="isReplay(payment)">
                  <div :class="['col-lg-3', 'col-md-4', 'col-sm-6']">
                    <i class="bi bi-person me-2"></i>
                    <span>{{ payment.replay?.coach.pseudo }}</span>
                  </div>

                  <div :class="['col-lg-3', 'col-md-4', 'col-sm-6']">
                    <i class="bi bi-activity me-2"></i>
                    <span>{{ payment.replay?.sport.name }}</span>
                  </div>

                  <div :class="['col-lg-1', 'col-md-4', 'col-sm-6']">
                    <i class="bi bi-currency-dollar"></i>
                    <span>{{ formatPrice(payment.total_amount) }}</span>
                  </div>

                  <div :class="['col-lg-12', 'col-md-4', 'col-sm-6']">
                    <button v-if="payment.replay" class="btn btn-replay ms-auto"
                      @click="openZoomLink(payment.replay.link)">
                      <i class="bi bi-camera-video mx-3"></i> {{ $t('replay.replay') }}
                    </button>
                  </div>

                </template>
              </div>
            </div>
          </div>
        </div>
        <span class="mb-4"></span>
      </div>
      <div v-else>
        <p class="text-center" v-t="'consultPayment.noContent'"></p>
      </div>
    </div>

  </div>
</template>

<script>
import PaymentService from '@/api/PaymentService';
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { priceMixin } from '@/mixins/priceMixin';
import { timeMixin } from '@/mixins/timeMixin';
import { zoomMixin } from '@/mixins/zoomMixin';

export default {
  name: 'ConsultPayment',
  mixins: [timeMixin, liveSessionMixin, priceMixin, zoomMixin],
  components: {
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/currentUser'];
    }
  },
  data() {
    return {
      payments: [],
    }
  },
  mounted() {
    this.fetchPayments();

  },
  methods: {
    // Ici, vous pouvez déclarer des fonctions qui seront utilisées dans votre template
    async fetchPayments() {
      try {
        this.payments = await PaymentService.getPaymentsByUser(this.currentUser.id);
      } catch (error) {
        console.log(error);
        this.$showToast('error', this.$t('consultPayment.loadingError'));
      }
    },
    getTypePayment(payment) {
      if (this.isPackage(payment)) {
        return this.$t('package.package');
      }
      if (this.isLiveSession(payment)) {
        return this.$t('liveSession.session');
      }
      if (this.isReplay(payment)) {
        return this.$t('replay.replay');
      }
    },
    getLogo(payment) {
      if (this.isPackage(payment)) {
        return "bi bi-calendar-range me-2";
      }
      if (this.isLiveSession(payment)) {
        return "bi bi-caret-right-square me-2";
      }
      if (this.isReplay(payment)) {
        return "bi bi-camera-video me-2";
      }
    },
    isPackage(payment) {
      return payment.package_id !== null;
    },
    isLiveSession(payment) {
      return payment.live_session_id !== null;
    },
    isReplay(payment) {
      return payment.replay_id !== null;
    },
  }
}
</script>

<style scoped>
.btn-replay {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-replay:hover,
.btn-replay:active {
  color: #ffffff;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
</style>