<!-- ReplaysTile.vue -->
<template>
    <div class="d-flex align-items-center">
        <i class="bi bi-camera-video mb-1 me-2"></i>
        <h3>{{ $t('replay.title') }}</h3>
    </div>

    <div v-if="isLoggedIn" class="row">
        <template v-if="hasReplays">
            <ReplayTile v-for="replay in replays" :key="replay.id" :replay="replay" />
        </template>
        <div v-else class="col-12">
            <p>{{ $t('replay.noReplay') }}</p>
        </div>
    </div>
    <div v-else class="row">
        <div class="col-12 mt-2">
            <p>{{ $t('replay.shouldBeConnected') }}</p>
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import ReplayTile from './ReplayTile.vue';
export default {
    name: 'ReplaysTile',
    components: {
        ReplayTile
    },
    computed: {
        ...mapGetters({
            hasReplays: 'participantReplays/hasFilteredReplays',
            isLoggedIn: 'user/isLoggedIn',
            replays: 'participantReplays/filteredReplays',
        }),
    },
}
</script>

<style scoped>
</style>
