// src/store/modules/participantPackages.js
import packageService from '@/api/PackageService';

const getDefaultState = () => {
    return {
        packages: [],
        filteredPackages: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        packages(state) {
            return state.packages;
        },
        hasPackages(state) {
            return state.packages && state.packages.length > 0;
        },
        filteredPackages(state) {
            return state.filteredPackages;
        },
        hasFilteredPackages(state) {
            return state.filteredPackages && state.filteredPackages.length > 0;
        },
        getPackageById: (state) => (id) => {
            return state.packages.find(pkg => pkg.id === id);
        }
    },
    mutations: {
        SET_PACKAGES(state, packages) {
            state.packages = packages;
        },
        SET_FILTERED_PACKAGES(state, packages) {
            state.filteredPackages = packages;
        },
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        ADD_PACKAGE(state, newPackage) {
            state.packages.push(newPackage);
        }
    },
    actions: {
        async fetchPackages({ commit }) {
            try {
                const packages = await packageService.getPackages();
                commit('SET_PACKAGES', packages);
            } catch (error) {
                console.error('Error while fetching participant packages:', error);
            }
        },
        async resetState({ commit }) {
            commit('RESET_STATE');
        },
        async ADD_PACKAGE({ commit }, newPackage) {
            commit('ADD_PACKAGE', newPackage);
        }
    },
};
