<template>
  <div class="container-lg flex-grow-1 ml-box mb-0 mb-md-4 bg-white">

    <h2 class="ml-title mt-4 mb-4" v-t="'stripeConnect.needRefresh.title'"></h2>
    <div>
      <p class="lead spacing" v-t="'stripeConnect.needRefresh.content'"></p>
      <p class="lead spacing" v-t="'stripeConnect.needRefresh.retry'"></p>

      <div v-if="!isLoggedIn" class="alert alert-light mt-2" role="alert">
        <i class="bi bi-info-square"></i>
        {{ $t('stripeConnect.needRefresh.notConnected') }}
      </div>
      <div v-else>
        <div class="d-flex justify-content-end">
          <button class="btn btn-create mt-2" @click="sendNewLink()">
            <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"
              aria-hidden="true"></span>
            <i v-else class="bi bi-arrow-repeat me-2"></i>

            <span v-if="isLoading">{{ $t('general.inProcess') }}</span>
            <span v-else>{{ $t('stripeConnect.needRefresh.refresh') }}</span>

          </button>
        </div>
        <div v-if="sendMailDone" class="alert alert-light mt-2" role="alert">
          <i class="bi bi-info-square"></i>
          {{ $t('stripeConnect.needRefresh.mailSent', { email: currentUser.email }) }}
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import CoachService from '@/api/CoachService';

export default {
  name: 'StripeOnboardSucces',
  data() {
    return {
      sendMailDone: false,
      isLoading: false,
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/currentUser'];
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
  },
  components: {
  },
  methods: {
    async sendNewLink() {
      try {
        this.isLoading = true;
        await CoachService.sendNewStripeLink(this.currentUser.id);
        this.sendMailDone = true;
      } catch (error) {
        console.error(error);
        this.$showToast('error', this.$t('stripeConnect.needRefresh.error'));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
