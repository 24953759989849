<template>
    <!-- Footer pour écrans md et plus grands -->
    <footer class="bg-light text-center p-1 d-none d-lg-block">
        <div class="row">
            <div class="col">
                <nav class="nav justify-content-center">
                    <p class="nav-link text-black mb-0">© {{ currentYear }} MovingLive Inc.</p>
                    <router-link to="/confidentialite" class="nav-link text-black" v-t="'footer.privacy'"></router-link>
                    <router-link to="/termes-et-conditions" class="nav-link text-black" v-t="'footer.terms'"></router-link>
                    <router-link to="/qui-sommes-nous" class="nav-link text-black" v-t="'footer.story'"></router-link>
                    <router-link to="/FAQ" class="nav-link text-black" v-t="'footer.faq'"></router-link>
                </nav>
            </div>
        </div>
    </footer>

    <!-- Footer pour écrans plus petits que md -->
    <footer class="bg-light text-center p-1 d-lg-none mb-md-0 mb-4">
        <div class="container">
        <div class="row justify-content-center">
          <!-- Column 1 -->
          <div class="col-6 col-md-5 mb-3">
            <ul class="list-unstyled">
              <li><router-link to="/qui-sommes-nous" class="text-black" v-t="'footer.story'"></router-link></li>
              <li><router-link to="/FAQ" class="text-black" v-t="'footer.faq'"></router-link></li>
            </ul>
          </div>
          <!-- Column 2 -->
          <div class="col-6 col-md-5 mb-3">
            <ul class="list-unstyled">
              <li><router-link to="/confidentialite" class="text-black" v-t="'footer.privacy'"></router-link></li>
              <li><router-link to="/termes-et-conditions" class="text-black" v-t="'footer.terms'"></router-link></li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text-black mb-0">© {{ currentYear }} MovingLive Inc.</p>
          </div>
        </div>
      </div>
    </footer>
  </template>

<script>
export default {
    name: 'FooterComponent',

    data() {
        return {
            currentYear: new Date().getFullYear()
        };
    },
    methods: {
        home() {
            // Votre logique pour la fonction home
        }
    }
};
</script>

<style scoped>
footer {
    margin-top: auto;
    border-top: 1px solid #ccc;
    padding: 20px;
}
/* Styles spécifiques au footer mobile */
.d-md-none footer {
    margin-bottom: 60px; /* Déplacez le margin-bottom ici */
}
</style>