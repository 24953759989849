<!-- FilterView.vue -->
<template>
    <div class="filter-section p-3 border rounded mt-2 mt-md-4 d-none d-md-block sticky-top bg-white">
        <div class="row g-3">
            <div class="col-lg-2 col-md-2 col-sm-6 col-12 d-flex align-items-center">
                <i class="bi bi-calendar me-2"></i>
                <select class="form-select flex-grow-1" v-model="localSelectedDate" :disabled="showAllPackages">
                    <option value="" selected>{{ $t('liveSession.filter.days') }}</option>
                    <option v-for="day in nextSevenDays" :key="day.date" :value="day.dayName">{{ day.dayName }}</option>
                </select>
            </div>
            <div class="col-lg-3  col-md-3 col-sm-6 col-12 d-flex align-items-center">
                <i class="bi bi-person me-2"></i>
                <select class="form-select flex-grow-1" v-model="localSelectedCoach">
                    <option value="" selected>{{ $t('liveSession.filter.coaches') }}</option>
                    <option v-for="coach in uniqueCoaches" :key="coach" :value="coach">{{ coach }}</option>
                </select>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12 d-flex align-items-center">
                <i class="bi bi-activity me-2"></i>
                <select class="form-select flex-grow-1" v-model="localSelectedSport">
                    <option value="" selected>{{ $t('liveSession.filter.sessions') }}</option>
                    <option v-for="sport in uniqueSports" :key="sport" :value="sport">{{ sport }}</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-6 col-12 d-flex align-items-center">
                <span data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom" class="w-100"
                    v-bind:data-bs-content="!isLoggedIn ? $t('general.shouldConnect') : ''">
                    <button class="btn btn-ml-secondary w-100" @click="showAllReplaysAction" :disabled="!isLoggedIn"
                        :class="{ 'toggle-active': showAllReplays }">
                        <i class="bi bi-camera-video mx-2"></i>
                        {{ $t('replay.replays') }}
                    </button>
                </span>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-6 col-12 d-flex align-items-center">
                <span data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom" class="w-100"
                    v-bind:data-bs-content="!isLoggedIn ? $t('general.shouldConnect') : ''">
                    <button class="btn btn-ml-secondary w-100" @click="showAllPackagesAction" :disabled="!isLoggedIn"
                        :class="{ 'toggle-active': showAllPackages }">
                        <i class="bi bi-infinity mx-2"></i>
                        {{ $t('package.packages') }}
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { popoverMixin } from '@/mixins/popoverMixin';
import { mapGetters } from 'vuex';

export default {
    name: 'FilterView',
    mixins: [popoverMixin],
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            nextSevenDays: 'filters/nextSevenDays',
            selectedCoach: 'filters/selectedCoach',
            selectedDate: 'filters/selectedDate',
            selectedSport: 'filters/selectedSport',
            showAllReplays: 'filters/showAllReplays',
            showAllPackages: 'filters/showAllPackages',
            uniqueCoaches: 'filters/uniqueCoaches',
            uniqueSports: 'filters/uniqueSports',
        }),
        localSelectedCoach: {
            get() {
                return this.selectedCoach;
            },
            set(value) {
                this.$store.dispatch('filters/setSelectedCoach', value);
                this.trackFilterEvent('coach', value, 'desktop');
            }
        },
        localSelectedSport: {
            get() {
                return this.selectedSport;
            },
            set(value) {
                this.$store.dispatch('filters/setSelectedSport', value);
                this.trackFilterEvent('sport', value, 'desktop');
            }
        },
        localSelectedDate: {
            get() {
                return this.selectedDate;
            },
            set(value) {
                this.$store.dispatch('filters/setSelectedDate', value);
                this.trackFilterEvent('date', value, 'desktop');
            }
        }
    },
    watch: {
        localSelectedCoach() {
            this.applyFilters();
        },
        localSelectedSport() {
            this.applyFilters();
        },
        localSelectedDate() {
            this.applyFilters();
        }
    },
    methods: {
        applyFilters() {
            this.$store.dispatch('filters/applyFilters');
        },
        resetFilter() {
            this.$store.dispatch('filters/resetState');
        },
        showAllReplaysAction() {
            this.$store.dispatch('filters/toggleShowAllReplays');

            if (this.showAllReplays) {
                this.trackShowReplayEvent();
            }
        },
        showAllPackagesAction() {
            this.$store.dispatch('filters/toggleShowAllPackages');

            if (this.showAllPackages) {
                this.trackShowReplayEvent();
            }
        },
        trackFilterEvent(filterType, filterValue, deviceType) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'filter_used',
                filterType: filterType,
                filterValue: filterValue,
                deviceType: deviceType,
            });
        },
        trackShowReplayEvent() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'show_all_replays',
                deviceType: 'desktop',
            });
        }
    }
}
</script>

<style scoped>
/* Styles pour Filter */
.filter-section {
    position: sticky;
    top: 68px;
    /* Ajustez cette valeur selon vos besoins */
    z-index: 1000;
    /* Assurez-vous que la section reste au-dessus d'autres contenus */
}

.filter-section .form-select {
    max-width: 100%;
}


.toggle-active {
    background-color: white;
    color: var(--primary-color);
    box-shadow: inset 0 0 0 3px #ADCFCA;
}
</style>
