<template>
    <div class="navbar-mobile d-lg-none fixed-bottom bg-dark">
        <div class="container d-flex justify-content-around">
            <template v-if="!isLoggedIn">
                <div class="nav-item flex-column text-center">
                    <a class="nav-link text-center" href="#" data-bs-toggle="modal" data-bs-target="#feedbackModal">
                        <i class="bi bi-envelope-fill icon-large"></i>
                        <span>{{ $t('menu.contact') }}</span>
                    </a>
                </div>
                <div class="nav-item dropdown flex-column text-center">
                    <a class="nav-link text-center" href="#" @click.prevent role="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="bi bi-translate icon-large"></i>
                        <span>
                            {{ $i18n.locale.toUpperCase() }}
                            <i class="bi bi-caret-down-fill ms-1"></i>
                        </span>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="localeDropdown">
                        <li>
                            <a class="dropdown-item" href="#" @click="setLocale($i18n.locale === 'fr' ? 'en' : 'fr')">
                                <span v-if="$i18n.locale === 'fr'">EN</span>
                                <span v-else>FR</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="nav-item dropdown flex-column text-center">
                    <a class="nav-link text-center" href="#" id="loginDropdownMobile" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-box-arrow-in-right icon-large"></i>
                        <span>{{ $t('menu.login') }}</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="loginDropdownMobile">
                        <li>
                            <GoogleLogin :callback="handleGoogleResponse" />
                        </li>
                        <li>
                            <a class="dropdown-item facebook" @click="loginWithFacebook"><i
                                    class="bi bi-facebook me-2"></i>{{ $t('menu.facebook') }}</a>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-else>

                <!-- workspace -->
                <div v-if="currentUser.is_coach_validated" class="nav-item flex-column text-center">
                    <router-link :to="getCourseLinkHref()" class="nav-link text-center" href="/#" @click="resetFilter">
                        <i class="bi bi-tools icon-large"></i>
                        <span>{{ $t('menu.workspace') }}</span>
                    </router-link>
                </div>

                <!-- Devenir coach -->
                <div v-if="!currentUser.is_coach_validated" class="nav-item flex-column text-center">
                    <router-link :to="getCourseLinkHref()" class="nav-link text-center" href="/#" @click="resetFilter">
                        <i class="bi bi-person-add icon-large"></i>
                        <span>{{ $t('menu.becomeCoach') }}</span>
                    </router-link>
                </div>

                <!-- Packages -->
                <div class="nav-item flex-column text-center" :class="[{ 'active-filter': showAllPackages }]">
                    <a class="nav-link text-center" href="/#" @click="showAllPackagesAction">
                        <i class="bi bi-infinity icon-large"></i>
                        <span>{{ $t('package.packages') }}</span>
                    </a>
                </div>


                <!-- Replays -->
                <div class="nav-item flex-column text-center" :class="[{ 'active-filter': showAllReplays }]">
                    <a class="nav-link text-center" href="/#" @click="showAllReplaysAction">
                        <i class="bi bi-camera-video icon-large"></i>
                        <span>{{ $t('replay.replays') }}</span>
                    </a>
                </div>


                <!-- Filtre -->
                <div class="nav-item dropdown flex-column text-center"
                    :class="[{ 'active-filter': selectedDate || selectedSport || selectedCoach }]">
                    <a class="nav-link text-center" href="#" id="filtersDropdown" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-search icon-large"></i>
                        <span>
                            {{ $t('liveSession.filter.filter') }}
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-fullwidth" aria-labelledby="filtersDropdown">
                        <div class="container h-100">
                            <div class="row h-100 overflow-auto">
                                <!-- Filtre jours -->
                                <div v-if="!showAllPackages" class="col-3 px-0">
                                    <h6 class="resetFilter" :class="{ 'active-filter-item': localSelectedDate === '' }"
                                        @click="localSelectedDate = ''; applyFilters()">{{ $t('liveSession.filter.days')
                                        }}</h6>
                                    <ul class="list-group list-group-flush px-2">
                                        <li class="list-group-item px-0" v-for="day in nextSevenDays" :key="day.date">
                                            <a class="dropdown-item  px-0" href="#"
                                                @click="localSelectedDate = day.dayName; applyFilters()">
                                                <span
                                                    :class="{ 'active-filter-item': localSelectedDate === day.dayName }">{{
                                                        day.dayName }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- Filtre séances -->
                                <div class="col-4 px-0">
                                    <h6 class="resetFilter" :class="{ 'active-filter-item': localSelectedSport === '' }"
                                        @click="localSelectedSport = ''; applyFilters()">{{
                                        $t('liveSession.filter.sessions') }}</h6>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item px-0" v-for="sport in uniqueSports" :key="sport">
                                            <a class="dropdown-item  px-0" href="#"
                                                @click="localSelectedSport = sport; applyFilters()">
                                                <span :class="{ 'active-filter-item': localSelectedSport === sport }">{{
                                                    sport }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- Filtre Coachs -->
                                <div class="col-4 px-0">
                                    <h6 class="resetFilter" :class="{ 'active-filter-item': localSelectedCoach === '' }"
                                        @click="localSelectedCoach = ''; applyFilters()">{{
                                        $t('liveSession.filter.coaches') }}</h6>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item px-0" v-for="coach in uniqueCoaches" :key="coach">
                                            <a class="dropdown-item  px-0" href="#"
                                                @click="localSelectedCoach = coach; applyFilters()">
                                                <span :class="{ 'active-filter-item': localSelectedCoach === coach }">{{
                                                    coach }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <MobileProfileDropdown />
        </div>
    </div>
</template>

<script>
import { timeMixin } from '@/mixins/timeMixin';
import { mapGetters } from 'vuex';
import MobileProfileDropdown from './MobileProfileDropdown.vue';

export default {
    name: 'MobileNavbar',
    mixins: [timeMixin],
    components: {
        MobileProfileDropdown
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            isLoggedIn: 'user/isLoggedIn',
            uniqueCoaches: 'filters/uniqueCoaches',
            uniqueSports: 'filters/uniqueSports',
            nextSevenDays: 'filters/nextSevenDays',
            showAllReplays: 'filters/showAllReplays',
            showAllPackages: 'filters/showAllPackages',
            selectedDate: 'filters/selectedDate',
            selectedSport: 'filters/selectedSport',
            selectedCoach: 'filters/selectedCoach'
        }),
        localSelectedCoach: {
            get() {
                return this.selectedCoach;
            },
            set(value) {
                this.$store.dispatch('filters/setSelectedCoach', value);
                this.trackFilterEvent('coach', value, 'mobile');
            }
        },
        localSelectedSport: {
            get() {
                return this.selectedSport;
            },
            set(value) {
                this.$store.dispatch('filters/setSelectedSport', value);
                this.trackFilterEvent('sport', value, 'mobile');
            }
        },
        localSelectedDate: {
            get() {
                return this.selectedDate;
            },
            set(value) {
                this.$store.dispatch('filters/setSelectedDate', value);
                this.trackFilterEvent('date', value, 'mobile');
            }
        }
    },
    methods: {
        /* global FB */
        setLocale(lang) {
            this.$i18n.locale = lang;
            localStorage.setItem('locale', lang);
        },
        getCourseLinkHref() {
            if (!this.currentUser) {
                return "#";
            } else if (this.currentUser.is_coach_validated === true) {
                return "/coach/dashboard";
            } else if (this.currentUser.is_coach_validated === false) {
                return "/enrollment-done-page";
            } else if (this.currentUser.is_coach_validated === null) {
                return "/coach-registration-form";
            } else {
                return "#";
            }
        },
        applyFilters() {
            this.$store.dispatch('filters/applyFilters');
        },
        resetFilter() {
            this.$store.dispatch('filters/resetState');
        },
        showAllReplaysAction() {
            this.$store.dispatch('filters/toggleShowAllReplays');

            if (this.showAllReplays) {
                this.trackShowReplayEvent();
            }
        },
        showAllPackagesAction() {
            this.$store.dispatch('filters/toggleShowAllPackages');

            if (this.showAllPackages) {
                this.trackShowPackageEvent();
            }
        },
        async handleGoogleResponse(response) {
            const payload = {
                id_token: response.credential,
                timezone: this.getTimezone(),
                provider: 'google'
            };
            await this.$store.dispatch('user/login', payload);
            this.trackLoginEvent('google');
        },
        loginWithFacebook() {
            FB.login((response) => {
                if (response.status === 'connected') {
                    this.handleFacebookLogin(response);
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            }, { scope: 'public_profile,email' });
        },
        handleFacebookLogin(response) {
            if (response?.status === 'connected') {
                const payload = {
                    id_token: response.authResponse.accessToken,
                    timezone: this.getTimezone(),
                    provider: 'facebook'
                };
                this.$store.dispatch('user/login', payload);
                this.trackLoginEvent('facebook');
            } else {
                console.log('Facebook login failed : ' + response?.status);
                this.$showToast('error', this.$t('error.facebookConnect'));
            }
        },
        trackFilterEvent(filterType, filterValue, deviceType) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'filter_used',
                filterType: filterType,
                filterValue: filterValue,
                deviceType: deviceType,
            });
        },
        trackShowReplayEvent() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'show_all_replays',
                deviceType: 'mobile',
            });
        },
        trackShowPackageEvent() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'show_all_packages',
                deviceType: 'mobile',
            });
        },
        async trackLoginEvent(provider) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'login',
                loginProvider: provider,
            });
        },
    },
};
</script>

<style scoped>
.nav-item {
    padding: 0.5rem 0 0 0;
}

.dropdown-menu-fullwidth {
    height: calc(100vh - 56px - 8vh);
    width: 100vw !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 !important;
    border-radius: 0;
    top: auto !important;
}


.dropdown-menu,
.list-group-item {
    background-color: #e9edeb;
}

.active-filter {
    position: relative;
}

.active-filter::before {
    content: '';
    position: absolute;
    top: 0;
    width: 80%;
    height: 3px;
    background-color: var(--primary-color);
}

.active-filter-item {
    font-weight: bold;
    color: var(--primary-color);
}

.resetFilter {
    cursor: pointer;
    text-align: center;
}
</style>
