<!-- Home.vue -->
<template>
  <div class="container-lg flex-grow-1 ml-box mb-0 mb-md-4 bg-white">
    <div class="row">
      <div class="col">
        <FilterView />
        <OffersTile v-if="hasFilteredLiveSessions" />
        <NoLiveSessionTile v-if="!hasFilteredLiveSessions && hasFilteredReplays" />
        <PaymentModal />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentModal from '@/components/PaymentModal.vue';
import FilterView from '@/components/filters/Filter.vue';
import NoLiveSessionTile from '@/components/liveSessions/NoLiveSessionTile.vue';
import OffersTile from '@/components/offers/OffersTile.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomeView',
  components: {
    OffersTile,
    NoLiveSessionTile,
    PaymentModal,
    FilterView,
  },
  computed: {
    ...mapGetters({
      filteredLiveSessions: 'participantLiveSessions/filteredLiveSessions',
      hasFilteredLiveSessions: 'participantLiveSessions/hasFilteredLiveSessions',
      filteredReplays: 'participantReplays/filteredReplays',
      hasFilteredReplays: 'participantReplays/hasFilteredReplays',
      hasFilteredPackages: 'participantPackages/hasFilteredPackages',
      isLoggedIn: 'user/isLoggedIn',
      selectedCoach: 'filters/selectedCoach',
    }),
  },
  created() {
    this.$store.dispatch('filters/resetState');
  },
  async mounted() {
    await this.fetchLiveSessions();
    await this.fetchPackages();
    this.setPackageIsInLiveSessions();
    if (this.isLoggedIn) {
      await this.fetchReplays();
    }
    this.applyFilters();
  },
  watch: {
    isLoggedIn(newVal) {
      if (newVal) {
        this.fetchReplays();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchLiveSessions: 'participantLiveSessions/fetchLiveSessions',
      fetchReplays: 'participantReplays/fetchReplays',
      fetchPackages: 'participantPackages/fetchPackages',
      setPackageIsInLiveSessions: 'filters/setPackageId',
      applyFilters: 'filters/applyFilters',
    }),
  }
}
</script>
