/* main.js */
import api from '@/services/api.js';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery';

import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import VueLocalStorage from 'vue-ls';
import vue3GoogleLogin from 'vue3-google-login';
import App from './App.vue';
import { initializeFacebookSDK } from './facebook-sdk.js';
import './registerServiceWorker';
import router from './router';
import store from './store';


const app = createApp(App);

// Configuration de l'internationalisation
const savedLocale = localStorage.getItem('locale') || 'fr';
const i18n = createI18n({
  legacy: false, // pour Vue 3
  locale: savedLocale,
  fallbackLocale: 'en', // Locale de secours
  messages: {
    en: require('./locales/en.json'),
    fr: require('./locales/fr.json')
  }
});

// Configuration de VueLocalStorage
const vueLsOptions = {
  namespace: 'vuejs__',
  name: 'ls',
  storage: 'local'
};
app.use(VueLocalStorage, vueLsOptions);


// Configuration de Google Login
const googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
app.use(vue3GoogleLogin, {
  clientId: googleClientId
});

// Initialisation du SDK Facebook
const fbAppId = process.env.VUE_APP_FACEBOOK_CLIENT_ID;
initializeFacebookSDK(fbAppId);

// Chargement Stripe.js
const stripeScript = document.createElement('script');
stripeScript.src = 'https://js.stripe.com/v3/';
stripeScript.async = true;
stripeScript.onload = function () {
  window.stripeLoaded = true;
};
document.head.appendChild(stripeScript);

// Configuration des propriétés globales
app.config.globalProperties.$setTitle = (title) => {
  document.title = title;
};

app.config.globalProperties.$showToast = function (type, message) {
  this.$store.dispatch('showToast', {
    type: type,
    message: message,
    show: true
  });
};

// Fourniture de l'API et utilisation du store et du routeur
app.provide('$api', api);
app.use(store);
app.use(router);
app.use(i18n);

// Montage de l'application
app.mount('#app');

store.dispatch('checkBackendStatus');