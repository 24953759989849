<!-- Slogan.vue -->
<template>
    <div class="navbar-slogan d-none d-lg-block mx-auto">
        <div class="slogan animate__animated animate__pulse">
            {{ $t('header.slogan') }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'SloganView'
};
</script>