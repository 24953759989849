<!-- components.ConsentModal.vue -->
<template>
    <div v-if="showModal" id="consentModal" class="modal" ref="consentModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title ml-title" id="consentementModalLabel">{{ $t('consent.welcome') }}</h3>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="submitAcceptTerms">
                        <div class="row mb-3 text-center gift">
                            <div class="col-12 text-center d-flex align-items-center justify-content-center">
                                <i class="bi bi-gift me-2"></i>
                                <h6 class="mb-0">{{ $t('consent.firstSessionFree') }}</h6>
                                <i class="bi bi-gift ms-2"></i>
                            </div>
                        </div>
                        <div class="row mb-3 text-center">
                            <h5>{{ $t('consent.almostDone') }}</h5>
                        </div>
                        <!-- name -->
                        <div class="mb-3">
                            <label for="surname" class="form-label">
                                {{ $t('consent.surname') }}
                                <span class="text-danger">*</span>:
                            </label>
                            <input type="text" class="form-control" id="surname" v-model="currentUser.surname">
                        </div>
                        <div class="mb-3">
                            <label for="name" class="form-label">
                                {{ $t('consent.name') }}
                                <span class="text-danger">*</span>:
                            </label>
                            <input type="text" class="form-control" id="name" v-model="currentUser.name">
                        </div>
                        <div class="mb-3">
                            <label for="pseudo" class="form-label">
                                {{ $t('consent.pseudo') }}
                                <span class="text-danger">*</span>:
                            </label>
                            <input type="text" class="form-control" id="pseudo" v-model="currentUser.pseudo">
                            <small id="pseudoHelp" class="form-text text-muted">
                                {{ $t('consent.visibleData') }}
                            </small>
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">
                                {{ $t('consent.email') }}
                                <span class="text-danger">*</span>:
                            </label>
                            <input type="email" class="form-control" id="email" v-model="currentUser.email">
                        </div>
                        <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="termsCheck" v-model="currentUser.termsAccepted">
                            <label class="form-check-label" for="termsCheck">
                                {{ $t('consent.acceptTerms') }} <a href="/termes-et-conditions" target="_blank">
                                    {{ $t('consent.termsAndConditions') }}</a>.
                            </label>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-accept">
                                {{ $t('consent.accept') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'ConsentModal',
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            isTermsAccepted: 'user/isTermsAccepted'
        }),
        showModal() {
            if (!this.currentUser) {
                return false;
            }
            const show = !this.isTermsAccepted && this.$route.name !== '/termes-et-conditions';
            return show;
        }
    },
    methods: {
        ...mapActions({
            fetchUserForTerms: 'user/fetchUserForTerms',
        }),
        checkDatas() {
            if (!this.currentUser.surname || !this.currentUser.name || !this.currentUser.pseudo || !this.currentUser.email) {
                this.$showToast('error', this.$t('error.form.notFilled'));
                return false;
            }
            // regex for email address
            const regex = /\S+@\S+\.\S+/;
            if (!regex.test(this.currentUser.email)) {
                this.$showToast('error', this.$t('error.form.email'));
                return false;
            }
            return true;
        },
        async submitAcceptTerms() {

            if (!this.checkDatas()) {
                return;
            }
            // check if terms are accepted
            if (!this.currentUser.termsAccepted) {
                this.$showToast('error', this.$t('error.form.termsAndConditions'));
                return;
            }

            // Préparation des données du formulaire
            const payload = {
                surname: this.currentUser.surname,
                name: this.currentUser.name,
                pseudo: this.currentUser.pseudo,
                email: this.currentUser.email,
                termsAccepted: this.currentUser.termsAccepted
            };

            try {
                this.$store.dispatch('user/updateTerms', payload);
                this.trackConsentEvent();
            } catch (error) {
                this.$showToast('error', this.$t('consent.error'));
            }
        },
        trackConsentEvent() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'consent_accepted',
                email: this.currentUser.email,
                pseudo: this.currentUser.pseudo,
            });
        }
    }
};
</script>

<style scoped>
/* Styles spécifiques au composant */
.gift {
    color: var(--primary-color);
}

.modal-body {
    max-height: calc(100vh - 120px);
    overflow-y: auto;
}

.modal {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}
</style>
