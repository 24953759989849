/* liveSessionMixin.js */

export const liveSessionMixin = {
  data() {
    return {
      interval: null, // Ajouter ceci pour garder une référence à votre intervalle
    };
  },
  created() {
    // Définir l'intervalle lors de la création du composant
    this.interval = setInterval(this.checkCoursesStatus, 60000);
  },
  destroyed() {
    // Arrêter l'intervalle lors de la destruction du composant
    clearInterval(this.interval);
  },
  methods: {

    checkCoursesStatus() {
      this.$forceUpdate(); // Force Vue à rafraîchir le composant
    },
    isCurrentlyOn(liveSession) {
      if (!liveSession || Object.keys(liveSession).length === 0) {
        return false;
      }
      let now = new Date().toISOString();
      return liveSession.zoom_begin <= now && now <= liveSession.zoom_end;
    },
    getClassTile(liveSession) {
      if (this.isCurrentlyOn(liveSession)) {
        if (liveSession.coach.id === this.currentUser?.id)
          return 'course-live-coach';
        return 'course-live';
      }
      return '';
    },
    range(start, end, step = 1) {
      return Array.from({ length: Math.floor((end - start) / step) + 1 }, (_, i) => start + i * step);
    },
  }
}