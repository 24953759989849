<template>
  <div class="container-lg flex-grow-1 ml-box mb-4 bg-white">

    <h2 class="ml-title mt-4">{{ $t('liveSession.nextSessionsOf', { name: sport?.name }) }}</h2>

    <template v-if="courses && courses.length">
      <OffersTile :courses="courses" :isForSingleSport="true" />
    </template>
    <PaymentModal />

    <NoLiveSessionTile v-if="!courses || !courses.length" />
  </div>
</template>

<script>
import PaymentModal from '@/components/PaymentModal.vue';
import NoLiveSessionTile from '@/components/liveSessions/NoLiveSessionTile.vue';
import OffersTile from '@/components/offers/OffersTile.vue';
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { timeMixin } from '@/mixins/timeMixin';
import api from '@/services/api.js';

export default {
  name: 'LiveSessionsBySport',
  mixins: [timeMixin, liveSessionMixin],
  props: {
    id: String
  },
  components: {
    OffersTile, NoLiveSessionTile, PaymentModal
  },
  data() {
    return {
      sport: null,
      courses: [],
    };
  },
  computed: {
    getImgProfil() {
      return this.sport && this.sport.url_image
        ? this.sport.url_image
        : "https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png";
    }
  },
  async created() {
    try {
      const response = await api.get(`/v1/courses/sports/${this.id}/upcoming`);
      this.sport = response.data.sport;
      this.courses = response.data.courses;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>