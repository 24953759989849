<!-- ReplayTile.vue -->
<template>
    <div class="col-md-6 mb-2">
        <div class="card">
            <div class="card-body">
                <div class="row">

                    <div class="col-lg-4 col-md-6 col-6">
                        <i class="bi bi-activity"></i>
                        {{ replay.sport.name }}
                    </div>
                    <div class="col-lg-4 col-md-6 col-6">
                        <template v-if="replay.coach.url_image">
                            <img class="rounded" :src="replay.coach.url_image" alt="avatar"
                                style="width: 20px; height: auto">
                        </template>
                        <template v-else>
                            <i class="bi bi-person"></i>
                        </template>
                        {{ replay.coach.pseudo }}
                    </div>
                    <div class="col-lg-4 col-md-6 col-6">
                        <i class="bi bi-hourglass-split"></i>
                        {{ replay.duration }} min
                    </div>
                    <div class="col-lg-4 col-md-6 col-6">
                        <i class="bi bi-currency-dollar"></i>
                        <span v-if="replay.price === -1" data-bs-toggle="popover" data-bs-trigger="hover"
                            data-bs-placement="bottom" data-bs-html="true"
                            :data-bs-content="$t('liveSession.voluntaryContributionPopover')">{{
                                $t('liveSession.voluntaryContribution') }}
                        </span>
                        <span v-if="replay.price === 0" data-bs-toggle="popover" data-bs-trigger="hover"
                            data-bs-placement="bottom" data-bs-html="true"
                            :data-bs-content="$t('liveSession.freePopover')">{{
                                $t('liveSession.free') }}
                        </span>
                        <span v-if="replay.price > 0">{{ formatPrice(replay.price) }}</span>
                    </div>
                    <div class=" col-lg-4 col-md-6 col-6">
                        <i class="bi bi-calendar-check"></i>
                        {{ convertToLocalTimeWhitShortDay(replay.recorded_date) }}
                    </div>
                    <div class="col-lg-4 col-md-12 col-12">
                        <button class="btn btn-replay w-100 " @click="getReplayLink(replay)">
                            <i class="bi bi-camera-video mx-2"></i> {{
                                $t('replay.replay') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import replayService from '@/api/replayService';
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { priceMixin } from '@/mixins/priceMixin';
import { timeMixin } from '@/mixins/timeMixin';
import { zoomMixin } from '@/mixins/zoomMixin';

export default {
    name: 'ReplayTile',
    mixins: [liveSessionMixin, priceMixin, zoomMixin, timeMixin],
    props: {
        replay: {
            type: Object,
            required: true
        }
    },
    methods: {
        async getReplayLink(replay) {
            let link = '';
            try {
                link = await replayService.getReplayLink(replay.id)

                if (link === null) {
                    this.openPaymentModal();
                    return;
                }

                this.openZoomLink(link);
                this.$store.dispatch('participantReplays/recordParticipant', replay.id);

            } catch (error) {
                console.error(error);
                this.$showToast('error', this.$t('replay.getLinkError'));
                return;
            }
        },
        openPaymentModal() {
            this.$store.commit('payment/openPaymentModal',
                {
                    selectedCourse: null,
                    selectedPackage: null,
                    selectedReplay: this.replay,
                    selectedCoach: this.replay.coach,
                });
        }
    }
}
</script>

<style scoped>
.btn-replay {
    color: var(--primary-color);
    border-color: var(--primary-color);
}


.btn-replay:hover,
.btn-replay:active {
    color: #ffffff;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}
</style>
