<template>
  <div class="container mb-4 ml-box bg-white">
    <div class="row mt-4">
      <div class="col-md-3">
        <nav id="sidebar">
          <ul class="list-unstyled components">
            <li v-for="item in summary" :key="item.id">
              <a class="Secondary link" :href="`#${item.id}`">{{ item.text }}</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-md-9" ref="contentArea">
        <section id="section">
          <div v-t="'terms.title'"></div>
          <h1 class="ml-title mb-4" v-t="'terms.header'"></h1>

          <section>
            <h2 id="section-1" v-t="'terms.section1Title'"></h2>

            <section>
              <h3 id="section-1-1" v-t="'terms.section11Title'"></h3>
              <p v-t="'terms.section11Content'"></p>
            </section>

            <section>
              <h3 id="section-1-2" v-t="'terms.section12Title'"></h3>
              <span v-t="'terms.section12Content'"></span>
              <ul>
                <li v-t="'terms.section12Content1'"></li>
                <li v-t="'terms.section12Content2'"></li>
                <li v-t="'terms.section12Content3'"></li>
              </ul>
              <span v-t="'terms.section12Content4'"></span>
            </section>

            <section>
              <h3 id="section-1-3" v-t="'terms.section13Title'"></h3>
              <p v-t="'terms.section13Content'"></p>
            </section>

            <section>
              <h3 id="section-1-4" v-t="'terms.section14Title'"></h3>
              <p v-t="'terms.section14Content'"></p>
            </section>

          </section>

          <section>
            <h2 id="section-2" v-t="'terms.section2Title'"></h2>

            <section>
              <h3 id="section-2-1" v-t="'terms.section21Title'"></h3>
              <p v-t="'terms.section21Content'"></p>
            </section>

            <section>
              <h3 id="section-2-2" v-t="'terms.section22Title'"></h3>
              <p v-t="'terms.section22Content'"></p>
            </section>

            <section>
              <h3 id="section-2-3" v-t="'terms.section23Title'"></h3>
              <p v-t="'terms.section23Content'"></p>
            </section>

            <section>
              <h3 id="section-2-4" v-t="'terms.section24Title'"></h3>
              <p v-t="'terms.section24Content'"></p>
            </section>

          </section>

          <section>
            <h2 id="section-3" v-t="'terms.section3Title'"></h2>

            <section>
              <h3 id="section-3-1" v-t="'terms.section31Title'"></h3>
              <p v-t="'terms.section31Content'"></p>
            </section>

            <section>
              <h3 id="section-3-2" v-t="'terms.section32Title'"></h3>
              <p v-t="'terms.section32Content'"></p>
            </section>

            <section>
              <h3 id="section-3-3" v-t="'terms.section33Title'"></h3>
              <p v-t="'terms.section33Content'"></p>
            </section>

            <section>
              <h3 id="section-3-4" v-t="'terms.section34Title'"></h3>
              <p v-t="'terms.section34Content'"></p>
            </section>

            <section>
              <h3 id="section-3-5" v-t="'terms.section35Title'"></h3>
              <p v-t="'terms.section35Content'"></p>
            </section>

            <section>
              <h3 id="section-3-6" v-t="'terms.section36Title'"></h3>
              <p v-t="'terms.section36Content'"></p>
            </section>
          </section>

          <section>
            <h2 id="section-4" v-t="'terms.section4Title'"></h2>

            <section>
              <h3 id="section-4-1" v-t="'terms.section41Title'"></h3>
              <p v-t="'terms.section41Content'"></p>
            </section>

            <section>
              <h3 id="section-4-2" v-t="'terms.section42Title'"></h3>
              <p v-t="'terms.section42Content'"></p>
            </section>

            <section>
              <h3 id="section-4-3" v-t="'terms.section43Title'"></h3>
              <p v-t="'terms.section43Content'"></p>
            </section>

            <section>
              <h3 id="section-4-4" v-t="'terms.section44Title'"></h3>
              <p v-t="'terms.section44Content'"></p>
            </section>

            <section>
              <h3 id="section-4-5" v-t="'terms.section45Title'"></h3>
              <p v-t="'terms.section45Content'"></p>
            </section>

            <section>
              <h3 id="section-4-6" v-t="'terms.section46Title'"></h3>
              <span v-t="'terms.section46Content'"></span>
              <ul>
                <li v-t="'terms.section46Content1'"></li>
                <li v-t="'terms.section46Content2'"></li>
                <li v-t="'terms.section46Content3'"></li>
              </ul>
            </section>

          </section>

          <section>
            <h2 id="section-5" v-t="'terms.section5Title'"></h2>

            <section>
              <h3 id="section-5-1" v-t="'terms.section51Title'"></h3>
              <p v-t="'terms.section51Content'"></p>
              <p v-t="'terms.section51Content1'"></p>
            </section>

            <section>
              <h3 id="section-5-2" v-t="'terms.section52Title'"></h3>
              <p v-t="'terms.section52Content'"></p>
              <p v-t="'terms.section52Content1'"></p>
            </section>

            <section>
              <h3 id="section-5-3" v-t="'terms.section53Title'"></h3>
              <p v-t="'terms.section53Content'"></p>
            </section>

            <section>
              <h3 id="section-5-4" v-t="'terms.section54Title'"></h3>
              <p v-t="'terms.section54Content'"></p>
              <p v-t="'terms.section54Content1'"></p>

            </section>

            <section>
              <h3 id="section-5-5" v-t="'terms.section55Title'"></h3>
              <p v-t="'terms.section55Content'"></p>
            </section>

            <section>
              <h3 id="section-5-6" v-t="'terms.section56Title'"></h3>
              <p v-t="'terms.section56Content'"></p>
            </section>
          </section>

          <section>
            <h2 id="section-6" v-t="'terms.section6Title'"></h2>

            <section>
              <h3 id="section-6-1" v-t="'terms.section61Title'"></h3>
              <p v-t="'terms.section61Content'"></p>
            </section>

            <section>
              <h3 id="section-6-2" v-t="'terms.section62Title'"></h3>
              <p v-t="'terms.section62Content'"></p>
            </section>

            <section>
              <h3 id="section-6-3" v-t="'terms.section63Title'"></h3>
              <p v-t="'terms.section63Content'"></p>
            </section>

            <section>
              <h3 id="section-6-4" v-t="'terms.section64Title'"></h3>
              <p v-t="'terms.section64Content'"></p>
            </section>

            <section>
              <h3 id="section-6-5" v-t="'terms.section65Title'"></h3>
              <p v-t="'terms.section65Content'"></p>
            </section>

            <section>
              <h3 id="section-6-6" v-t="'terms.section66Title'"></h3>
              <p v-t="'terms.section66Content'"></p>
            </section>

          </section>

          <section>
            <h2 id="section-7" v-t="'terms.section7Title'"></h2>

            <section>
              <h3 id="section-7-1" v-t="'terms.section71Title'"></h3>
              <p v-t="'terms.section71Content'"></p>
            </section>

            <section>
              <h3 id="section-7-2" v-t="'terms.section72Title'"></h3>
              <p v-t="'terms.section72Content'"></p>
            </section>

            <section>
              <h3 id="section-7-3" v-t="'terms.section73Title'"></h3>
              <p v-t="'terms.section73Content'"></p>
            </section>

            <section>
              <h3 id="section-7-4" v-t="'terms.section74Title'"></h3>
              <p v-t="'terms.section74Content'"></p>
            </section>

            <section>
              <h3 id="section-7-5" v-t="'terms.section75Title'"></h3>
              <p v-t="'terms.section75Content'"></p>
            </section>

          </section>

          <section>
            <h2 id="section-8" v-t="'terms.section8Title'">8. Confidentialité</h2>

            <section>
              <h3 id="section-8-1" v-t="'terms.section81Title'"></h3>
              <p v-t="'terms.section81Content'"></p>
            </section>

            <section>
              <h3 id="section-8-2" v-t="'terms.section82Title'"></h3>
              <p v-t="'terms.section82Content'"></p>
            </section>

            <section>
              <h3 id="section-8-3" v-t="'terms.section83Title'"></h3>
              <p v-t="'terms.section83Content'"></p>
            </section>

            <section>
              <h3 id="section-8-4" v-t="'terms.section84Title'"></h3>
              <p v-t="'terms.section84Content'"></p>
            </section>

            <section>
              <h3 id="section-8-5" v-t="'terms.section85Title'"></h3>
              <p v-t="'terms.section85Content'"></p>
            </section>

            <section>
              <h3 id="section-8-6" v-t="'terms.section86Title'"></h3>
              <p v-t="'terms.section86Content'"></p>
            </section>

            <section>
              <h3 id="section-8-7" v-t="'terms.section87Title'"></h3>
              <p v-t="'terms.section87Content'"></p>
            </section>

            <section>
              <h3 id="section-8-8" v-t="'terms.section88Title'"></h3>
              <p v-t="'terms.section88Content'"></p>
            </section>

          </section>

          <section>
            <h2 id="section-9" v-t="'terms.section9Title'"></h2>

            <section>
              <h3 id="section-9-1" v-t="'terms.section91Title'"></h3>
              <p v-t="'terms.section91Content'"></p>
            </section>

            <section>
              <h3 id="section-9-2" v-t="'terms.section92Title'"></h3>
              <p v-t="'terms.section92Content'"></p>
            </section>

            <section>
              <h3 id="section-9-3" v-t="'terms.section93Title'"></h3>
              <p v-t="'terms.section93Content'"></p>
            </section>

            <section>
              <h3 id="section-9-4" v-t="'terms.section94Title'"></h3>
              <p v-t="'terms.section94Content'"></p>
            </section>

            <section>
              <h3 id="section-9-5" v-t="'terms.section95Title'"></h3>
              <p v-t="'terms.section95Content'"></p>
            </section>

            <section>
              <h3 id="section-9-6" v-t="'terms.section96Title'"></h3>
              <p v-t="'terms.section96Content'"></p>
            </section>

          </section>

          <section>
            <h2 id="section-10" v-t="'terms.section10Title'"></h2>

            <section>
              <h3 id="section-10-1" v-t="'terms.section101Title'"></h3>
              <p v-t="'terms.section101Content'"></p>
            </section>

            <section>
              <h3 id="section-10-2" v-t="'terms.section102Title'"></h3>
              <p v-t="'terms.section102Content'"></p>
            </section>

            <section>
              <h3 id="section-10-3" v-t="'terms.section103Title'"></h3>
              <p v-t="'terms.section103Content'"></p>
            </section>

            <section>
              <h3 id="section-10-4" v-t="'terms.section104Title'"></h3>
              <p v-t="'terms.section104Content'"></p>
            </section>

          </section>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsVue',
  metaInfo: {
    title: 'Conditions générales',
    meta: [
      {
        vmid: 'Terms',
        name: 'description',
        content: 'Conditions générales de MovingLive Inc.'
      }
    ]
  },
  data() {
    return {
      summary: [],
    };
  },
  methods: {
    generateSummary() {
      const selectors = ['h1', 'h2', 'h3']; // Ajouter d'autres sélecteurs si nécessaire
      const contentArea = this.$refs.contentArea;
      if (contentArea) {
        const elements = contentArea.querySelectorAll(selectors.join(', '));
        this.summary = Array.from(elements).map(el => ({
          text: el.textContent,
          id: el.id,
        }));
      }
    },
  },
  mounted() {
    this.generateSummary();
  },
};
</script>