<!-- DesktopProfileDropdown.vue -->
<template>
    <li v-if="isLoggedIn" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <i v-if="currentUser.participant_valid_packages && currentUser.participant_valid_packages.length > 0"
                class="bi bi-infinity"></i>
            <i v-else class="bi bi-person-fill"></i>
            {{ currentUser.pseudo }}
        </a>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profileDropdown">
            <li><a class="dropdown-item" href="/profile">{{ $t('menu.profile') }}</a></li>
            <li v-if="currentUser.is_coach_validated">
                <a class="dropdown-item" href="/coach/dashboard">{{ $t('menu.workspace') }}</a>
            </li>
            <li><a class="dropdown-item" href="/manage-booking">{{ $t('menu.myReservations') }}</a></li>
            <li><a class="dropdown-item" href="/consult-payment">{{ $t('menu.myPayments') }}</a></li>
            <li>
                <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#feedbackModal">{{
                    $t('menu.contact') }}</a>
            </li>
            <li>
                <select class="form-select d-none d-lg-block bg-transparent text-black border-0 ms-1"
                    v-model="$i18n.locale" @change="setLocale($i18n.locale)">
                    <option value="fr">FR</option>
                    <option value="en">EN</option>
                </select>
            </li>
            <template v-if="currentUser.is_admin">
                <li>
                    <hr class="dropdown-divider" />
                </li>
                <li>
                    <a class="dropdown-item" href="/admin/dashboard">{{ $t('menu.admin.dashboard') }}</a>
                </li>
                <li>
                    <a class="dropdown-item" href="/admin/coaches">{{ $t('menu.admin.manageCoachs') }}</a>
                </li>
                <li>
                    <a class="dropdown-item" href="/admin/participants">{{ $t('menu.admin.manageParticipants') }}</a>
                </li>
                <li>
                    <a class="dropdown-item" href="/admin/sports">{{ $t('menu.admin.manageSports') }}</a>
                </li>
            </template>
            <li>
                <hr class="dropdown-divider">
            </li>
            <li><a class="dropdown-item" href="#" @click="logout">{{ $t('menu.logout') }}</a></li>
            <template v-if="isDevelopment && isLoggedIn">
                <li>
                    <hr class="dropdown-divider" />
                </li>
                <li><a class="dropdown-item" href="#" @click="localAddSession()">Add sessions</a></li>
                <li><a class="dropdown-item" href="#" @click="localAddPastSession()">Add past sessions</a></li>
                <li><a class="dropdown-item" href="#" @click="localClearSession()">Clear sessions</a></li>
                <li><a class="dropdown-item" href="#" @click="localClearPayment()">Clear payment</a></li>
                <li><a class="dropdown-item" href="#" @click="localAddReplay()">Add replays</a></li>
            </template>
        </ul>
    </li>
</template>

<script>
import api from '@/services/api.js';
import { mapGetters } from 'vuex';

export default {
    name: 'DesktopProfileDropdown',
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            isLoggedIn: 'user/isLoggedIn',
            loggedInService: 'user/loggedInService'
        }),
        isDevelopment() {
            return process.env.VUE_APP_ENV === 'development';
        },
    },
    methods: {
        /* global FB */
        async logout() {
            if (this.loggedInService === 'facebook') {
                FB.logout(function () { });
            }
            await this.$store.dispatch('user/logout');
            this.$router.push('/');
        },
        setLocale(lang) {
            this.$i18n.locale = lang;
            localStorage.setItem('locale', lang);
        },
        async localAddSession() {
            await api.post('/v1/local/sessions');
            window.location.reload();
        },
        async localAddPastSession() {
            await api.post('/v1/local/sessions/past');
            window.location.reload();
        },
        async localClearSession() {
            await api.delete('/v1/local/sessions');
            window.location.reload();
        },
        async localClearPayment() {
            await api.delete('/v1/local/payments');
            window.location.reload();
        },
        async localAddReplay() {
            await api.post('/v1/local/replays');
            window.location.reload();
        },
    }
};
</script>
