<template>
  <div class="container ml-box py-4 mb-4 bg-white">
    <div class="row">
      <!-- MovingLive c'est quoi -->
      <div class="col-12 mb-4">
        <div class="card shadow p-3 bg-white rounded h-100">
          <div class="card-body">
            <h2 class=" card-title ml-title display-6" v-t="'whoWeAre.what.title'"></h2>
            <p v-t="'whoWeAre.what.content'">
            </p>
            <p v-t="'whoWeAre.what.content2'">
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Début de la deuxième ligne -->
    <div class="row">
      <div class="col-12">
        <div class="card shadow p-3 mb-5 bg-white rounded">
          <div class="card-body">
            <h2 class="card-title display-6 ml-title" v-t="'whoWeAre.vision.title'"></h2>

            <h4 class="card-title fw-bold" v-t="'whoWeAre.vision.participants.title'"></h4>
            <ul class="list-group-flush">
              <li v-for="(item, index) in $tm('whoWeAre.vision.participants.items')" :key="index">
                {{ item }}
              </li>
            </ul>

            <hr class="my-4">

            <h4 class="card-title fw-bold" v-t="'whoWeAre.vision.coachs.title'"></h4>
            <ul class="list-group-flush">
              <li v-for="(item, index) in $tm('whoWeAre.vision.coachs.items')" :key="index">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Fermeture de la deuxième ligne -->
  </div>
</template>

<script>
export default {
  name: 'WwaVue',
  metaInfo: {
    title: 'MovingLive Story',
    meta: [
      {
        vmid: 'Wwa',
        name: 'description',
        content: 'Découvrez l\'histoire de MovingLive.'
      }
    ]
  },
  components: {
  },
  computed: {
  },
};
</script>