<template>
    <div class="container ml-box mb-4 bg-white">
        <div class="row g-0 mb-4">
            <div class="col-11 mt-4">
                <h2 class="ml-title" v-t="'replay.myReplays'"></h2>
            </div>
        </div>
        <div class="container-fluid">
            <div v-if="hasReplays" class="row">
                <div class="col-12" v-for="replay in replays" :key="replay.id">
                    <div class="card mb-2">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 mb-2">
                                    <i class="bi bi-activity me-2"></i>
                                    <span>{{ replay.sport?.name }}</span>
                                </div>
                                <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 mb-2">
                                    <i class="bi bi-stopwatch-fill me-2"></i>
                                    {{ replay.duration }} min
                                </div>
                                <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 mb-2">
                                    <i class="bi bi-currency-dollar me-2"></i>
                                    <span>{{ formatPrice(replay.price) }}</span>
                                </div>
                                <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 mb-2">
                                    <i class="bi bi-calendar-check me-2"></i>
                                    <span>{{ formatDateShorty(replay.recorded_date) }}</span>
                                </div>
                                <div :class="['col-xl-2', 'col-lg-4', 'col-md-4', 'col-sm-12']">
                                    <i class="bi bi-person-fill-check me-2"></i>
                                    <span data-bs-toggle="popover" data-bs-trigger="hover" data-bs-placement="bottom"
                                        :data-bs-content="replay.participants_names">
                                        {{ replay.participants_names?.length }}
                                    </span>
                                </div>
                                <div class="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                                    <i class="bi bi-link-45deg me-2"></i>
                                    <a :href="replay.link" target="_blank">{{ replay.link }}</a>
                                </div>
                                <div class="col-xl-1 col-lg-12 col-md-12 col-sm-12">
                                    <button class="btn btn-delete btn-sm" @click="deleteReplay(replay.id)"
                                        v-t="'replay.delete'"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="text-center" v-t="'replay.noReplay'"></p>
            </div>
        </div>
    </div>
</template>

<script>
import { liveSessionMixin } from '@/mixins/liveSessionMixin';
import { popoverMixin } from '@/mixins/popoverMixin';
import { priceMixin } from '@/mixins/priceMixin';
import { timeMixin } from '@/mixins/timeMixin';

export default {
    name: 'CoachReplaysTile',
    mixins: [timeMixin, liveSessionMixin, priceMixin, popoverMixin],
    computed: {
        replays() {
            return this.$store.getters['coachReplays/replays'];
        },
        hasReplays() {
            return this.$store.getters['coachReplays/hasReplays'];
        },
        currentUser() {
            return this.$store.getters['user/currentUser'];
        },
    },
    created() {
    },
    methods: {
        async deleteReplay(replayId) {
            try {
                await this.$store.dispatch('coachReplays/deleteReplay', replayId);
                this.$showToast('success', this.$t('replay.deleteSuccess'));
            } catch (error) {
                this.$showToast('error', this.$t('replay.deleteError'));
                console.error(error);
            }
        },

    },
};
</script>

<style scoped>
.subscription-active {
    border: 2px solid var(--primary-color);
}
</style>
