<template>
  <div class="container flex-grow-1 ml-box mt-4 mb-4 bg-white">
    <div class="container-fluid mt-4 mb-4">
      <h2 class="ml-title mt-4">Dashboard</h2>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Payed</th>
            <th scope="col">coach</th>
            <th scope="col">Platform</th>
            <th scope="col">Stripe</th>
            <th scope="col">Provider</th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-secondary">
            <th scope="col">Total</th>
            <th scope="col">{{ formatAmount(totalAmount) }}</th>
            <th scope="col">{{ formatAmount(totalNetTocoach) }}</th>
            <th scope="col">{{ formatAmount(totalPlatformFee) }}</th>
            <th scope="col">{{ formatAmount(totalStripeFee) }}</th>
            <th scope="col"></th>
          </tr>
          <tr v-for="payment in payments" :key="payment.id">
            <td>{{ payment.id }}</td>
            <td>{{ formatAmount(payment.total_amount) }}</td>
            <td>{{ formatAmount(payment.net_to_coach) }}</td>
            <td>{{ formatAmount(payment.platform_fee) }}</td>
            <td>{{ formatAmount(payment.stripe_fee) }}</td>
            <td>{{ payment.payment_provider }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import AdminService from '@/api/AdminService';

export default {
  name: 'AdminDashboard',
  data() {
    return {
      payments: []
    };
  },
  mounted() {
    this.fetchPayments();
  },
  computed: {
    totalAmount() {
      return this.payments.reduce((sum, payment) => sum + payment.total_amount, 0);
    },
    totalNetTocoach() {
      return this.payments.reduce((sum, payment) => sum + payment.net_to_coach, 0);
    },
    totalPlatformFee() {
      return this.payments.reduce((sum, payment) => sum + payment.platform_fee, 0);
    },
    totalStripeFee() {
      return this.payments.reduce((sum, payment) => sum + payment.stripe_fee, 0);
    }
  },
  methods: {
    async fetchPayments() {
      try {
        this.payments = await AdminService.getPayments();
      } catch (error) {
        this.$showToast('error', 'erreur lors de la récupération des paiements');
        console.error(error);
      }
    },
    formatAmount(amount) {
      return amount !== null && amount !== undefined ? amount.toFixed(2) : '0.00';
    }
  }
};
</script>
