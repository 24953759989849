// src/store/modules/participantReplays.js
import replayService from '@/api/replayService';

const getDefaultState = () => {
    return {
        replays: [],
        filteredReplays: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        replays(state) {
            return state.replays;
        },
        filteredReplays(state) {
            return state.filteredReplays;
        },
        hasReplays(state) {
            return state.replays && state.replays.length > 0;
        },
        hasFilteredReplays(state) {
            return state.filteredReplays && state.filteredReplays.length > 0;
        }
    },
    mutations: {
        SET_REPLAYS(state, replays) {
            state.replays = replays;
        },
        SET_FILTERED_REPLAYS(state, filteredReplays) {
            state.filteredReplays = filteredReplays;
        },
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        ADD_REPLAY(state, replay) {
            state.replays.push(replay);
        }
    },
    actions: {
        async fetchReplays({ commit }) {
            try {
                const replays = await replayService.getReplays();
                commit('SET_REPLAYS', replays);
            } catch (error) {
                console.error('Error while fetching participant replays:', error);
            }
        },
        async getReplayLink(replayId) {
            try {
                return await replayService.getReplayLink(replayId);
            } catch (error) {
                console.error('Error while fetching replay link:', error);
            }
        },
        async recordParticipant(context, replayId) {
            try {
                await replayService.recordParticipant(replayId);
            } catch (error) {
                console.error('Error while recording participant:', error);
            }
        },
        async init({ commit }) {
            const replays = await replayService.getReplays();
            commit('SET_REPLAYS', replays);
            commit('SET_FILTERED_REPLAYS', replays);
        },
        async resetState({ commit }) {
            commit('RESET_STATE');
        },
        async addReplay({ commit }, replay) {
            commit('ADD_REPLAY', replay);
        }
    },
};
