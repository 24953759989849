/* src/services/api.js */
import authService from '@/api/authService';
import store from '@/store';
import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true
});

instance.interceptors.request.use(
    (config) => {
        const accessToken = authService.getToken();
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        console.error("Error in request configuration: " + error);
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = authService.getRefreshToken();
                if (refreshToken) {
                    const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/v1/users/refresh-token`, {
                        token: refreshToken
                    });
                    const newAccessToken = response.data.access_token;

                    authService.saveToken(newAccessToken);
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axios(originalRequest);
                }
            } catch (refreshError) {
                store.dispatch('user/logout');
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export default instance;
